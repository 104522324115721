import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface BlacklistApprovalConfirmationModalProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

export const BlacklistApprovalConfirmationModal: FC<BlacklistApprovalConfirmationModalProps> = ({
	onAccept,
	onClose,
	open
}) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('titleApproveEnrollment')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('approveActionText')}
					</NetcurioButton>
				</>
			}
		>
			<Trans>
				<p>{t('questionBlacklistEnrollment')}</p>
			</Trans>
		</NetcurioDialog>
	)
}
