import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsumptionItem, InvoiceItem, PurchaseOrderItem } from '../../../../types'
import { ItemsToUpdate } from '../types'
import { InvoiceItemEdit } from './InvoiceItemEdit/InvoiceItemEdit'

interface InvoiceItemListEditProps {
	updateAssociatedItemPendingQuantity(itemsToUpdate: Array<ItemsToUpdate>): void
	informationTable: Array<InvoiceItem>
	disableAssociateProducts: boolean
	suggestionsItemsToAssociate: Array<PurchaseOrderItem | ConsumptionItem>
	associateProducts(indexSelect: number, item?: number): void
	documentSelected?: string
	showAssociationSection?: boolean
}
export const InvoiceItemListEdit: FC<InvoiceItemListEditProps> = ({
	updateAssociatedItemPendingQuantity,
	informationTable = [],
	disableAssociateProducts,
	suggestionsItemsToAssociate,
	associateProducts,
	documentSelected,
	showAssociationSection
}) => {
	const { t } = useTranslation()
	return (
		<NetcurioTableContainer minHeight="100%">
			<NetcurioTable size="small">
				<colgroup>
					<col style={{ width: '7%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '8%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '7%' }} />
					{showAssociationSection && <col style={{ width: '19%' }} />}
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionConceptTooltip')} placement="top">
								<span>{t('numberText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('descriptionProductInvoicedTooltip')} placement="top">
								<span>{t('conceptText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('codeTooltipText')} placement="top">
								<span>{t('codeText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('quantityProductInvoicedTooltip')} placement="top">
								<span>{t('quantity')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('unitPriceTooltip')} placement="top">
								<span>{t('unitPrice')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('invoicedAmountProductTooltip')} placement="top">
								<span>{t('amountText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('indicatorTaxTooltip')} placement="top">
								<span>{t('taxIVA')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						{showAssociationSection && (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('relatedPositionPOTooltip')} placement="top">
									<span>{t('positionRelated')}</span>
								</NetcurioTooltip>
							</NetcurioTableCell>
						)}
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{informationTable.map((item: InvoiceItem, index: number) => (
						<InvoiceItemEdit
							key={item.position}
							itemPosition={index}
							invoiceItem={item}
							disableAssociateProducts={disableAssociateProducts}
							suggestionsItemsToAssociate={suggestionsItemsToAssociate}
							associateProducts={associateProducts}
							documentSelected={documentSelected}
							updateAssociatedItemPendingQuantity={updateAssociatedItemPendingQuantity}
							showAssociationSection={showAssociationSection}
						/>
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
