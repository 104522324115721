import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Roles } from '@netcurio-ui/common'
import { NetcurioTableCell, NetcurioTableRow, NetcurioTooltip } from '@netcurio-ui/components'
import { ConsumptionItem } from '../../../../../types'
import UserInformation from '../../../../../utilities/userInformation'
import styles from '../../consumptionDetail.module.scss'

import Formatter from '../../../../../utilities/formatter'

interface TableRowProps {
	informationTable: ConsumptionItem
}

export const ItemConsumption: FC<TableRowProps> = ({
	informationTable: {
		position,
		supplier,
		description,
		code,
		tax,
		unit_price,
		net_value,
		storage_location,
		quantity,
		unit,
		currency
	}
}) => {
	const { t } = useTranslation()
	const userRole = UserInformation.getCompanyRole()
	return (
		<NetcurioTableRow isDetailRow>
			<NetcurioTableCell align="center">
				<p className={styles.margin0}>{position}</p>
			</NetcurioTableCell>
			{userRole === Roles.CUSTOMER && (
				<NetcurioTableCell align="center">
					<p className={styles.margin0}>{supplier['name']}</p>
				</NetcurioTableCell>
			)}
			<NetcurioTableCell>
				<NetcurioTooltip title={description} placement="right">
					<p className={styles.margin0}>{`${t('itemDescription')} ${description}`}</p>
				</NetcurioTooltip>
				<p className={styles.margin0}>{`${t('itemCode')} ${code}`}</p>
				<p className={styles.margin0}>{`${t('umText')} ${unit}`}</p>
				<p className={styles.margin0}>{`${t('taxText')} ${Formatter.percent.format(tax)}`}</p>

				<div>
					<p className={styles.margin0}>{`${t('unitPrice')} ${Formatter.currency.format(
						unit_price
					)}`}</p>
					<p className={styles.margin0}>{`${t('itemNetValue')} ${Formatter.currency.format(
						net_value
					)}`}</p>
				</div>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.margin0}>{storage_location ?? t('NAtext')}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.margin0}>
					{quantity} {unit}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.margin0}>{currency}</p>
			</NetcurioTableCell>
		</NetcurioTableRow>
	)
}
