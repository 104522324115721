import { NetcurioButton, NetcurioGrid } from '@netcurio-ui/components'
import { t } from 'i18next'
import React from 'react'

interface ActionButtonsProps {
	onClose(): void
	onAuthorize(): void
	disabled: boolean
	displaySubmitButton?: boolean
}

export const ActionButtons = ({
	onClose,
	onAuthorize,
	disabled,
	displaySubmitButton
}: ActionButtonsProps) => {
	return (
		<NetcurioGrid width="100%" display="flex" justifyContent="end" gap="12px">
			<NetcurioButton
				variant={displaySubmitButton ? 'outlined' : 'contained'}
				onClick={() => {
					onClose()
				}}
			>
				{t('closeButton')}
			</NetcurioButton>
			{displaySubmitButton && (
				<NetcurioButton variant="contained" disabled={disabled} onClick={onAuthorize}>
					{t('authorize')}
				</NetcurioButton>
			)}
		</NetcurioGrid>
	)
}
