import React from 'react'
import { t } from 'i18next'
import {
	NetcurioTooltip,
	NetcurioTextField,
	NetcurioNumericField,
	NetcurioSelect,
	NetcurioMenuItem,
	NetcurioSelectChangeEvent,
	NetcurioIconButton,
	NetcurioIcons
} from '@netcurio-ui/components'
import { ItemRFCM } from '../../types'
import { ValidationItemRFCM } from '../../types/ValidationItemRFCM'
import { ColorBaseRed } from '@netcurio-ui/design-tokens/dist/_variables'
import classNames from 'classnames'
import styles from './newRequestForCreditMemo.module.scss'

const taxtItems = [
	{ label: '0 %', value: '0.0 ', key: 1 },
	{ label: '8 %', value: '0.08', key: 2 },
	{ label: '16 %', value: '0.16', key: 3 }
]

interface IItemRowProps {
	itemDataObject: ItemRFCM
	itemIndex: number
	itemPosition: number
	handleItemFieldChange: (value: string, index: number, nameField: string) => void
	deleteItem: (itemIndex: number) => void
	validateItem: ValidationItemRFCM
	disabledRow: boolean
}

const ItemRow = ({
	itemDataObject,
	itemIndex,
	itemPosition,
	handleItemFieldChange,
	deleteItem,
	validateItem,
	disabledRow
}: IItemRowProps) => {
	const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleItemFieldChange(event.target.value, itemIndex, 'description')
	}
	const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleItemFieldChange(event.target.value, itemIndex, 'quantity')
	}
	const handleNetAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleItemFieldChange(event.target.value, itemIndex, 'netAmount')
	}
	const handleTaxIndicatorChange = (event: NetcurioSelectChangeEvent) => {
		handleItemFieldChange(event.target.value, itemIndex, 'taxIndicator')
	}

	return (
		<div className={'item-row-RFCM'}>
			<div className={styles.rightModuleRFCM}>
				<div className={styles.alignModulesRFCM}>
					<NetcurioTooltip title={t('positionText')}>
						<div className={classNames(styles.readOnlyField, styles.resizePositionRFCM)}>
							{itemPosition}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('appConcept')}>
						<div className={classNames(styles.itemFieldRFCM, 'color-read-mode-text')}>
							<NetcurioTextField
								fullWidth
								size="small"
								placeholder={t('appConceptPlaceholder')}
								variant="outlined"
								value={itemDataObject.description}
								onChange={handleDescriptionChange}
								maxLength={60}
								disabled={disabledRow}
								error={!!validateItem.errorDescription}
							/>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('quantity')}>
						<div className={classNames(styles.itemFieldRFCM, styles.resizeItemField)}>
							<NetcurioNumericField
								fullWidth
								size="small"
								placeholder={t('placeholderAmount')}
								value={itemDataObject.quantity}
								decimalScale={2}
								onChange={handleQuantityChange}
								disabled={disabledRow}
								error={!!validateItem.errorQuantity}
							/>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('amountText')}>
						<div className={classNames(styles.itemFieldRFCM, styles.resizeItemField)}>
							<NetcurioNumericField
								fullWidth
								size="small"
								prefix="$"
								placeholder={t('placeholderAmount2')}
								value={itemDataObject.netAmount}
								decimalScale={2}
								onChange={handleNetAmountChange}
								disabled={disabledRow}
								error={!!validateItem.errorNetAmount}
							/>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('indicatorTaxTooltip')}>
						<div className={classNames(styles.itemFieldRFCM, styles.resizeItemFieldDropdown)}>
							<NetcurioSelect
								size="small"
								displayEmpty
								fullWidth
								value={itemDataObject.taxIndicator}
								onChange={handleTaxIndicatorChange}
								disabled={disabledRow}
								error={!!validateItem.errorTaxIndicator}
							>
								<NetcurioMenuItem value="">
									<span>{t('taxIVA')}</span>
								</NetcurioMenuItem>
								{taxtItems.map((i) => (
									<NetcurioMenuItem key={i.key} value={i.value}>
										{i.label}
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</div>
					</NetcurioTooltip>

					<NetcurioIconButton
						disabled={validateItem.cancelImgDisabled}
						sx={{ color: ColorBaseRed }}
						restingIcon={<NetcurioIcons.CancelOutlined />}
						hoverIcon={<NetcurioIcons.Cancel />}
						onClick={() => {
							deleteItem(itemIndex)
						}}
					/>
				</div>
			</div>
		</div>
	)
}
export default ItemRow
