import React from 'react'
import { NetcurioDialog, NetcurioButton } from '@netcurio-ui/components'
import { t } from 'i18next'
import { TermsAndConditions } from './TermsAndConditions'

export interface TermsAndConditionsModalProps {
	showModal: boolean
	closeModal: () => void
}
export const TermsAndConditionsModal = ({ showModal, closeModal }: TermsAndConditionsModalProps) => {
	return (
		<NetcurioDialog
			titleText={t('termsText')}
			open={showModal}
			actionButtons={
				<div>
					<NetcurioButton variant="contained" onClick={closeModal}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</div>
			}
		>
			<TermsAndConditions />
		</NetcurioDialog>
	)
}
