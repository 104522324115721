import React from 'react'
import { Translation } from 'react-i18next'
import { NetcurioButton, NetcurioIcons } from '@netcurio-ui/components'
import { FileInformation } from './relatedCompanyDetail'
import styles from './relatedCompanyDetail.module.scss'

interface Props {
	filesInformation: FileInformation
	setUUIDFile: (uuid: string) => void
}

const CompanyFiles = ({ filesInformation, setUUIDFile }: Props): JSX.Element => {
	function hasDocuments() {
		for (const key in filesInformation) {
			if (filesInformation[key].uuid) return true
		}
		return false
	}

	return (
		<Translation>
			{(t) => (
				<div>
					<div className={styles.relatedCompanyDetailMainContainer}>
						<div className={styles.displayFlex}>
							<p className={styles.titleHeaderRelatedCompanyDetail}>
								{t('relatedCompanyDocuments')}
							</p>
						</div>
					</div>
					<div
						className={`${styles.containerTrackRelatedCompanyDetailDocuments} ${styles.grayColorText}`}
					>
						{Object.keys(filesInformation).map(
							(key) =>
								filesInformation[key].uuid && (
									<div className={styles.relatedCompanyDetailDocumentsRow} key={key}>
										<div>
											<span>{t(`${key}NewCompany`)}</span>
										</div>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											sx={{
												height: '3rem',
												margin: '0.5rem 0 0 0.5rem',
												border: 'solid 0.1rem'
											}}
											onClick={() => setUUIDFile(filesInformation[key].uuid)}
											endIcon={<NetcurioIcons.VisibilityOutlined />}
										>
											<p>{t('viewDocument')}</p>
										</NetcurioButton>
									</div>
								)
						)}
						{!hasDocuments() && (
							<p className={styles.relatedCompanyDetailNoDocuments}>
								{t('relatedCompanyNoDocuments')}
							</p>
						)}
					</div>
				</div>
			)}
		</Translation>
	)
}
export default CompanyFiles
