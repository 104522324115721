import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface SaveQuotationModalProps {
	openModal: boolean
	closeModal(): void
	onAcceptModal(): void
	saveDiffPrices: boolean
}

/**
 * SaveQuotationModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param openModal <boolean>: If `true`, the component is shown
 * @param closeModal <boolean>: If `false`, the component is hide
 * @param onAcceptModal <void>: Callback fired when the user accept the action the modal
 * @param saveDiffPrices <boolean>: flag for change text of the modal
 * @return component <ReactNode>
 **/

export const SaveQuotationModal: FC<SaveQuotationModalProps> = ({
	openModal,
	closeModal,
	onAcceptModal,
	saveDiffPrices
}) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={openModal}
			titleText={t('createTitleNewQuotation')}
			actionButtons={
				<>
					<NetcurioButton onClick={() => closeModal()}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAcceptModal}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t(saveDiffPrices ? 'questionNewQuotationPricesModified' : 'questionNewQuotation')}</p>
		</NetcurioDialog>
	)
}
