import { NetcurioButton, NetcurioDialog, TextArea } from '@netcurio-ui/components'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import constants from '../../../../../utilities/constants'
import styles from '../../InvoiceDetail.module.scss'

interface CancelInvoiceModalProps {
	open: boolean
	onAccept(): void
	onClose(): void
	isReasonCancelInvoice?: boolean
	setReasonToCancel: Dispatch<SetStateAction<string>>
	reasonToCancel: string
	hasReference?: boolean
	referenceType?: string | null
}

/**
 * CancelInvoiceModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <boolean>: If `false`, the component is hide
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @param isReasonCancelInvoice <boolean>: If `true`, the component is shown with a reason input
 * @param setReasonToCancel <Dispatch<SetStateAction<string>>> is a function that change value of reasonToCancel
 * @param reasonToCancel <string> is a value of the text input
 * @return component <ReactNode>
 **/

const CHARACTERS_LIMIT = 150

export const CancelInvoiceModal = ({
	open,
	onAccept,
	onClose,
	isReasonCancelInvoice,
	setReasonToCancel,
	reasonToCancel,
	referenceType
}: CancelInvoiceModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={
				referenceType === constants.DOCUMENT_TYPE.STANDALONE
					? t('invoice.cancelInvoiceNoPO')
					: t('cancelPayment')
			}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('closeButton')}</NetcurioButton>
					<NetcurioButton
						variant="contained"
						onClick={onAccept}
						disabled={isReasonCancelInvoice && !reasonToCancel.length}
					>
						{t('invoice.cancelInvoice')}
					</NetcurioButton>
				</>
			}
		>
			<div>
				<TextArea
					placeholder={t('reasonCancelInvoice')}
					onChangeText={setReasonToCancel}
					readValue={reasonToCancel}
					rows={2}
					maxLength={CHARACTERS_LIMIT}
				/>
				<span className={styles.textMaxChars}>
					{CHARACTERS_LIMIT - reasonToCancel.length === 1
						? t('invoice.remainingSingleCharacter')
						: t('invoice.remainingCharacters', {
								chars: CHARACTERS_LIMIT - reasonToCancel.length
							})}
				</span>
			</div>
		</NetcurioDialog>
	)
}
