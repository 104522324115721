import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { NetcurioButton, NetcurioIcons } from '@netcurio-ui/components'
import { ItemsListProps, MeasurementUnits, ProductRFQ } from '@netcurio-ui/common'
import { Item } from '../item/item'
import styles from './itemList.module.scss'

const ItemsList = ({
	addItem,
	itemDataList,
	handleItemFieldChange,
	selectedProductItem,
	selectedSupplier,
	deleteItem,
	measurementUnits,
	clearUnit,
	notFilledMessage,
	validateItems
}: ItemsListProps): JSX.Element => {
	const { t } = useTranslation()
	useEffect(() => {
		addItem()
	}, [])

	function showErrorMessage(): JSX.Element {
		const items = itemDataList
		let showMessage = false
		items.forEach(
			(item: { requiredDate: Dayjs; amount: string; unit: MeasurementUnits; product: ProductRFQ }) => {
				if (
					notFilledMessage === true &&
					(item.requiredDate === undefined ||
						item.amount === '' ||
						parseFloat(item.amount) === 0 ||
						item.unit === undefined ||
						item.product === undefined ||
						item.product === undefined)
				) {
					showMessage = true
				}
			}
		)
		if (showMessage) {
			return (
				<div id="notFilledItem" className={styles.notFilledNewMessage}>
					<div>{t('missingFields')}</div>
				</div>
			)
		}
	}

	return (
		<div className={styles.newRfqModuleContainer}>
			<div className={styles.headerSticky}>
				<div id="titleTable">
					<div className={styles.headerContainerRfq}>
						<div className={styles.titleHeaderNewRfq}>{t('productsText')}</div>
					</div>
				</div>
			</div>
			<div className={styles.informationContainerNewRfq}>
				{itemDataList.map((item, index) => (
					<Item
						key={index}
						itemPosition={String(index)}
						itemDataObject={item}
						validateItem={validateItems[parseInt(String(index))]}
						handleItemFieldChange={handleItemFieldChange}
						selectedProductItem={selectedProductItem}
						selectedSupplier={selectedSupplier}
						deleteItem={deleteItem}
						measurementUnits={measurementUnits}
						clearUnit={clearUnit}
						disabled={!selectedSupplier.supplier_rfc}
						lengthItems={itemDataList.length}
					/>
				))}
				{showErrorMessage()}
				<div className={styles.moduleRfq}>
					<NetcurioButton
						startIcon={<NetcurioIcons.AddCircleOutlineOutlined />}
						onClick={addItem}
						variant="text"
						disabled={!selectedSupplier.supplier_rfc}
					>
						{t('addItem')}
					</NetcurioButton>
				</div>
			</div>
		</div>
	)
}

export default ItemsList
