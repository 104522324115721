import { NetcurioButton, NetcurioIcons } from '@netcurio-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddButton } from '../../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'

export const ProductsListButtons = ({
	actionHeaderButton
}: {
	actionHeaderButton: (action: string) => void
}) => {
	const { t } = useTranslation()
	return (
		<>
			<div>
				<AddButton translationKey="newProduct" onClick={() => actionHeaderButton('newProduct')} />
				<NetcurioButton
					variant="outlined"
					size="small"
					color="primary"
					endIcon={<NetcurioIcons.Upload />}
					onClick={() => actionHeaderButton('massiveUpload')}
				>
					{t('massiveUpload')}
				</NetcurioButton>
			</div>
			<div>
				<ExportButton onClick={() => actionHeaderButton('exportListProduct')} />
			</div>
		</>
	)
}
