import { getCurrentUser } from '@netcurio-ui/common'
import { t } from 'i18next'
import constants from '../../../../utilities/constants'
import fileToBase64 from '../../../../utilities/fileToBase64'

export const uploadProductsCatalog = async (files: File[], token: string) => {
	const currentUser = getCurrentUser()

	const file = await fileToBase64(files[0])

	const headers: HeadersInit = {
		'Content-Type': 'application/json; charset=UTF-8',
		Accept: 'application/json; charset=UTF-8',
		authorization: 'Bearer ' + token
	}

	if (currentUser && currentUser.company && currentUser.company.rfc) {
		headers['current-company'] = currentUser?.company?.rfc
	}

	return await fetch(`/api/uploads/product-catalog`, {
		method: 'POST',
		headers,
		body: JSON.stringify({
			actionType: constants.UPLOAD_FILES.UPLOAD_TYPE.PRODUCTS_CATALOG,
			file
		})
	})
		.then(async (res) => {
			let response: { [x: string]: any } = {}
			let content = await res.json()
			let fileName = res.headers.get('Content-Disposition')
				? res?.headers
						?.get('Content-Disposition')
						?.split('filename=')[1]
						.replace(new RegExp('"', 'g'), '')
				: t('logDefaultName')
			if (content.code) {
				if (content.code === constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS.INCORRECT_FILE_STRUCTURE) {
					response[constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE.ERROR] = content.code
				} else {
					response[constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE.UNKNOWN_ERROR] = content
				}
			} else {
				response[constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE.SUCCESSFUL] = {
					text: content.logFile,
					fileName: fileName,
					rowsProcessed: content.rowsProcessed,
					totalRows: content.totalRows
				}
			}
			return response
		})
		.catch((error) => {
			return { [constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE.UNKNOWN_ERROR]: error }
		})
}
