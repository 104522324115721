import { getCurrentUser, Roles } from '@netcurio-ui/common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow
} from '@netcurio-ui/components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCompanySettings } from '../../../../../hooks/useCompanySettings'
import { GoodReceipt } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import styles from '../../purchaseOrderDetail.module.scss'
import { GoodsReceiptRow } from './GoodsReceiptRow'

interface GoodsReceiptModuleProps {
	userRole: string
	newGoodReceipt(): void
	statusPO: string
	newGRModal: boolean
	dataGoodsReceipts?: Array<GoodReceipt>
	showGrDetail(position: number, idGoodReceive: string): void
}

export const GoodsReceiptModule: FC<GoodsReceiptModuleProps> = ({
	userRole,
	newGoodReceipt,
	statusPO,
	newGRModal,
	dataGoodsReceipts,
	showGrDetail
}) => {
	const { t } = useTranslation()

	const user = getCurrentUser()
	const { companySettings } = useCompanySettings({
		rfc: user?.company?.rfc || null
	})

	const isCustomer = userRole === Roles.CUSTOMER

	const disabled = !!(
		newGRModal ||
		(companySettings?.process_invoice_in_external_system && isCustomer) ||
		(statusPO &&
			(statusPO === Constants.STATUS.NEW ||
				statusPO === Constants.STATUS.REJECTED ||
				statusPO === Constants.STATUS.DELIVERED))
	)
	const showNewGRButton: boolean = userRole === Roles.CUSTOMER

	return (
		<NetcurioGrid
			item
			height="100%"
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			className={styles.containerDataTableDisplay}
		>
			<p className={styles.textHeaderDetailTable}>{t('relatedGoodsReceiptText')}</p>
			{showNewGRButton && (
				<NetcurioGrid marginBottom="1rem">
					<NetcurioButton
						startIcon={<NetcurioIcons.Add />}
						color="secondary"
						onClick={newGoodReceipt}
						variant="outlined"
						disabled={disabled}
					>
						{t('newGoodReceipt')}
					</NetcurioButton>
				</NetcurioGrid>
			)}
			<NetcurioGrid item width="100%" height="100%">
				<NetcurioTableContainer minHeight="100%">
					<NetcurioTable size="small" stickyHeader>
						<NetcurioTableHead>
							<NetcurioTableRow isDetailHeader>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('billText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('dateText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('createdByText')}</span>
								</NetcurioTableCell>
							</NetcurioTableRow>
						</NetcurioTableHead>
						<NetcurioTableBody>
							{dataGoodsReceipts &&
								dataGoodsReceipts?.map((goodReceipt: GoodReceipt, index) => (
									<GoodsReceiptRow
										key={goodReceipt.id}
										position={index}
										goodReceipt={goodReceipt}
										showGrDetail={showGrDetail}
									/>
								))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
