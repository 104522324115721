import React, { FC } from 'react'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow
} from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'
import { Roles } from '@netcurio-ui/common'
import { InvoiceRow } from './InvoiceRow'
import { Invoice } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import styles from '../../purchaseOrderDetail.module.scss'

interface InvoicesModuleProps {
	userRole?: string
	dataInvoices?: Array<Invoice>
	setShowNewInvoiceModal(open: boolean): void
	openInvoiceDetail(uuid: string): void
	statusPO: string
}

export const InvoicesModule: FC<InvoicesModuleProps> = ({
	statusPO,
	userRole,
	dataInvoices,
	setShowNewInvoiceModal,
	openInvoiceDetail
}) => {
	const { t } = useTranslation()
	const showNewInvoiceButton = !!(statusPO && userRole === Roles.SUPPLIER)
	const disabledNewInvoiceButton = !!(statusPO && statusPO === Constants.STATUS.REJECTED)

	return (
		<NetcurioGrid
			item
			height="100%"
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			className={styles.containerDataTableDisplay}
		>
			<p className={styles.textHeaderDetailTable}>{t('textInvoicesHeader')}</p>
			{showNewInvoiceButton && (
				<NetcurioGrid marginBottom="1rem">
					<NetcurioButton
						color="secondary"
						startIcon={<NetcurioIcons.ControlPointOutlined />}
						variant="outlined"
						disabled={disabledNewInvoiceButton}
						onClick={() => setShowNewInvoiceModal(true)}
					>
						{t('newInvoice')}
					</NetcurioButton>
				</NetcurioGrid>
			)}
			<NetcurioGrid item width="100%" height="100%">
				<NetcurioTableContainer minHeight="100%">
					<NetcurioTable size="small">
						<NetcurioTableHead>
							<NetcurioTableRow isDetailHeader>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('serieFolio')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('dateText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center" className={styles.tableCellInThirds}>
									<span>{t('total_amount')}</span>
								</NetcurioTableCell>
							</NetcurioTableRow>
						</NetcurioTableHead>
						<NetcurioTableBody>
							{dataInvoices &&
								dataInvoices?.map((invoice: Invoice) => (
									<InvoiceRow
										key={invoice.uuid}
										invoice={invoice}
										openInvoiceDetail={openInvoiceDetail}
									/>
								))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
