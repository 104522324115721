import React, { FC, useState } from 'react'
import { t } from 'i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { NewUserForm } from './NewUserForm'
import { NewUserModalProps } from './types'

/**
 * @param open: <boolean> is a state if you can view modal
 * @param close: <function> to close the modal
 * @return component <ReactNode>
 */
export const NewUserModal: FC<NewUserModalProps> = ({ open, close }) => {
	const [isValidForm, setIsValidForm] = useState<boolean>(false)
	const [sendUserForm, setSendUserForm] = useState<boolean>(false)

	const triggerAccept = () => {
		setSendUserForm(true)
	}

	return (
		<NetcurioDialog
			open={open}
			titleText={t('newUserTitle')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={triggerAccept} disabled={!isValidForm}>
						{t('createText')}
					</NetcurioButton>
				</>
			}
			contentFontSize="1.4rem"
		>
			<NewUserForm
				sendUserForm={sendUserForm}
				setIsValidForm={setIsValidForm}
				setSendUserForm={setSendUserForm}
				closeModal={close}
			/>
		</NetcurioDialog>
	)
}
