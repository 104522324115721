export default {
	PURCHASE_ORDERS: 'PO_LIST',
	INVOICES: 'INVOICE_LIST',
	GOOD_RECEIPTS: 'GR_LIST',
	PAYMENT_COMPLEMENTS: 'COMPLEMENT_LIST',
	DASHBOARDS: 'DASHBOARDS',
	REPORTS: 'REPORT_LIST',
	REQUEST_FOR_QUOTATION: 'RFQ',
	CONSUMPTION: 'CONSUMPTION_LIST',
	CREDIT_MEMO: 'CREDIT_MEMO_LIST',
	FOR_CREDIT_MEMO: 'REQUEST_FOR_CREDIT_MEMO_LIST'
}
