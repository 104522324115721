import React, { useState } from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio-ui/components'
import { CloseRFQ } from './CloseRFQ'
import { useTranslation } from 'react-i18next'

interface CloseRFQModalProps {
	openModal: boolean
	addNewComment(): void
	onCloseModal(): void
	resetDataPage(): void
}

/**
 * CloseRFQModal
 * @description Helps to display a modal when the user is trying to close a RFQ
 * @param openModal <boolean>: If `true`, the component is shown
 * @param addNewComment <void>: Callback fired when the component requests to add a new comment into CommentSection
 * @param closeModal <void>: Callback fired when the modal requests to be closed
 * @param resetDataPage <void>: Callback for reset the page
 * @return component <ReactNode>
 **/

export const CloseRFQModal = ({
	openModal,
	addNewComment,
	onCloseModal,
	resetDataPage
}: CloseRFQModalProps) => {
	const [closeRFQ, setCloseRFQ] = useState<boolean>(false)
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			headerTitleSeverity={Severity.Warning}
			titleText={t('warningAction')}
			open={openModal}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={onCloseModal}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={() => setCloseRFQ(true)}>
						{t('closeTicket')}
					</NetcurioButton>
				</>
			}
		>
			<CloseRFQ
				closeRequest={closeRFQ}
				closeMainModal={onCloseModal}
				addNewComment={addNewComment}
				resetBtnValue={() => setCloseRFQ(false)}
				resetDataPage={resetDataPage}
			/>
		</NetcurioDialog>
	)
}
