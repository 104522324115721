import React, { ReactElement } from 'react'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { NetcurioButton, NetcurioGrid } from '@netcurio-ui/components'
import { URLS } from '@netcurio-ui/common'
import constants from '../../utilities/constants'
import logo from '@netcurio-ui/assets/src/images/secondaryBlueNavyLogo.svg'
import landingImage from '@netcurio-ui/assets/src/imagesNewDesign/landingPassword.webp'
import styles from './expiredOrInvalidLink.module.scss'

interface ExpiredOrInvalidLinkProps {
	isExpired?: boolean
	redirect?: 'help' | 'passwordRecovery'
}

export const ExpiredOrInvalidLink = ({
	isExpired,
	redirect = 'help'
}: ExpiredOrInvalidLinkProps): ReactElement => {
	const history = useHistory()
	const redirectUser = (redirectionFlag) => {
		if (redirectionFlag === 'help') {
			window.open(constants.LINKS.LINK_HELP, '_self')
		} else {
			history.push(URLS.PASSWORD_RECOVERY)
		}
	}

	return (
		<>
			<NetcurioGrid container className={styles.body}>
				<NetcurioGrid item xs={4}>
					<div className={styles.container}>
						<img src={logo} className={styles.logo} />
						<span className={styles.loginLabel}>
							{isExpired ? t('expiredLinkTitle') : t('invalidLinkTitle')}
						</span>
						<span className={styles.infoLabel}>
							{isExpired ? t('expiredLinkMessage') : t('invalidLinkMessage')}
						</span>
						<NetcurioButton
							className={styles.helpButton}
							variant="contained"
							onClick={() => redirectUser(redirect)}
						>
							{t('helpButton')}
						</NetcurioButton>
					</div>
				</NetcurioGrid>
				<NetcurioGrid item alignItems="center" xs={8}>
					<picture className={styles.landingImage}>
						<source type="image/webp" srcSet={landingImage} />
						<source type="image/png" srcSet={landingImage} />
						<img src={landingImage} />
					</picture>
				</NetcurioGrid>
			</NetcurioGrid>
		</>
	)
}
