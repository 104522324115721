import React from 'react'
import { TotalDetail } from '../../creditMemoDetail/totalDetail'
import { CreditMemo } from '../../../types'
import ContainerAssociationDocument from '../containerAssociationDocument'
import styles from '../requestForCreditMemoDetail.module.scss'

interface LateralColumnProps {
	creditMemoDetail: CreditMemo
	creditMemos: CreditMemo[]
	showCreditMemoDetail: (creditMemo: CreditMemo) => void
	statusRFCM: string
	readonly: boolean
	buttonAction?: () => void
}

export const CreditMemoRelatedColumn = ({
	creditMemoDetail,
	creditMemos,
	showCreditMemoDetail,
	statusRFCM,
	readonly,
	buttonAction
}: LateralColumnProps): React.ReactElement => {
	return (
		<div className={styles.creditMemoLateralColumnContainer}>
			<div>
				<TotalDetail
					currency={creditMemoDetail?.currency}
					subtotal={creditMemoDetail?.subtotal}
					iva={creditMemoDetail?.iva}
					total={creditMemoDetail?.total}
					showButtonGoCreditMemo={true}
					buttonAction={buttonAction}
					isBorderShown
				/>
			</div>
			<ContainerAssociationDocument
				creditMemos={creditMemos}
				showCreditMemoDetail={showCreditMemoDetail}
				statusRFCM={statusRFCM}
				readOnly={readonly}
			/>
		</div>
	)
}
