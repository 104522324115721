import React, { useState } from 'react'
import { t } from 'i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { RejectRequestCreditMemo } from './RejectRequestCreditMemo'

export interface RejectRequestCreditMemoModalProps {
	closeModal(): void
	openModal: boolean
}

/**
 * @param openModal <boolean>: If `true`, the component is shown.
 * @param closeModal <void>: Callback fired when the modal requests to be closed.
 */

export const RejectRequestCreditMemoModal = ({
	closeModal,
	openModal
}: RejectRequestCreditMemoModalProps) => {
	const [disabledButton, setDisabledButton] = useState<boolean>(true)
	const [rejectCreditMemo, setRejectCreditMemo] = useState<boolean>(false)
	const [emptyServerError, setEmptyServerError] = useState<string>('warningAction')

	const resetModal = () => {
		closeModal()
		setEmptyServerError('warningAction')
	}

	return (
		<>
			<NetcurioDialog
				titleText={t('titleCreditMemoDetailsReject')}
				open={openModal}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={resetModal}>
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton
							variant="contained"
							onClick={() => setRejectCreditMemo(true)}
							disabled={disabledButton}
						>
							{t('rejectButtonText')}
						</NetcurioButton>
					</>
				}
			>
				<RejectRequestCreditMemo
					disabledButton={(disabled) => setDisabledButton(disabled)}
					rejectCreditMemo={rejectCreditMemo}
					closeMainModal={closeModal}
					emptyServerError={emptyServerError}
					resetBtnValue={() => setRejectCreditMemo(false)}
				/>
			</NetcurioDialog>
		</>
	)
}
