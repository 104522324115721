import { useNetcurioLoader } from '@netcurio-ui/components'
import { ApolloQueryResult } from 'apollo-boost'
import React, { useCallback, useEffect, useState } from 'react'
import { Invitation } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { ExpiredOrInvalidLink } from '../expiredOrInvalidLink'
import { RegisterUserToCompany } from './registerUserToCompany/RegisterUserToCompany'
import { defaultInvitation } from './utilities/defaultValues'
import { INVITE } from './utilities/queries'

export const SignUp = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [validLink, setValidLink] = useState(false)
	const [doneLoading, setDoneLoading] = useState(false)
	const [invitation, setInvitation] = useState<Invitation>(defaultInvitation)

	const queryDataInvite = useCallback(() => {
		const client = connection()
		setDoneLoading(false)
		showLoadingSpinner()
		const uuid = getUrlParameter('invite')
		client
			.query({ query: INVITE, variables: { uuid } })
			.then((res: ApolloQueryResult<{ Invitation: Invitation }>) => {
				setValidLink(true)
				setInvitation(res.data.Invitation)
			})
			.catch(({ graphQLErrors }) => {
				const expiredLink = graphQLErrors?.[0]?.code === Constants.LINKS.EXPIRED_LINK
			})
			.finally(() => {
				hideLoadingSpinner()
				setDoneLoading(true)
			})
	}, [showLoadingSpinner, hideLoadingSpinner])

	useEffect(() => {
		queryDataInvite()
	}, [])

	function renderComponent() {
		if (validLink && doneLoading) {
			return (
				<RegisterUserToCompany
					email={invitation.email}
					id={invitation.uuid}
					companies={invitation.companies}
				/>
			)
		} else if (!validLink && doneLoading) {
			return <ExpiredOrInvalidLink />
		} else {
			return <div />
		}
	}
	return renderComponent()
}
