import { Company, getCurrentUser, Roles, User } from '@netcurio-ui/common'
import * as queries from '../views/login/queries'
import { connection } from './connection'
import Constants from './constants'

const setCurrentUser = (user: User, company: any) => {
	user.company = company
	localStorage.setItem(Constants.AUTH.NETCURIO_USER, JSON.stringify(user))
}

export const getCompanyRole = () => {
	const currentUser = getCurrentUser()
	if (currentUser && currentUser.company !== undefined) {
		const currentCompanyRoles = currentUser.company.roles
		const findRole = currentCompanyRoles?.find((role) => {
			return role.id === Roles.CUSTOMER || role.id === Roles.SUPPLIER
		})
		return findRole?.id
	} else {
		return undefined
	}
}

const hasAdminRole = () => {
	return getCurrentUser()?.company?.is_admin
}

const isEnrollmentRequestApprover = () => {
	const company = getCurrentUser()?.company
	return company?.is_admin && company?.enrollment_request_approver
}

const getCompanySelected = (userCompanies: Company[], selectedRfc: Company | string) => {
	let company
	for (let i = 0; i < userCompanies.length; i++) {
		if (selectedRfc.includes(userCompanies[i].rfc as string)) {
			company = userCompanies[i]
			break
		}
	}
	return company
}

const getDefaultCompany = () => {
	return getCurrentUser()?.default_company
}

const updateUserInfo = async () => {
	const userData = getCurrentUser()
	const client = connection()
	await client
		.mutate({
			mutation: queries.LOGIN_USER
		})
		.then((result) => {
			const companySelected = getCompanySelected(
				result.data.loginUser.companies,
				userData?.company?.rfc
			)
			setCurrentUser(result.data.loginUser, companySelected)
		})
		.catch((error) => {
			console.log(error)
		})
}

const getCompaniesUnblocked = () => {
	const companies: Company[] = getCurrentUser()?.companies,
		companiesUnblocked: Company[] = []
	companies.forEach((company: Company) => {
		if (company?.status?.key !== Constants.USER_STATUS.BLOCKED) {
			companiesUnblocked.push(company)
		}
	})
	return companiesUnblocked
}

export default {
	setCurrentUser,
	getCompanySelected,
	getDefaultCompany,
	getCompanyRole,
	hasAdminRole,
	isEnrollmentRequestApprover,
	getCompaniesUnblocked,
	updateUserInfo
}
