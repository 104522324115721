import React, { FC } from 'react'
import { InformationRFQItem } from '../types'
import { NetcurioTooltip } from '@netcurio-ui/components'
import { parseTimestampToTimeZone, dateFormatter } from '@netcurio-ui/common'
import { useTranslation } from 'react-i18next'
import Formatter from '../../../utilities/formatter'
import classNames from 'classnames'
import styles from '../requestForQuotation.module.scss'

interface RowTableProps {
	informationItem: InformationRFQItem
}

export const RowTable: FC<RowTableProps> = ({
	informationItem: { position, product_description, product_code, unit, quantity, required_delivery_date }
}) => {
	const { t } = useTranslation()
	return (
		<>
			<div className={styles.containerColumRFC}>
				<p className={classNames(styles.textColumnMainTableRFQ, styles.smallHeader)}>{position}</p>
				<NetcurioTooltip title={product_description} placement="right">
					<div className={classNames(styles.textColumnMainTableRFQ, styles.bigHeaderRFQ)}>
						<div className={styles.textColumnProductMainTableRFQ}>
							<div className={styles.textInLineRFQ}>
								<p className={styles.multilineInfo}>{t('itemCode')}</p>
								<p className={classNames(styles.multilineInfo, styles.productDescriptionRow)}>
									{t(Formatter.nullValueFormattingToNA(product_code))}
								</p>
							</div>
							<div className={styles.textInLineRFQ}>
								<p className={styles.multilineInfo}>{t('itemDescription')}</p>
								<p className={classNames(styles.multilineInfo, styles.productDescriptionRow)}>
									{product_description}
								</p>
							</div>
						</div>
					</div>
				</NetcurioTooltip>
				<p className={classNames(styles.textColumnMainTableRFQ, styles.smallHeader)}>
					{quantity + ' ' + unit}
				</p>
				<p className={classNames(styles.textColumnMainTableRFQ, styles.smallHeader)}>
					{dateFormatter.format(parseTimestampToTimeZone(required_delivery_date))}
				</p>
			</div>
		</>
	)
}
