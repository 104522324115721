import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TypesBoxTableRow, TypesGoodReceipts, TypesTableRow } from './types'
import { parseTimestampToTimeZone, dateFormatter, URLS } from '@netcurio-ui/common'
import Formatter from '../../utilities/formatter'
import Constants from '../../utilities/constants'
import '../../style.scss'
import styles from './goodsReceiptList.module.scss'

const TableRow = (props: TypesTableRow): React.ReactElement => {
	const dataGoodReceipt: TypesGoodReceipts = props.informationTable
	const history = useHistory<string>()
	const [dataBox, setDataBox] = useState<TypesBoxTableRow>({
		hoverColumn: '',
		boxLeftHover: '',
		boxRightHover: ''
	})

	const onMouseEnterHandler = () => {
		setDataBox((prevState) => ({
			...prevState,
			hoverColumn: styles.hoverColumnGR,
			boxLeftHover: styles.boxLeftHover,
			boxRightHover: styles.boxRightHover
		}))
	}

	const onMouseLeaveHandler = () => {
		setDataBox((prevState) => ({
			...prevState,
			hoverColumn: '',
			boxLeftHover: '',
			boxRightHover: ''
		}))
	}

	const linkTo = (id: string) => {
		history.push(`${URLS.GR_DETAIL}?goodsReceipt=${id}`)
	}

	return (
		<div
			className={`${styles.rowContainer} ` + dataBox.hoverColumn}
			onClick={() => linkTo(dataGoodReceipt.id)}
			onMouseEnter={() => onMouseEnterHandler()}
			onMouseLeave={() => onMouseLeaveHandler()}
		>
			<div className={dataBox.boxLeftHover} />
			<p className={`${styles.textColumnMainTableGR} ${styles.rowsFieldGR}`}>{dataGoodReceipt.id}</p>
			<p className={`${styles.textColumnMainTableGR} ${styles.rowsFieldGR}`}>
				{dataGoodReceipt.customer_reference || Constants.SYMBOL.HYPHEN}
			</p>
			<p className={`${styles.textColumnMainTableGR} ${styles.rowsFieldGR}`}>
				{dateFormatter.format(parseTimestampToTimeZone(dataGoodReceipt.created_at))}
			</p>
			<p className={`${styles.textColumnMainTableGR} ${styles.rowsFieldGR}`}>
				{dataGoodReceipt.created_by.name + ' ' + dataGoodReceipt.created_by.lastname}
			</p>
			<p className={`${styles.textColumnMainTableGR} ${styles.rowsFieldGR}`}>
				{Formatter.id(dataGoodReceipt.purchase_order.id)}
			</p>
			<p className={`${styles.textColumnMainTableGR} ${styles.supplierColumn}`}>
				{dataGoodReceipt.purchase_order.supplier.name}
			</p>
			<div className={dataBox.boxRightHover} />
		</div>
	)
}
export default TableRow
