import React, { FC } from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'

interface RemoveBranchOfficeProps {
	open: boolean
	onClose(): void
	onAccept(): void
}
export const RemoveBranchOffice: FC<RemoveBranchOfficeProps> = ({ open, onClose, onAccept }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('removeBranchOfficeModalTitle')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('deleteText')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('removeBranchOfficeMessage')}</p>
		</NetcurioDialog>
	)
}
