import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NetcurioButton, NetcurioDialog, NetcurioMenuItem, NetcurioSelect } from '@netcurio-ui/components'

interface ConfirmRejectPOProps {
	open: boolean
	onClose(): void
	onAccept: (dropdownValue) => any
}

const dropdownItems = [
	{ label: 'shortage', value: 'Desabasto' },
	{ label: 'discontinued', value: 'Descontinuado' },
	{ label: 'code_error', value: 'Error en código' },
	{ label: 'price_error', value: 'Error en precio' },
	{ label: 'credit_limit_exceeded', value: 'Límite de crédito excedido' },
	{ label: 'um_error', value: 'UM errónea' }
]

/**
 * ConfirmRejectPO
 * @description Helps to display a modal when the user is trying to reject a PO
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <void>: Callback fired to close modal
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const ConfirmRejectPO: FC<ConfirmRejectPOProps> = ({ onAccept, onClose, open }) => {
	const { t } = useTranslation()
	const [dropdownValue, setDropdownValue] = useState<string>('')

	const handleOnChange = (e): void => {
		setDropdownValue(e as string)
	}

	const triggerClose = () => {
		onClose()
		setDropdownValue('')
	}
	return (
		<NetcurioDialog
			titleText={t('rejectOrder')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={triggerClose}>{t('goBackText')}</NetcurioButton>
					<NetcurioButton
						variant="contained"
						onClick={() => onAccept(dropdownValue)}
						disabled={!dropdownValue}
					>
						{t('rejectButtonText')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionRejectAllOrder')}</p>
			<div className={'rejectPosition'}>
				<p>{t('rejectedReason')}</p>
				<NetcurioSelect
					minWidth="50%"
					value={dropdownValue}
					onChange={(e) => handleOnChange(e.target.value)}
				>
					{dropdownItems.map((status, index) => (
						<NetcurioMenuItem key={index} value={status.label}>
							{status.value}
						</NetcurioMenuItem>
					))}
				</NetcurioSelect>
			</div>
		</NetcurioDialog>
	)
}
