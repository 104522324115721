import React from 'react'
import { Translation } from 'react-i18next'
import Formatter from '../../utilities/formatter'
import classNames from 'classnames'
import styles from './creditMemoDetail.module.scss'
import { NetcurioButton, NetcurioIcons, NetcurioTheme } from '@netcurio-ui/components'

interface TotalDetailProps {
	currency: string
	subtotal: number
	iva: number
	total: number
	showButtonGoCreditMemo?: boolean
	buttonAction?: () => void
	isBorderShown?: boolean
}

export const TotalDetail = ({
	currency,
	subtotal,
	iva,
	total,
	showButtonGoCreditMemo,
	isBorderShown,
	buttonAction
}: TotalDetailProps) => {
	function getButtonModal() {
		if (showButtonGoCreditMemo) {
			return (
				<Translation>
					{(t) => (
						<div className={styles.containerButtonGoCreditMemo}>
							<NetcurioButton
								endIcon={<NetcurioIcons.ArrowCircleRight />}
								onClick={() => buttonAction()}
								variant="outlined"
							>
								{t('goToCM')}
							</NetcurioButton>
						</div>
					)}
				</Translation>
			)
		} else return null
	}

	return (
		<NetcurioTheme>
			<Translation>
				{(t) => (
					<div
						className={classNames(styles.creditMemoTotalDetailContainer, {
							[styles.cmTotalDetailContainerBorder]: isBorderShown
						})}
					>
						<div className={styles.cmTotalDetailHeaderContainer}>
							<p className={styles.cmTotalDetailHeader}>{t('netTotal')}</p>
						</div>
						<div className={styles.creditMemoTotalDetailInfoContainer}>
							<p className={styles.cmTotalLabel}>{t('currency')}</p>
							<p className={styles.cmTotalLabel}>{currency}</p>
						</div>
						<div className={styles.creditMemoTotalDetailInfoContainer}>
							<p className={styles.cmTotalLabel}>{t('subtotal')}</p>
							<p className={styles.cmTotalLabel}>
								{Formatter.currency.format(subtotal ? subtotal : 0)}
							</p>
						</div>
						<div className={styles.creditMemoTotalDetailInfoContainer}>
							<p className={styles.cmTotalLabel}>{t('iva')}</p>
							<p className={styles.cmTotalLabel}>{Formatter.currency.format(iva ? iva : 0)}</p>
						</div>
						<div
							className={`${styles.creditMemoTotalDetailInfoContainer} ${styles.cmTotalBorder}`}
						>
							<p className={styles.cmTotalLabel}>{t('total')}</p>
							<p className={styles.cmTotalLabel}>
								{Formatter.currency.format(total ? total : 0)}
							</p>
						</div>
						{getButtonModal()}
					</div>
				)}
			</Translation>
		</NetcurioTheme>
	)
}
