import { TypesDataTable, tableFillSelectionFilter } from '@netcurio-ui/common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	NumericInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio-ui/components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './creditMemoList.module.scss'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { TypesMainTable, TypesStyleMainTable } from './types'

export const CreditMemoTable = ({
	closeFilterContainerBar,
	dataCreditMemo,
	dataFiltersArray,
	deleteFilterActive,
	deleteRange,
	filterContainerBar,
	filteringCreditMemo,
	filtersOfTypeStatus,
	rowsActual,
	setActivePagination,
	totalRows,
	userRole,
	showBarLoader,
	setShowBarLoader
}: TypesMainTable): ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [styleMainTable, setStyleMainTable] = useState<TypesStyleMainTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		activeHeight: true
	})
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		textHeader: styles.textHeaderListCM,
		statusFilter: 'display-none',
		dateFilter: 'display-none',
		numericFilter: 'display-none',
		textFilter: 'display-none',
		actualField: 'date',
		fillStatusInputFilter: objectStatusFilterArray(),
		initDate: undefined,
		finalDate: undefined,
		initDateErrorText: '',
		finalDateErrorText: '',
		minAmount: '',
		maxAmount: '',
		filterValue: '',
		initRange: undefined,
		finalRange: undefined,
		minRange: undefined,
		maxRange: undefined,
		deleteRange: false
	})

	useEffect(() => {
		const finalHeight: number = window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT
		const mainHeight: number = window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		setShowBarLoader(false)
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: finalHeight,
			mainContainerHeight: mainHeight
		}))
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRole))
	}, [userRole])

	useEffect(() => {
		if (totalRows && rowsActual)
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)

		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			element && element.removeEventListener('scroll', handlePagination)
		}
	}, [totalRows, rowsActual])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'date') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initRange: undefined,
					finalRange: undefined
				}))
			} else if (dataTable.actualField === 'total') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					minRange: undefined,
					maxRange: undefined
				}))
			}
		}

		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (dataTable.fillStatusInputFilter.length !== objectStatusFilterArray().length)
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
		} else if (filtersOfTypeStatus.status.dataFilter !== '' && filtersOfTypeStatus.status.dataFilter) {
			// Condition explanation: only add dataFilter if dataTable.fillStatusInputFilter doesn't includes it already
			// This block is needed when status filters are closed and pushed again to SelectInputFilter
			if (
				!dataTable.fillStatusInputFilter.some(
					(selectionFilter) =>
						selectionFilter.getSelectedParameter() === filtersOfTypeStatus.status.dataFilter
				)
			) {
				// add dataFilter to dataTable.fillStatusInputFilter
				setDataTable((prevState) => {
					const newFillStatusInputFilter = prevState.fillStatusInputFilter
					newFillStatusInputFilter.push(
						new tableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter)
					)

					return {
						...prevState,
						fillStatusInputFilter: newFillStatusInputFilter
					}
				})
			}
		}
		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - 40,
				mainContainerHeight: prevState.mainContainerHeight - 40
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + 40,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
		}

		setResultNotFound(totalRows < 1)

		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [deleteRange, filtersOfTypeStatus, filterContainerBar, totalRows, dataTable])

	const handlePagination = () => {
		listHelper.handlePagination(
			'tableInformation',
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string
		switch (filterActual) {
			case 'dateFilter':
				classActualField = `request-for-credit-memo-${fieldStyle}-filter`
				activeFilter = dataTable.dateFilter
				break
			case 'statusFilter':
				classActualField = `request-for-credit-memo-${fieldStyle}-filter`
				activeFilter = dataTable.statusFilter
				break
			case 'numericFilter':
				classActualField = `credit-memo-${fieldStyle}-filter`
				activeFilter = dataTable.numericFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}

		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer)
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - 40,
					mainContainerHeight: prevState.mainContainerHeight - 40
				}))

			setDataTable((prevState) => ({
				...prevState,
				initRange: undefined,
				finalRange: undefined,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				minRange: undefined,
				maxRange: undefined,
				minAmount: undefined,
				maxAmount: undefined,
				statusFilter: 'display-none',
				dateFilter: 'display-none',
				numericFilter: 'display-none',
				textFilter: 'display-none',
				actualField: field,
				textHeader: styles.textHeaderListOnClick,
				[filterActual]: classActualField
			}))
		} else if (dataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				headerTableCreditMemoClick: 'header-table-credit-memo-list',
				textHeader: styles.textHeaderListCM,
				initRange: undefined,
				finalRage: undefined,
				[filterActual]: 'display-none'
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		fieldValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		showLoadingSpinner()
		let initRange: number | Dayjs = dataTable.initRange
		let finalRange: number | Dayjs = dataTable.finalRange
		const minRange: number | Dayjs = dataTable.minRange
		const maxRange: number | Dayjs = dataTable.maxRange
		if (objectRange) {
			objectRange.initRange && (initRange = objectRange.initRange)
			objectRange.finalRange && (finalRange = objectRange.finalRange)
		}
		minRange && (initRange = minRange)
		maxRange && (finalRange = maxRange)

		if (sortField !== '') {
			filteringCreditMemo(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringCreditMemo(
				0,
				sortField,
				order,
				fieldFilter,
				fieldValue,
				initRange,
				finalRange,
				undefined
			)
		}

		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const selectionFilter = (status: string, position: number) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 5) {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillStatusInputFilter.splice(position, 1)
		}
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + 40,
			mainContainerHeight: prevState.mainContainerHeight + 40
		}))
		setDataTable((prevState) => ({
			...prevState,
			numericFilter: 'display-none',
			statusFilter: 'display-none',
			dateFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.tableHeaderClicked,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChangeQty = (evt: ChangeEvent<HTMLInputElement>, order: string) => {
		evt.preventDefault()
		let newFilterValue: number = evt.target.value
		const value = evt.target.value
		if (parseFloat(evt.target.value) < 0) {
			newFilterValue = undefined
		}
		if (order === 'initial') {
			setDataTable((prevState) => ({
				...prevState,
				minRange: newFilterValue,
				minAmount: value
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				maxRange: newFilterValue,
				maxAmount: value
			}))
		}
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			dataTable.minRange,
			dataTable.maxRange
		)
	}

	const emptyFilterField = () =>
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			minAmount: '',
			maxAmount: '',
			finalDate: undefined,
			initDate: undefined
		}))

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	return (
		<div className="margins-table">
			<div className={styles.headerMainTableCM}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={dataTable.statusFilter}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={dataTable.fillStatusInputFilter}
			/>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<NumericInputFilter
				showNumFilter={dataTable.numericFilter}
				actualField={dataTable.actualField}
				minAmount={dataTable.minAmount}
				maxAmount={dataTable.maxAmount}
				handleTextFilterChangeQty={handleTextFilterChangeQty}
				handleFilterKeyDown={handleFilterKeyDown}
				callDataList={callDataList}
				closeFilter={closeFilter}
			/>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.CREDIT_MEMOS}
				/>
			)}
			<div
				className="tableInformationWhiteStyle"
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className="tableInformation" style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataCreditMemo).map((key) => (
						<TableRow
							key={key}
							dataCreditMemo={dataCreditMemo[parseInt(key)]}
							userRole={userRole}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
