import { tableFillSelectionFilter, Roles } from '@netcurio-ui/common'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import Constants from '../../utilities/constants'
import styles from './creditMemoList.module.scss'

export function objectHeaderArray(userRole: Roles): Array<tableFillHeader> {
	const seriesFolioCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'serieFolioCreditMemo',
		styles.seriesFolioHeader,
		'',
		'serie' + 'folio',
		'text'
	)
	const statusCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'statusHeader',
		styles.statusHeader,
		'',
		'status',
		'status'
	)
	const supplierCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'supplierLabelCreditMemo',
		styles.supplierHeader,
		'',
		'sender',
		'text'
	)
	const createdAtCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'createdAtTextCreditMemo',
		styles.createdAtHeader,
		'',
		'date',
		'date'
	)
	const totalCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'totalAmountCreditMemo',
		styles.totalAmountHeader,
		'',
		'total',
		'numeric'
	)
	const invoiceRelatedCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'associatedInvoiceCreditMemo',
		styles.associatedInvoiceHeader,
		'',
		'reference',
		'text'
	)
	const customerCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'customerLabelCreditMemo',
		styles.customerHeader,
		'',
		'receiver',
		'text'
	)

	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		seriesFolioCreditMemoColumn,
		statusCreditMemoColumn,
		supplierCreditMemoColumn,
		createdAtCreditMemoColumn,
		totalCreditMemoColumn,
		invoiceRelatedCreditMemoColumn
	]

	const objectHeaderArraySupplier: Array<tableFillHeader> = [
		seriesFolioCreditMemoColumn,
		statusCreditMemoColumn,
		customerCreditMemoColumn,
		createdAtCreditMemoColumn,
		totalCreditMemoColumn,
		invoiceRelatedCreditMemoColumn
	]

	if (userRole === Roles.CUSTOMER) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const acceptedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.LINKS.MISSING_LINK
	)
	const errorStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.CREDIT_MEMO_STATUS.ERROR
	)
	const rejectedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.CREDIT_MEMO_STATUS.VOIDED
	)
	const closedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.CREDIT_MEMO_STATUS.ACCOUNTED
	)
	const cancelStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.CREDIT_MEMO_STATUS.CANCELED
	)

	return [
		errorStatusOption,
		acceptedStatusOption,
		rejectedStatusOption,
		closedStatusOption,
		cancelStatusOption
	]
}
