import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Invoice, InvoiceItem as InvoiceItemType, InvoiceValidationEnum } from '../../../../types'
import constants from '../../../../utilities/constants'
import { InvoiceItem } from './InvoiceItem/InvoiceItem'

interface InvoiceItemListProps {
	invoiceItems: Array<InvoiceItemType>
	referenceType?: string | null
	invoice: Invoice
}

export const InvoiceItemList: FC<InvoiceItemListProps> = ({
	invoiceItems,
	referenceType,
	invoice
}): ReactElement => {
	const { t } = useTranslation()
	const [renderColumnWithError, setRenderColumnWithError] = useState<boolean>(false)
	const isAdvance = invoice.advance

	const showRelatedPositionColumn = referenceType !== constants.DOCUMENT_TYPE.STANDALONE && !isAdvance

	useEffect(() => {
		setRenderColumnWithError(
			invoiceItems.some(
				(item: InvoiceItemType) =>
					item.price_validation === InvoiceValidationEnum.NOT_OK ||
					item.tax_validation === InvoiceValidationEnum.NOT_OK ||
					item.currency_validation === InvoiceValidationEnum.NOT_OK ||
					item.quantity_validation === InvoiceValidationEnum.NOT_OK ||
					item.missing_merchandise_validation === InvoiceValidationEnum.NOT_OK
			)
		)
	}, [invoiceItems])

	return (
		<NetcurioTableContainer minHeight="100%">
			<NetcurioTable size="small">
				<colgroup>
					{renderColumnWithError && <col style={{ width: '4%' }} />}
					<col style={{ width: renderColumnWithError ? '3%' : '7%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '8%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '12%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '7%' }} />
					{showRelatedPositionColumn && <col style={{ width: '19%' }} />}
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						{renderColumnWithError && <NetcurioTableCell align="center"></NetcurioTableCell>}
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionConceptTooltip')} placement="top">
								<span>{t('numberText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('descriptionProductInvoicedTooltip')} placement="top">
								<span>{t('conceptText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('codeTooltipText')} placement="top">
								<span>{t('codeText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('quantityProductInvoicedTooltip')} placement="top">
								<span>{t('quantity')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('unitPriceTooltip')} placement="top">
								<span>{t('unitPrice')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('invoicedAmountProductTooltip')} placement="top">
								<span>{t('amountText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('indicatorTaxTooltip')} placement="top">
								<span>{t('taxIVA')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						{showRelatedPositionColumn && (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('relatedPositionPOTooltip')} placement="top">
									<span>{t('positionRelated')}</span>
								</NetcurioTooltip>
							</NetcurioTableCell>
						)}
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{invoiceItems.map((item: InvoiceItemType) => (
						<InvoiceItem
							key={item.position}
							invoiceItem={item}
							referenceType={referenceType}
							renderColumnWithError={renderColumnWithError}
						/>
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
