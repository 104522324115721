import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Filter, URLS } from '@netcurio-ui/common'
import { ProductList } from './productsList/ProductList'
import { Order } from '../../../../types'
import ProductDetail from './productDetails/productDetail'
import Constants from '../../../../utilities/constants'

interface Props {
	chooseMainHeaderButton: (button: string) => void
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}

export default function ProductSelectionScreen({ chooseMainHeaderButton, setDataFilterArray }: Props) {
	const { path } = useRouteMatch()

	useEffect(() => {
		if (window.location.pathname === URLS.ADMINISTRATION + '/' + Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB)
			chooseMainHeaderButton('productsList')
		else chooseMainHeaderButton('productDetailTab')
	}, [window.location.pathname])

	return (
		<Switch>
			<Route exact path={path}>
				<ProductList setDataFilterArray={setDataFilterArray} />
			</Route>
			<Route path={`${path}/:code`}>
				<ProductDetail />
			</Route>
		</Switch>
	)
}
