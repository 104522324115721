import React from 'react'
import { t } from 'i18next'
import { parseTimestampToTimeZone, Company, dateFormatter } from '@netcurio-ui/common'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import styles from './ticketList.module.scss'
import '../../style.scss'

interface Props {
	id: string
	status: string
	created_at: string
	sender: Company
	my_counterpart: Company
	company_is_sender: boolean
	category: string
}

export default function TableRow({
	id,
	status,
	created_at,
	sender,
	my_counterpart,
	company_is_sender,
	category
}: Props) {
	return (
		<div
			className={`${styles.containerColumnList} ${styles.hoverColumnTicketList}`}
			onClick={() => (location.href = `/ticketdetail?ticket=${id}&sender=${sender.rfc}`)}
		>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldId}`}>{Formatter.id(id)}</p>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldCategory}`}>{category}</p>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldStatus}`}>{t(status)}</p>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldCounterpart}`}>
				{my_counterpart.name}
			</p>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldDate}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
			<p className={`${styles.textColumnMainTicket} ${styles.fieldAttend}`}>
				{t(
					company_is_sender
						? Constants.TICKET_REVIEW.MY_COUNTERPART
						: Constants.TICKET_REVIEW.MY_COMPANY
				)}
			</p>
		</div>
	)
}
