import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { RelatedCompaniesList } from './relatedCompaniesList/relatedCompaniesList'
import { Filter, URLS } from '@netcurio-ui/common'
import { Order } from '../../../../types'
import RelatedCompanyDetail from './relatedCompanyDetail/relatedCompanyDetail'
import Constants from '../../../../utilities/constants'

interface Props {
	chooseMainHeaderButton: (button: string) => void
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}

export default function RelatedCompaniesSelectionScreen({
	chooseMainHeaderButton,
	setDataFilterArray
}: Props) {
	const { path } = useRouteMatch()

	useEffect(() => {
		if (
			window.location.pathname ===
			URLS.ADMINISTRATION + '/' + Constants.ADMIN.ADMIN_TABS.RELATED_COMPANIES_TAB
		)
			chooseMainHeaderButton('relatedCompaniesList')
		else chooseMainHeaderButton('relatedCompanyDetail')
	}, [window.location.pathname])

	return (
		<Switch>
			<Route exact path={path}>
				<RelatedCompaniesList setDataFilterArray={setDataFilterArray} />
			</Route>
			<Route path={`${path}/:relatedCompany`}>
				<RelatedCompanyDetail />
			</Route>
		</Switch>
	)
}
