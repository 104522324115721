import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	PriceField,
	NetcurioTooltip,
	NetcurioTextField,
	NetcurioFormControl,
	NetcurioInputLabel,
	NetcurioSelect,
	NetcurioMenuItem,
	NetcurioButton,
	NetcurioDatePicker,
	NetcurioSelectChangeEvent
} from '@netcurio-ui/components'
import { getCurrency, isValidQuantity } from '@netcurio-ui/common'
import { InformationRFQItem, ItemQ, SelectedProductItemProps, ValidationItem } from '../types'
import Formatter from '../../../utilities/formatter'
import Constants from '../../../utilities/constants'
import classNames from 'classnames'
import styles from './NewQuotation.module.scss'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { connection } from '../../../utilities/connection'
import GlobalQueries from '../../../components/queries'
import dayjs from 'dayjs'

interface QuotationRegisterUncataloguedRowProps {
	itemPosition: number
	itemQuotation: ItemQ
	keyRFQ: number
	informationItem: InformationRFQItem
	selectedProductItem(selectedProductItemProps: SelectedProductItemProps): void
	handleItemFieldChange(
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
		index: number,
		field: string,
		requestPosition: number
	): void
	deleteItem(keyRFQ: number, itemPosition: number): void
	cleanReason: boolean
	updateInfoRow(positionRFQ: number): void
	resetDataQuotation: boolean
	validationItem?: ValidationItem
}
interface CatalogueItem {
	label: string
	value: string
}
const taxes = [
	{ label: '0%', value: '0.0' },
	{ label: '8%', value: '0.08' },
	{ label: '16%', value: '0.16' }
]
export const QuotationRegisterUncataloguedRow: FC<QuotationRegisterUncataloguedRowProps> = ({
	keyRFQ,
	itemQuotation,
	handleItemFieldChange,
	itemPosition,
	deleteItem,
	informationItem,
	validationItem,
	cleanReason,
	updateInfoRow,
	resetDataQuotation
}) => {
	const minDate = dayjs()
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [productValue, setProductValue] = useState<string>('')
	const [amountValue, setAmountValue] = useState<string>('')
	const [unitMeasureValue, setUnitMeasureValue] = useState<string>('PZA')
	const [taxValue, setTaxValue] = useState<string>('Selecciona')
	const [unitPriceValue, setUnitPriceVale] = useState<string>('')
	const [disabledInputPrice, setDisabledInputPrice] = useState<boolean>(true)
	const [measurementUnits, setAllMeasurementsUnits] = useState<CatalogueItem[]>([])
	const [itemDate, setItemDate] = useState<dayjs.Dayjs | null>(null)

	let isMounted = false

	useEffect(() => {
		getAllMeasurementUnits()
		getItemDate()
	}, [])

	useEffect(() => {
		isMounted = true
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		if (!itemQuotation.product && cleanReason) {
			initialProductUncatalogued()
			updateInfoRow(0)
		}
		if (resetDataQuotation) {
			deleteItemRow()
		}
		if (isMounted) {
			initialProductUncatalogued()
			updateInfoRow(0)
		}
	}, [itemQuotation.product, cleanReason, resetDataQuotation])

	const initialProductUncatalogued = () => {
		if (itemPosition === 0) {
			const productValue =
				(informationItem.product_code ? informationItem.product_code : 'N/A') +
				' - ' +
				informationItem.product_description
			const amountValue = informationItem.quantity
			setProductValue(productValue)
			setAmountValue(amountValue.toString())
			handleItemFieldChange(
				{
					target: {
						value: productValue
					}
				} as ChangeEvent<HTMLInputElement>,
				itemPosition,
				'productItem',
				keyRFQ
			)
			handleItemFieldChange(
				{
					target: {
						value: amountValue.toString()
					}
				} as ChangeEvent<HTMLInputElement>,
				itemPosition,
				'amount',
				keyRFQ
			)
		}
		setUnitMeasureValue(informationItem.unit)
		handleItemFieldChange(
			{
				target: {
					value: informationItem.unit
				}
			} as ChangeEvent<HTMLInputElement>,
			itemPosition,
			'unitMeasure',
			keyRFQ
		)
	}

	const onChangeItemField = (event: ChangeEvent<HTMLInputElement>, nameField: string) => {
		handleItemFieldChange(event, itemPosition, nameField, keyRFQ)
	}

	const deleteItemRow = () => {
		setProductValue('')
		setAmountValue('')
		setUnitMeasureValue('')
		setTaxValue('')
		setUnitPriceVale('')
		setDisabledInputPrice(true)
		deleteItem(keyRFQ, itemPosition)
	}

	const setNewPrice = (
		tempTarget: ChangeEvent<HTMLInputElement>,
		nameField: string,
		valueEvent: number
	) => {
		handleItemFieldChange(tempTarget, itemPosition, nameField, keyRFQ)
		setUnitPriceVale(valueEvent.toString())
	}

	const getAllMeasurementUnits = () => {
		client
			.query({
				query: GlobalQueries.MEASUREMENT_UNITS
			})
			.then((result) => {
				const items = result.data.MeasurementUnits
				const allMeasurementUnits: CatalogueItem[] = items.map((item) => ({
					label: t(item.code),
					value: item.code
				}))

				setAllMeasurementsUnits(allMeasurementUnits)
			})
			.catch((error) => console.log(error))
	}

	const getItemDate = () => {
		setItemDate(itemQuotation.requiredDate ? dayjs(itemQuotation.requiredDate) : null)
	}

	const handleUmOnChange = (event: NetcurioSelectChangeEvent) => {
		setUnitMeasureValue(event.target.value)
		handleItemFieldChange(
			{
				target: {
					value: event.target.value
				}
			} as ChangeEvent<HTMLInputElement>,
			itemPosition,
			'unitMeasure',
			keyRFQ
		)
	}

	const handleSelectTax = (event: NetcurioSelectChangeEvent) => {
		setTaxValue(event.target.value)
		handleItemFieldChange(
			{
				target: {
					value: event.target.value
				}
			} as ChangeEvent<HTMLInputElement>,
			itemPosition,
			'taxIVA',
			keyRFQ
		)
	}
	const handleSelectQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === '.') {
			event.target.value = '0.'
		}
		if (isValidQuantity(event.target.value)) {
			setAmountValue(event.target.value)
			onChangeItemField(event, 'amount')
		}
	}

	return (
		<div className={styles.rowRegisterItem}>
			<div className={styles.resizeProductNewQuotation}>
				<NetcurioTextField
					fullWidth
					label={t('textProductUncatalogued')}
					value={productValue}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setProductValue(event.target.value)
						onChangeItemField(event, 'productItem')
					}}
				/>
			</div>
			<NetcurioTooltip title={<label>{t('quantity')}</label>} placement="top">
				<div className={styles.containerAmountField}>
					<NetcurioTextField
						label={t('quantity')}
						value={amountValue}
						onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectQuantity(event)}
						error={validationItem?.amountError}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t(unitMeasureValue)}</label>} placement="top">
				<div className={styles.resizeUmNewQuotation}>
					<NetcurioFormControl fullWidth>
						<NetcurioInputLabel id="unitMeasure">{t('unitMeasure')}</NetcurioInputLabel>
						<NetcurioSelect
							labelId="unitMeasure"
							variant="outlined"
							label={t('unitMeasure')}
							displayEmpty
							fullWidth
							size="medium"
							value={unitMeasureValue}
							onChange={(event: NetcurioSelectChangeEvent) => handleUmOnChange(event)}
						>
							{measurementUnits.map((item, i) => (
								<NetcurioMenuItem key={i} value={item.value}>
									<span>{item.label}</span>
								</NetcurioMenuItem>
							))}
						</NetcurioSelect>
					</NetcurioFormControl>
				</div>
			</NetcurioTooltip>
			<div
				className={styles.marginUnitPriceRowRegisterUncatalogued}
				onFocus={() => setDisabledInputPrice(false)}
			>
				<PriceField
					idPriceField={'inputPriceItem' + keyRFQ + Constants.SYMBOL.HYPHEN + itemPosition}
					label={t('unitPricePlaceholder')}
					readValue={
						disabledInputPrice
							? parseFloat(unitPriceValue) > 0
								? getCurrency(parseFloat(unitPriceValue))
								: unitPriceValue
							: unitPriceValue
					}
					onChangeText={setNewPrice}
					onBlurPrice={() => setDisabledInputPrice(true)}
					error={validationItem?.unitPriceError}
				/>
			</div>
			<NetcurioTooltip title={<label>{t('taxIVA')}</label>} placement="top">
				<div className={styles.resizeItemFieldDropDowUncatalogued}>
					<NetcurioFormControl fullWidth>
						<NetcurioInputLabel id="taxIVA">{t('taxIVA')}</NetcurioInputLabel>
						<NetcurioSelect
							labelId="taxIVAtaxIVA"
							label={t('taxIVA')}
							displayEmpty
							fullWidth
							size="medium"
							value={taxValue}
							onChange={(event: NetcurioSelectChangeEvent) => handleSelectTax(event)}
							error={validationItem?.taxError}
						>
							<NetcurioMenuItem value={t('placeholderSelect')}>
								<em>{t('placeholderSelect')}</em>
							</NetcurioMenuItem>
							{taxes.map((tax, i) => (
								<NetcurioMenuItem key={i} value={tax.value}>
									{tax.label}
								</NetcurioMenuItem>
							))}
						</NetcurioSelect>
					</NetcurioFormControl>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('totalDescriptionStatus')}</label>} placement="top">
				<div className={styles.totalField}>
					<NetcurioTextField
						label={t('totalDescriptionStatus')}
						value={Formatter.currency.format(
							typeof itemQuotation?.netPrice === 'string' ? 0 : itemQuotation?.netPrice
						)}
						disabled
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('proposedDeliveryDate')}</label>} placement="top">
				<div className={styles.calendarQuotation}>
					<NetcurioDatePicker
						value={itemDate}
						height="smaller"
						onChange={(date: dayjs.Dayjs) => {
							setItemDate(date)
							handleItemFieldChange(
								{
									target: {
										value: date.toISOString()
									}
								} as ChangeEvent<HTMLInputElement>,
								itemPosition,
								'requiredDate',
								keyRFQ
							)
						}}
						label={t('proposedDeliveryDate')}
						format="DD/MM/YYYY"
						fullWidth
						error={validationItem?.requiredDate}
						minDate={minDate}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('delete')}</label>} placement="top">
				<NetcurioButton
					className={classNames(styles.buttonCancelItemQuotation, styles.backgroundTransparent)}
				>
					<img
						src={itemQuotation.cancelImg}
						alt="cancel"
						className={classNames(styles.cancelIcon)}
						onClick={deleteItemRow}
					/>
				</NetcurioButton>
			</NetcurioTooltip>
		</div>
	)
}
