import LIST_PROPERTIES from './listProperties'
import USER_STATUS from './userStatus'
import ENROLLMENT_STATUS from './enrollmentStatus'
import TICKET_STATUS from './ticketStatus'
import TICKET_REVIEW from './ticketReview'
import ENROLLMENT_ADMIN_STATUS from './enrollmentAdminStatus'
import ACTIONS_USER_STATUS from './actionUserStatus'
import USER_TYPES from './userTypes'
import PRODUCT_STATUS from './productStatus'
import CREDIT_MEMO_STATUS from './creditMemoStatus'
import GRAPH_BARS_COLORS from './creditMemoStatus'
import REQUEST_FOR_CREDIT_MEMO_STATUS from './requestForCreditMemo'
import REGEX from './regex'
import TOOLTIP from './toolTip'
import LISTS from './list'
import REPORTS from './reports'
import MIME_TYPES from './mimeTypes'
import LANGUAGES from './languages'
import USER_SETTINGS from './settingsTabs'
import ADMIN from './settingsTabs'
import DOCUMENT_TYPE from './documentType'
import TEXT_MAIN_SCREENS from './textMainScreens'
import KEY_MAIN_SCREENS from './keyMainScreens'
import RELATION_KEY_URL from './relationKeyUrl'
import INVITATION_STATUS from './invitationStatus'
import UPLOAD_FILES from './files'
import LINKS from './links'
import COLORS from './colors'
import FOLDER from './folder'
import USER from './user'
import SIZE from './size'
import ERROR from './error'
import ORDER from './order'
import SYMBOL from './symbols'
import AUTH from './authentication'
import QUOTATION from './quotation'
import STATUS from './status'
import PurchaseOrderStatus from './status'
import INVOICE from './invoice'
import PAYMENT_COMPLEMENT_STATUS from './paymentComplementStatus'

export default {
	PAYMENT_COMPLEMENT_STATUS,
	TOOLTIP,
	PRODUCT_STATUS,
	CREDIT_MEMO_STATUS,
	REQUEST_FOR_CREDIT_MEMO_STATUS,
	REGEX,
	LIST_PROPERTIES,
	USER_STATUS,
	ENROLLMENT_STATUS,
	TICKET_STATUS,
	TICKET_REVIEW,
	ENROLLMENT_ADMIN_STATUS,
	ACTIONS_USER_STATUS,
	USER_TYPES,
	LINKS,
	COLORS,
	FOLDER,
	USER,
	SIZE,
	ERROR,
	LISTS,
	ORDER,
	SYMBOL,
	AUTH,
	QUOTATION,
	REPORTS,
	MIME_TYPES,
	LANGUAGES,
	USER_SETTINGS,
	ADMIN,
	DOCUMENT_TYPE,
	TEXT_MAIN_SCREENS,
	KEY_MAIN_SCREENS,
	RELATION_KEY_URL,
	GRAPH_BARS_COLORS,
	INVITATION_STATUS,
	UPLOAD_FILES,
	STATUS,
	PurchaseOrderStatus,
	DETAIL: 'DETAIL',
	INVOICE
}
