import download from '@netcurio-ui/assets/src/icons/download-white.svg'
import {
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	PairFilesIcon,
	ProcessFileUpload,
	Severity
} from '@netcurio-ui/components'
import { FileResult } from '@netcurio-ui/components/src'
import { Auth } from 'aws-amplify'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import constants from '../../../../utilities/constants'
import { downloadTxtFile } from '../../../../utilities/file-handling/download-txt-file'
import {
	validateNewFiles,
	validateSizeOfFiles,
	validateTypeOfFile
} from '../../../../utilities/uploadFilesFuctions'
import styles from './UploadProductsModal.module.scss'
import { uploadProductsCatalog } from './utils'

interface UploadProductsModalProps {
	open: boolean
	onClose: () => void
}

type UploadedFileResult = {
	text: string
	fileName: string
	rowsProcessed: number
	totalRows: number
}
export const UploadProductsModal = ({ open, onClose }: UploadProductsModalProps) => {
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [fileResult, setFileResult] = useState<FileResult | null>(null)
	const [canUpload, setCanUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(ProcessFileUpload.SELECT)

	const [uploadedFileResult, setUploadedFileResult] = useState<UploadedFileResult>({
		text: '',
		fileName: '',
		rowsProcessed: 0,
		totalRows: 0
	})

	const { t } = useTranslation()

	function acceptActionModal(action: string) {
		if (action === 'close') {
			sessionStorage.setItem('redirectToTab', 'ProductsList')
			onClose()
		}
	}

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => validateNewFiles(fileList, state))

	const getToken = useCallback(async () => {
		return await Auth.currentAuthenticatedUser()
			.then((user: any) => user.signInUserSession.idToken.jwtToken)
			.catch(console.log)
	}, [])

	const processData = async () => {
		const { SUCCESSFUL, ERROR, UNKNOWN_ERROR } = constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE
		const { UPLOADING_STOPPED } = constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS

		const fileResult: FileResult = { isLoading: true, files: filesToUpload, icon: PairFilesIcon.Stop }

		setFileResult(fileResult)
		setProcess(ProcessFileUpload.UPLOADING)
		const token = await getToken()
		const response = await uploadProductsCatalog(filesToUpload, token)

		if (response[SUCCESSFUL]) {
			fileResult.icon = PairFilesIcon.Success
			setUploadedFileResult(response[SUCCESSFUL])
		} else if (response[ERROR] === '20') {
			fileResult.icon = PairFilesIcon.Stop
			fileResult.errorText = t(UPLOADING_STOPPED)
		} else {
			fileResult.icon = PairFilesIcon.Warning
			fileResult.errorText = t(response[ERROR] || response[UNKNOWN_ERROR])
		}

		fileResult.isLoading = false
		setFileResult({ ...fileResult })
		setProcess(ProcessFileUpload.FINISHED)
	}

	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))
	const handleDownloadLog = () => {
		downloadTxtFile(uploadedFileResult.text, uploadedFileResult.fileName)
	}

	const actionButtons = (
		<div className={styles.actionButtonsForDialog}>
			{process === ProcessFileUpload.SELECT && (
				<>
					<NetcurioButton variant="text" onClick={() => acceptActionModal('close')}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
						{t('processText')}
					</NetcurioButton>
				</>
			)}
			{process === ProcessFileUpload.UPLOADING && (
				<>
					<NetcurioButton variant="contained" disabled>
						{t('processing')}
					</NetcurioButton>
				</>
			)}
			{process === ProcessFileUpload.FINISHED && (
				<>
					<NetcurioButton variant="text" onClick={handleDownloadLog}>
						{t('downloadLogText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={() => acceptActionModal('close')}>
						{t('closeButton')}
					</NetcurioButton>
				</>
			)}
		</div>
	)

	const title = () => {
		if (process === ProcessFileUpload.SELECT) {
			return t('selectFileTitle')
		}

		return t('uploadResultsTitle')
	}

	const downloadTemplateButton = () => {
		if (process === ProcessFileUpload.SELECT) {
			return (
				<a
					className="download-button pebbleGrayText"
					href="https://s3.amazonaws.com/email.netcurio.com/products_catalog_template.xlsx"
					download
				>
					<div className="download-text">{t('massiveFormatText')}</div>
					<img src={download} className="image-download-button" alt="download_catalog_template" />
				</a>
			)
		}
		return ''
	}

	const typeOfFileValidation = (files: Array<File>) => validateTypeOfFile(files, constants.MIME_TYPES.XLSX)
	const validateFilesToUpload = [typeOfFileValidation, validateSizeOfFiles]

	const nextSteps = () => {
		if (
			process === ProcessFileUpload.FINISHED &&
			uploadedFileResult &&
			uploadedFileResult.rowsProcessed === uploadedFileResult.totalRows
		) {
			return {
				severity: Severity.Success,
				message: t('UploadedProductsText', {
					updated: uploadedFileResult.rowsProcessed,
					total: uploadedFileResult.totalRows
				})
			}
		}
		return
	}

	useEffect(() => {
		if (open) {
			setFilesToUpload([])
			setFileResult(null)
			setCanUpload(false)
			setProcess(ProcessFileUpload.SELECT)
		}
	}, [open])

	return (
		<NetcurioDialog
			open={open}
			actionButtons={actionButtons}
			titleText={t('uploadProductCatalog')}
			minWidth="960px"
			maxWidth="960px"
		>
			<FileUpload
				fileUploadDescription={[
					title(),
					t('downloadXLSXText'),
					downloadTemplateButton(),
					t('selectXLSXFileText')
				].filter(Boolean)}
				dragAndDropText={t('dragAndDropProducts')}
				waitUploadFilesText={t('productsBeingProcessed')}
				acceptFileTypes={constants.MIME_TYPES.XLSX}
				acceptMultipleFiles={false}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={fileResult ? [fileResult] : []}
				validateFilesToUpload={validateFilesToUpload}
				validateFilesResult={[nextSteps]}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
			/>
		</NetcurioDialog>
	)
}
