import { URLS } from '@netcurio-ui/common'
import { NetcurioButton, NetcurioIcons, useNetcurioLoader } from '@netcurio-ui/components'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { useClient } from '../../../hooks/useClient'
import { Invoice } from '../../../types'
import { VoidInvoiceModal } from '../InvoiceEdit/Modals'
import { VOID_INVOICE } from '../graphql'

interface VoidInvoiceProps {
	invoice: Invoice
}

export const VoidInvoice = ({ invoice }: VoidInvoiceProps) => {
	const { t } = useTranslation()
	const client = useClient()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [isModalVisible, setIsModalVisible] = useState(false)
	const history: RouterChildContext['router']['history'] = useHistory()

	const handleVoidInvoice = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: VOID_INVOICE,
				variables: {
					uuid: invoice.uuid
				}
			})
			.then(() => {
				hideModal()
				history.replace(URLS.INVOICE_DETAIL + '?invoice=' + invoice.uuid)
			})
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const hideModal = useCallback(() => {
		setIsModalVisible(false)
	}, [])

	return (
		<>
			<NetcurioButton
				variant="text"
				color="secondary"
				size="medium"
				onClick={() => setIsModalVisible(true)}
				endIcon={<NetcurioIcons.CloseOutlined />}
			>
				{t('voidInvoice')}
			</NetcurioButton>
			<VoidInvoiceModal
				open={isModalVisible}
				onClose={hideModal}
				onAccept={() => handleVoidInvoice()}
			/>
		</>
	)
}
