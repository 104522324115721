import { URLS } from '@netcurio-ui/common'

export function getRelationRolesAndStatus() {
	return {
		supplier: {
			PO: {
				NEW: URLS.PO_EDIT,
				REJECTED: URLS.PO_DETAIL,
				CONFIRMED: URLS.PO_DETAIL,
				HALF_CONFIRMED: URLS.PO_DETAIL,
				DELIVERED: URLS.PO_DETAIL,
				HALF_DELIVERED: URLS.PO_DETAIL
			},
			IN: {
				MISSING_LINK: URLS.INVOICE_EDIT,
				ERROR: URLS.INVOICE_EDIT,
				PAID: URLS.INVOICE_DETAIL,
				PAYMENT_SCHEDULED: URLS.INVOICE_DETAIL,
				CANCELED: URLS.INVOICE_DETAIL,
				VOIDED: URLS.INVOICE_DETAIL,
				IN_PROCESS: URLS.INVOICE_DETAIL,
				IN_APPROVAL: URLS.INVOICE_DETAIL
			}
		},
		customer: {
			PO: {
				NEW: URLS.PO_DETAIL,
				REJECTED: URLS.PO_DETAIL,
				CONFIRMED: URLS.PO_DETAIL,
				HALF_CONFIRMED: URLS.PO_DETAIL,
				DELIVERED: URLS.PO_DETAIL,
				HALF_DELIVERED: URLS.PO_DETAIL
			},
			IN: {
				MISSING_LINK: URLS.INVOICE_DETAIL,
				ERROR: URLS.INVOICE_DETAIL,
				PAID: URLS.INVOICE_DETAIL,
				PAYMENT_SCHEDULED: URLS.INVOICE_DETAIL,
				CANCELED: URLS.INVOICE_DETAIL,
				VOIDED: URLS.INVOICE_DETAIL,
				IN_PROCESS: URLS.INVOICE_DETAIL,
				IN_APPROVAL: URLS.INVOICE_DETAIL
			}
		}
	}
}
