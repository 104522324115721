import {
	NetcurioButton,
	NetcurioCheckbox,
	NetcurioDialog,
	NetcurioFormControlLabel,
	NetcurioGrid,
	NetcurioIcons,
	TextArea
} from '@netcurio-ui/components'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { Validations } from '../../../../../types/Validations'
import { ValidationTable } from '../ValidationTable/ValidationTable'
import styles from './ManuallyAuth.module.scss'

interface ManuallyAuthModalProps {
	open: boolean
	onClose(): void
	triggerManualAuthorization(motive: string): void
	previousValidation: Validations
	currentValidation: Validations
	passAllValidations: boolean
	setCancelInvoiceModal(): void
}

/**
 * @description Helps to display a modal when the use tries to manually authorize an Invoice when its status is Error
 * @param open <boolean>: If `true`, the modal will show
 * @param triggerManualAuthorization <function>: when trigger, the modal will authorize the Invoice
 * @param onClose <boolean>: If `false`, the modal will hide
 * @param previousValidation <object>: get the previousValidation
 * @param currentValidation <object>: get the currentValidation
 * @param passAllValidations <boolean>: tells us if the validation passed or not
 * @param setCancelInvoiceModal <Dispatch<SetStateAction<boolean>>> is a function that change value of cancelInvoiceModal
 * @returns component <ReactNode>
 */
export const ManuallyAuthModal = ({
	open,
	onClose,
	triggerManualAuthorization,
	previousValidation,
	currentValidation,
	passAllValidations,
	setCancelInvoiceModal
}: ManuallyAuthModalProps) => {
	const [motiveChange, setMotiveChange] = useState<string>('')
	const [isAuthorizeButtonDisabled, setIsAuthorizeButtonDisabled] = useState<boolean>(true)
	const dataValidations: Validations = passAllValidations ? previousValidation : currentValidation
	const renderActionSatValidation = !!Object.keys(dataValidations).find(
		(key) => key === 'satValidation' && dataValidations[key].value === 'NOT_OK'
	)

	const toggleBtn = () => {
		setIsAuthorizeButtonDisabled(!isAuthorizeButtonDisabled)
	}

	return (
		<NetcurioDialog
			titleText={t('authorizeManualInvoice.header')}
			open={open}
			actionButtons={
				<NetcurioGrid
					width="100%"
					display="flex"
					justifyContent={renderActionSatValidation ? 'space-between' : 'end'}
				>
					{renderActionSatValidation && (
						<NetcurioButton
							variant="outlined"
							color="secondary"
							onClick={setCancelInvoiceModal}
							endIcon={<NetcurioIcons.CloseOutlined />}
						>
							{t('cancelPayment')}
						</NetcurioButton>
					)}
					<div>
						<NetcurioButton
							onClick={() => {
								onClose()
								setMotiveChange('')
							}}
						>
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton
							variant="contained"
							onClick={() => triggerManualAuthorization(motiveChange)}
							disabled={isAuthorizeButtonDisabled || motiveChange === ''}
						>
							{t('authorize')}
						</NetcurioButton>
					</div>
				</NetcurioGrid>
			}
			minWidth={'60rem'}
			maxWidth={'60rem'}
			contentFontSize={'0'}
		>
			<div className={styles.authorizeContainerColumn}>
				<div>
					<p className={styles.labels}>
						<Trans>{t('authorizeManualInvoice.description')}</Trans>
					</p>
				</div>
				<ValidationTable
					previousValidation={previousValidation}
					currentValidation={currentValidation}
					passAllValidations={passAllValidations}
				/>
				<div className={styles.authorizeCheckboxContainer}>
					<NetcurioFormControlLabel
						control={<NetcurioCheckbox />}
						onClick={toggleBtn}
						label={''}
						sx={{ margin: 0 }}
					/>
					<p>{t('manuallyAuthorizedDisclaimer')}</p>
				</div>
				<div className={styles.textAreaPaddingElimination}>
					<TextArea
						rows={3}
						placeholder={t('authorizeManualInvoice.textArea')}
						onChangeText={(response: string) => setMotiveChange(response)}
						readValue={motiveChange}
						maxLength={500}
					/>
					<p className={styles.minimumChar}>{t('max500Char')}</p>
				</div>
			</div>
			{renderActionSatValidation && (
				<p className={styles.warningTextSatValidation}>{t('satValidationTextModal')}</p>
			)}
		</NetcurioDialog>
	)
}
