import React, { FC } from 'react'
import { NetcurioTableCell, NetcurioTableRow } from '@netcurio-ui/components'
import { dateFormatter, getSerieFolio, parseTimestampToTimeZone } from '@netcurio-ui/common'
import styles from '../consumptionDetail.module.scss'
import { Invoice } from '../../../../types'
import Formatter from '../../../../utilities/formatter'

interface InvoiceRowProps {
	invoice?: Invoice
	openInvoiceDetail(uuid: string): void
}

export const InvoiceRow: FC<InvoiceRowProps> = ({ invoice, openInvoiceDetail }) => {
	return (
		<NetcurioTableRow
			isDetailRow
			onClick={() => {
				openInvoiceDetail(invoice.uuid)
			}}
		>
			<NetcurioTableCell align="center">
				<p className={styles.folioPaddingHor}>{getSerieFolio(invoice?.serie, invoice?.folio)}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{invoice?.date ? dateFormatter.format(parseTimestampToTimeZone(invoice?.date)) : '-'}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{Formatter.currency.format(invoice?.total)}</p>
			</NetcurioTableCell>
		</NetcurioTableRow>
	)
}
