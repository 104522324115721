import React, { FC } from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'

interface RemoveAssociatioDocumentProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

export const RemoveAssociation: FC<RemoveAssociatioDocumentProps> = ({ open, onClose, onAccept }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t('removeAssociationTitle')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton onClick={onAccept} variant="contained">
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('removeAssociationMessage')}</p>
		</NetcurioDialog>
	)
}
