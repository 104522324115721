import { Comment, currencyFormatter, Roles } from '@netcurio-ui/common'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	NetcurioButton,
	NetcurioIcons,
	TotalContainerBar
} from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContainerAssociationDocument from '../../components/totalContainerBar/containerAssociationDocument'
import { CreditMemo, RequestForCreditMemoItem } from '../../types'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { useDelayUnmount } from '../../utilities/useDelayUnmount'
import styles from './creditMemoDetail.module.scss'
import { CREATE_CREDIT_MEMO_COMMENT, CREDIT_MEMO_COMMENTS } from './queries'
import { ModalTypes } from './types'

interface LateralColumnProps {
	creditMemoDetail: CreditMemo
	creditMemoId: string
	client: DefaultClient<NormalizedCacheObject>
	sendAssociation: () => void
	isEditionMode: boolean
	disabledSendButton: boolean
	rfcmReference?: string
	userRole: string
	setShowModal(showModal: boolean): void
	setErrorCode(errorCode: string): void
	setModalType(modalType: ModalTypes): void
	onDocumentSelected(rfcmId: string, items: RequestForCreditMemoItem[]): void
	refreshDocumentData(): void
	hasPendingMsg(pendingMsg: boolean): void
}

export const LateralColumn = ({
	creditMemoDetail,
	creditMemoId,
	client,
	setShowModal,
	setErrorCode,
	setModalType,
	sendAssociation,
	isEditionMode,
	onDocumentSelected,
	disabledSendButton,
	rfcmReference,
	refreshDocumentData,
	userRole,
	hasPendingMsg
}: LateralColumnProps): React.ReactElement => {
	const { t } = useTranslation()
	const [isCommentSectionOpen, setIsCommentSectionOpen] = useState<boolean>(false)
	const [creditMemoComments, setCreditMemoComments] = useState<Comment[]>([])
	const [showDiv, hideStyle] = useDelayUnmount(!isCommentSectionOpen, 1000)

	const handleError = useCallback(
		(error: Error) => {
			const errorCode = showErrorComponent(error)
			if (!expiredToken(errorCode)) {
				setShowModal(true)
				setErrorCode(errorCode)
				setModalType(ModalTypes.Error)
			}
		},
		[setShowModal, setErrorCode, setModalType]
	)

	const getComments = () => {
		client
			.query({
				query: CREDIT_MEMO_COMMENTS,
				variables: { uuid: creditMemoId },
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				setCreditMemoComments(result.data.CreditMemo.comments)
			})
			.catch(handleError)
	}

	useEffect(() => {
		getComments()
	}, [])

	const addNewComment = (newCommentData: Comment) => {
		const dataNewComment = {
			creditMemo: creditMemoId,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: CREATE_CREDIT_MEMO_COMMENT,
				variables: dataNewComment
			})
			.then(getComments)
			.catch(handleError)
			.finally(() => hasPendingMsg(false))
	}

	return (
		<div className={styles.creditMemoLateralColumnContainer}>
			{showDiv && (
				<div
					className={classnames(styles.creditMemoLateralColumnContainerTotalDetail, {
						transitionHide: hideStyle
					})}
				>
					<TotalContainerBar
						title={'netTotal'}
						fields={{
							currency: creditMemoDetail?.currency,
							subtotal: currencyFormatter.format(
								creditMemoDetail?.subtotal ? creditMemoDetail?.subtotal : 0
							),
							iva: currencyFormatter.format(creditMemoDetail?.iva ?? 0),
							ieps: currencyFormatter.format(creditMemoDetail?.ieps ?? 0),
							isr: currencyFormatter.format(creditMemoDetail?.isr ?? 0)
						}}
						total={{
							total: currencyFormatter.format(
								creditMemoDetail?.total ? creditMemoDetail?.total : 0
							)
						}}
					/>
				</div>
			)}
			<CommentsSection
				variant={CommentSectionVariant.WithBody}
				comments={creditMemoComments}
				onAddComment={addNewComment}
				onSizeChange={(e) => setIsCommentSectionOpen(e === CommentSectionSize.Extended)}
				onChange={(pendingMsg) => hasPendingMsg(!!pendingMsg)}
			/>
			{!isCommentSectionOpen && (
				<div className={styles.lateralColumnContainerAssociationDocument}>
					<ContainerAssociationDocument
						documentReferenceId={creditMemoDetail?.request_for_credit_memo?.id || rfcmReference}
						customerReference={creditMemoDetail?.request_for_credit_memo?.customer_reference}
						customerRFC={creditMemoDetail?.request_for_credit_memo?.customer.rfc}
						referenceType={Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO}
						showTextDocumentRelated={
							creditMemoDetail?.request_for_credit_memo?.id !== undefined ||
							rfcmReference !== undefined
						}
						titleContainerClass={styles.cadTitleContainerCreditMemo}
						statusCredit={creditMemoDetail?.status?.key}
						invoiceDetail={creditMemoDetail?.invoice}
						associateCreditNote={isEditionMode}
						rfcCustomerInDocument={creditMemoDetail?.receiver?.rfc}
						documentAssociate={onDocumentSelected}
						refreshDocumentData={refreshDocumentData}
					/>
					{isEditionMode ? (
						<div className={styles.buttonsContainer}>
							<NetcurioButton
								disabled={disabledSendButton}
								variant={'outlined'}
								onClick={() => sendAssociation()}
								endIcon={<NetcurioIcons.Check />}
							>
								<span className={styles.textButton}>{t('sendInvoice')}</span>
							</NetcurioButton>
							<NetcurioButton
								variant={'text'}
								color={'secondary'}
								onClick={() => {
									setShowModal(true)
									setModalType(ModalTypes.Void)
								}}
								endIcon={<NetcurioIcons.Close />}
							>
								<span className={styles.textButton}>{t('voidInvoice')}</span>
							</NetcurioButton>
						</div>
					) : (
						creditMemoDetail?.status.key === Constants.STATUS.ACCOUNTED &&
						userRole === Roles.CUSTOMER && (
							<div className={styles.containerButtonsCM}>
								<NetcurioButton
									variant="text"
									color="secondary"
									onClick={() => {
										setShowModal(true)
										setModalType(ModalTypes.Cancel)
									}}
									endIcon={<NetcurioIcons.Close />}
								>
									<span>{t('cancelCMButton')}</span>
								</NetcurioButton>
							</div>
						)
					)}
				</div>
			)}
		</div>
	)
}
