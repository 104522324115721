import { Comment, PURCHASE, Status, URLS, User, currencyFormatter, dateFormatter } from '@netcurio-ui/common'
import { useNetcurioLoader } from '@netcurio-ui/components'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	Header,
	NetcurioAlert,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	Severity,
	StatusBadges,
	TotalContainerBar
} from '@netcurio-ui/components/'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory, useLocation } from 'react-router-dom'
import { AttachDocumentsModal } from '../../../components/AttachDocumentsModal/AttachDocumentsModal'
import { AttachedDocumentsModal } from '../../../components/AttachedDocumentsModal/AttachedDocumentsModal'
import {
	Consumption,
	ConsumptionItem,
	ErrorWrapper,
	Invoice,
	InvoiceItem,
	PurchaseOrder,
	PurchaseOrderItem
} from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import documentType from '../../../utilities/constants/documentType'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { redirectByStatus } from '../../../utilities/validateUrlRoles'
import { AutoAssociationState } from '../InvoiceActions/AutoAssociateInvoice'
import { DetailField } from '../InvoiceDetail/DetailField/DetailField'
import { InvoiceInfoBar } from '../InvoiceDetail/InvoiceInfoBar/InvoiceInfoBar'
import { CREATE_INVOICE_COMMENT, INVOICES_EDIT, INVOICE_COMMENTS, UPDATED_INVOICES_DATA } from '../graphql'
import { TypesOfStatusMessages, invoiceEditAlertMessages } from '../helpers/visualMessageInvoiceEdit'
import { getVisualStatusInvoiceEdit } from '../helpers/visualStatusHelperInvoiceEdit'
import { AssociateDocument } from './AssociateDocument/AssociateDocument'
import { EditInvoicesButtonsSection } from './ButtonsSection/ButtonsSection'
import styles from './InvoiceEdit.module.scss'
import { InvoiceItemListEdit } from './InvoiceItemListEdit/InvoiceItemListEdit'
import { CancelAssociationModal, ErrorModal, RejectPendingMessageInvoiceEdit } from './Modals'
import { ItemsToUpdate } from './types'
import { defaultValuesInvoice } from './utilities/defaultValues'

export const InvoiceEdit = (): ReactElement => {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const history: RouterChildContext['router']['history'] = useHistory()
	const location = useLocation<{ search: string }>()
	const queryParams: URLSearchParams = new URLSearchParams(location.search)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [invoice, setInvoice] = useState<Invoice>(defaultValuesInvoice)
	const [invoiceItems, setInvoiceItems] = useState<Array<InvoiceItem>>([])
	const [invoiceHasChanges, setInvoiceHasChanges] = useState<boolean>(false)
	const [statusMessage, setStatusMessage] = useState<TypesOfStatusMessages>(TypesOfStatusMessages.Default)
	const [comments, setComments] = useState<Comment[]>([])
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [paymentDate, setPaymentDate] = useState<string>('NA')
	const [bankReference, setBankReference] = useState<string>('NA')
	const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
	const [documentSelected, setDocumentSelected] = useState<string>('')
	const [relatedDocumentType, setRelatedDocumentType] = useState<string>('')
	const [suggestionsItemsToAssociate, setSuggestionsItemsToAssociate] = useState<
		Array<PurchaseOrderItem | ConsumptionItem>
	>([])
	const [disableAssociateProducts, setDisableAssociateProducts] = useState<boolean>(true)
	const [isCancelAssociationOpen, setIsCancelAssociationOpen] = useState<boolean>(false)
	const [existsPendingMessage, setExistsPendingMessage] = useState<boolean>(false)
	const [openRejectPendingMsgModal, setOpenRejectPendingMsgModal] = useState<boolean>(false)
	const [openAttachedDocumentsModal, setOpenAttachedDocumentsModal] = useState<boolean>(false)
	const [openAttachDocumentsModal, setOpenAttachDocumentsModal] = useState<boolean>(false)
	const disableAttachButton = [Constants.STATUS.CANCELED, Constants.ERROR.ERROR, PURCHASE.VOIDED].includes(
		(invoice.status as Status)?.key
	)
	const [showAssociationSection, setShowAssociationSection] = useState<boolean>(true)
	const [currentAssociation, setCurrentAssociation] = useState<PurchaseOrder | Consumption>(null)
	const [autoAssociationState, setAutoAssociationState] = useState<AutoAssociationState>(
		AutoAssociationState.Idle
	)

	useEffect(() => {
		setShowAssociationSection(relatedDocumentType !== documentType.STANDALONE && !invoice.advance)
		if (relatedDocumentType === documentType.STANDALONE) {
			setStatusMessage(TypesOfStatusMessages.SendAssociation)
		}
		if (
			relatedDocumentType === documentType.PURCHASE_ORDER ||
			relatedDocumentType === documentType.CONSUMPTION
		) {
			setStatusMessage(TypesOfStatusMessages.PendingAssociate)
		}
	}, [relatedDocumentType, invoice])

	useEffect(() => {
		getInvoiceInfo()
	}, [])

	useEffect(() => {
		const statusText: string = (invoice?.status as Status)?.key ?? ''
		const validStatus: Array<string> = [
			PURCHASE.PAYMENT_SCHEDULED,
			Constants.LINKS.MISSING_LINK,
			Constants.ERROR.ERROR
		]
		if (validStatus.includes(statusText)) {
			setPaymentDate(t(PURCHASE.TEXT_PENDING))
			setBankReference(t(PURCHASE.TEXT_PENDING))
		} else {
			setPaymentDate(invoice?.payment_date ? dateFormatter.format(invoice?.payment_date) : 'NA')
			setBankReference(invoice?.bank_reference ?? 'NA')
		}
	}, [invoice])

	const getInvoiceInfo = () => {
		showLoadingSpinner()
		const uuid: string = getUrlParameter('invoice')
		client
			.query({
				query: INVOICES_EDIT,
				variables: {
					uuid
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
				const paramsToUrl = `?invoice=${uuid}`
				redirectByStatus(Constants.LISTS.IN, (result.data.Invoice.status as Status)?.key, paramsToUrl)
				if (result.data.Invoice) {
					setInvoice(result.data.Invoice)
					setComments(result.data.Invoice?.comments)
					setInvoiceItems(result.data.Invoice?.items)
					setRelatedDocumentType(
						result.data.Invoice?.reference_type || Constants.DOCUMENT_TYPE.PURCHASE_ORDER
					)

					if (
						(result.data.Invoice?.status as Status).key === Constants.LINKS.MISSING_LINK &&
						!result.data.Invoice?.reference
					) {
						setStatusMessage(TypesOfStatusMessages.PendingAssociate)
					}

					if (
						(result.data.Invoice?.status as Status).key === Constants.ERROR.ERROR &&
						result.data.Invoice?.sat_validation_message
					) {
						setStatusMessage(TypesOfStatusMessages.SatValidation)
					}
				} else {
					const code = showErrorComponent(undefined)
					setErrorMessage({ code })
				}
			})
			.catch(errorHandler)
			.finally(() => hideLoadingSpinner())
	}

	const errorHandler = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorMessage({
				code: errorCode,
				message: error.message
			})
		}
		hideLoadingSpinner()
	}

	const cancelButtonAction = () => {
		if (invoiceHasChanges) {
			setIsCancelAssociationOpen(true)
		} else if (existsPendingMessage) {
			setOpenRejectPendingMsgModal(true)
		} else {
			history.push(URLS.INVOICE_LIST)
		}
	}

	const addNewComment = (newCommentData: Comment): void => {
		client
			.mutate({
				mutation: CREATE_INVOICE_COMMENT,
				variables: {
					invoice: invoice.uuid,
					text: newCommentData.text
				}
			})
			.then(() => {
				client
					.query({
						query: INVOICE_COMMENTS,
						variables: {
							uuid: invoice.uuid
						},
						fetchPolicy: 'no-cache'
					})
					.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
						setComments(result.data.Invoice.comments)
					})
			})
			.catch(errorHandler)
			.finally(() => setExistsPendingMessage(false))
	}

	const handleCommentsSectionSizeChange = (commentsSectionSize: CommentSectionSize) => {
		setIsCommentsSectionExtended(commentsSectionSize === CommentSectionSize.Extended)
	}

	const modifiedBy = (): User => {
		const userType = 'modified_by_supplier'
		const userName: string = invoice
			? invoice[userType]
				? (invoice[userType] as User)?.name + ' ' + (invoice[userType] as User)?.lastname
				: PURCHASE.TEXT_PENDING
			: ''
		const userEmail = invoice ? (invoice[userType] ? (invoice[userType] as User)?.email : '') : ''

		return { name: userName, email: userEmail }
	}

	const documentAssociate = (document?: string, items?: Array<PurchaseOrderItem | ConsumptionItem>) => {
		if (document) {
			setStatusMessage(
				!invoiceItems.some((item: InvoiceItem) => !item.reference_position)
					? TypesOfStatusMessages.Default
					: TypesOfStatusMessages.EndingProduct
			)
			setDisableAssociateProducts(!(items && items.length > 0))
			setSuggestionsItemsToAssociate(items ?? [])
			setDocumentSelected(document)
			setInvoiceHasChanges(true)
		} else {
			const tempItems: Array<InvoiceItem> = invoiceItems.map((item: InvoiceItem) => {
				if (item?.reference_position) {
					item.reference_position = undefined
				}
				return item
			})
			setInvoiceItems(tempItems)
			setDisableAssociateProducts(true)
			setSuggestionsItemsToAssociate([])
			setDocumentSelected('')
			setIsSubmitDisabled(true)
			setInvoiceHasChanges(false)
		}
	}

	const documentTypeSelected = (relatedDocumentType: string) => {
		if (relatedDocumentType !== invoice.reference_type) {
			const tempInvoice: Invoice = structuredClone(invoice)
			tempInvoice.reference_type = relatedDocumentType
			setInvoice(tempInvoice)
			setRelatedDocumentType(relatedDocumentType)
		}
	}

	const checkIfKeyExists = (key: string) => t(key) !== key

	const updateInvoiceAssociationData = () => {
		showLoadingSpinner()
		client
			.query({
				query: UPDATED_INVOICES_DATA,
				variables: {
					uuid: queryParams.get('invoice')
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
				const updatedInvoice: Invoice = Object.assign({}, invoice, result.data.Invoice)
				setInvoice(updatedInvoice)
				const newItems: Array<InvoiceItem> = invoiceItems.map((item: InvoiceItem) => {
					item.error = false
					return item
				})
				setInvoiceItems(newItems)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const updateAssociatedItemPendingQuantity = (itemsToUpdate: Array<ItemsToUpdate>) => {
		if (itemsToUpdate.length > 0) {
			const updatedItems: Array<PurchaseOrderItem | ConsumptionItem> = suggestionsItemsToAssociate.map(
				(suggestion: PurchaseOrderItem | ConsumptionItem) => {
					itemsToUpdate.forEach((item: ItemsToUpdate) => {
						if (item.position === suggestion.position) {
							suggestion.pending_quantity_to_invoice = item.newQuantity
						}
					})
					return suggestion
				}
			)
			setSuggestionsItemsToAssociate(updatedItems)
		}
	}

	const associateProducts = (indexSelect: number, item: number) => {
		if (autoAssociationState === AutoAssociationState.Executing) {
			return
		}
		const tempItemInvoice: Array<InvoiceItem> = structuredClone(invoiceItems)
		if (tempItemInvoice[indexSelect]) {
			tempItemInvoice[indexSelect].reference_position = item
		}
		const completeItems = !tempItemInvoice.some((item: InvoiceItem) => !item.reference_position)
		setInvoiceItems(tempItemInvoice)
		setIsSubmitDisabled(!completeItems)
		setStatusMessage(completeItems ? TypesOfStatusMessages.Default : TypesOfStatusMessages.EndingProduct)
	}

	const openAttachedDocumentModal = (): void => {
		setOpenAttachedDocumentsModal(true)
	}

	const openAttachDocumentModal = (): void => {
		setOpenAttachDocumentsModal(true)
	}

	const handleAutoAssociateInvoice = (invoiceItems: InvoiceItem[], hasExceedItems: boolean) => {
		const allItemsAreAssociated = invoiceItems.every((item) => item.reference_position)
		setIsSubmitDisabled(!allItemsAreAssociated)
		setInvoiceItems(invoiceItems)

		if (allItemsAreAssociated) {
			setStatusMessage(TypesOfStatusMessages.PositionsMatch)
		} else {
			if (hasExceedItems) {
				setStatusMessage(TypesOfStatusMessages.QuantityExceeded)
			} else {
				setStatusMessage(TypesOfStatusMessages.SomePositionsDidMatch)
			}
		}
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
			<NetcurioGrid item alignItems="center" xs={12}>
				<Header>
					<div>
						<NetcurioButton
							variant="outlined"
							color="error"
							className={styles.btnHeader}
							onClick={cancelButtonAction}
							endIcon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
						>
							<span> {t('cancelText')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={openAttachDocumentModal}
							endIcon={
								<NetcurioIcons.AttachFile
									className={classNames(styles.icon, styles.iconPDF)}
								/>
							}
							disabled={disableAttachButton}
						>
							<span> {t('attachDocumentsTitle')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={openAttachedDocumentModal}
							endIcon={
								<NetcurioIcons.Folder className={classNames(styles.icon, styles.iconPDF)} />
							}
							disabled={!invoice.folder?.files.length}
						>
							<span> {t('viewAttachments')} </span>
						</NetcurioButton>
					</div>
					<div></div>
				</Header>
			</NetcurioGrid>
			<NetcurioGrid container alignItems="flex-start">
				<NetcurioGrid
					item
					xs={8}
					sm={8}
					md={9}
					lg={9}
					xl={10}
					justifyContent="flex-start"
					className={classNames(styles.mainSection)}
				>
					<NetcurioGrid container>
						<NetcurioGrid item xs={12}>
							<InvoiceInfoBar invoice={invoice} mode="edit" />
						</NetcurioGrid>
						<NetcurioGrid container className={classNames(styles.invoiceInformationSection)}>
							<NetcurioGrid container item xs={12} className={classNames(styles.statusSection)}>
								<div>
									<StatusBadges
										statusArray={getVisualStatusInvoiceEdit(
											(invoice?.status as Status)?.key
										)}
									/>
								</div>
								<div className={styles.msgContainer}>
									{(invoice?.status as Status).key === Constants.ERROR.ERROR &&
									invoice?.sat_validation_message ? (
										<NetcurioAlert severity={Severity.Error}>
											{t(invoice?.sat_validation_message)}
										</NetcurioAlert>
									) : (
										(invoiceEditAlertMessages[statusMessage]?.message ||
											(invoice?.status as Status)?.key === Constants.ERROR.ERROR) && (
											<NetcurioAlert
												severity={
													(invoice?.status as Status)?.key === Constants.ERROR.ERROR
														? Severity.Warning
														: invoiceEditAlertMessages[statusMessage].variant
												}
											>
												{(invoice?.status as Status)?.key === Constants.ERROR.ERROR
													? checkIfKeyExists(
															`graphQLErrors.${(invoice.error as Status)?.value}`
														)
														? t(
																`graphQLErrors.${(invoice.error as Status)?.value}`
															)
														: (invoice.error as Status)?.value
													: t(invoiceEditAlertMessages[statusMessage].message)}
											</NetcurioAlert>
										)
									)}
								</div>
							</NetcurioGrid>
							<NetcurioGrid container item xs={12} className={styles.invoiceEditContainer}>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('dateInvoicesHeader')}
										information={
											invoice?.date ? dateFormatter.format(new Date(invoice.date)) : ''
										}
									/>
									<DetailField
										title={t('uploadedAt')}
										information={
											invoice?.created_at
												? dateFormatter.format(new Date(invoice.created_at))
												: ''
										}
									/>
									<DetailField
										title={t('rfcSender')}
										information={invoice?.sender?.rfc}
										tooltip={invoice?.sender?.name}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('receiver')}
										information={invoice?.receiver?.name}
										tooltip={invoice?.receiver?.name}
									/>
									<DetailField
										title={t('rfcReceiver')}
										information={invoice?.receiver?.rfc}
									/>
									<DetailField
										title={t('UUID')}
										information={invoice?.uuid}
										tooltip={invoice?.uuid}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('proposedPaymentDate')}
										information={
											invoice?.proposed_payment_date
												? dateFormatter.format(
														new Date(invoice?.proposed_payment_date)
													)
												: t(PURCHASE.TEXT_PENDING)
										}
									/>
									<DetailField
										title={t('uploadedBy')}
										information={
											invoice?.uploaded_by &&
											invoice?.uploaded_by?.name + ' ' + invoice?.uploaded_by?.lastname
										}
										tooltip={invoice?.uploaded_by?.email}
									/>
									<DetailField
										title={t('editedBy')}
										information={t(modifiedBy().name)}
										tooltip={t(modifiedBy().email)}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField title={t('paymentDate')} information={paymentDate} />
									<DetailField title={t('bankReference')} information={bankReference} />
									<DetailField
										title={t('dateLastModification')}
										information={
											invoice?.updated_at
												? dateFormatter.format(new Date(invoice.updated_at))
												: ''
										}
										tooltip={t('dateLastModification')}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid
							container
							justifyContent={'flex-start'}
							alignItems={'center'}
							alignContent={'start'}
							className={styles.listContainer}
							marginTop="1rem"
						>
							<InvoiceItemListEdit
								updateAssociatedItemPendingQuantity={updateAssociatedItemPendingQuantity}
								informationTable={invoiceItems}
								disableAssociateProducts={disableAssociateProducts}
								suggestionsItemsToAssociate={suggestionsItemsToAssociate}
								associateProducts={associateProducts}
								documentSelected={documentSelected}
								showAssociationSection={showAssociationSection}
							/>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid container item xs={4} sm={4} md={3} lg={3} xl={2} spacing={1}>
					{!isCommentsSectionExtended && (
						<NetcurioGrid item xs={12}>
							<TotalContainerBar
								title={'total'}
								fields={{
									currency: invoice?.currency,
									subtotalText: currencyFormatter.format(invoice?.subtotal ?? 0),
									discount: currencyFormatter.format(invoice?.discount ?? 0),
									ivaText: currencyFormatter.format(invoice?.iva ?? 0),
									ieps: currencyFormatter.format(invoice?.ieps ?? 0),
									isr: currencyFormatter.format(invoice?.isr ?? 0),
									ivaRet: currencyFormatter.format(invoice?.iva_ret ?? 0)
								}}
								total={{ totalDotText: currencyFormatter.format(invoice?.total ?? 0) }}
							/>
						</NetcurioGrid>
					)}
					<NetcurioGrid
						item
						xs={12}
						className={classNames(styles.commentSection, {
							[styles.grownSection]: isCommentsSectionExtended
						})}
					>
						<CommentsSection
							comments={comments}
							variant={CommentSectionVariant.WithBody}
							onSizeChange={(e: CommentSectionSize) => handleCommentsSectionSizeChange(e)}
							onAddComment={(e: Comment) => addNewComment(e)}
							onChange={(pendingMSg) => setExistsPendingMessage(!!pendingMSg)}
						/>
					</NetcurioGrid>
					{!isCommentsSectionExtended && (
						<NetcurioGrid item xs={12}>
							<AssociateDocument
								invoice={invoice}
								rfcCustomerInDocument={invoice?.receiver?.rfc}
								documentAssociate={documentAssociate}
								documentSelected={documentSelected}
								documentReferenceId={
									invoice?.reference_type ===
									Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER
										? (invoice?.purchase_order as PurchaseOrder)?.id
										: invoice?.reference
								}
								updateDocumentTypeSelected={documentTypeSelected}
								updateInvoiceAssociationData={updateInvoiceAssociationData}
								referenceType={relatedDocumentType}
								customerReferencePORelated={
									(invoice?.purchase_order as PurchaseOrder)?.customer_reference
								}
								customerReferenceConsumptionRelated={
									(invoice?.consumption as Consumption)?.customer_reference
								}
								onChangeAssociation={(document: PurchaseOrder | Consumption) => {
									setCurrentAssociation(document)
									setAutoAssociationState(AutoAssociationState.Idle)
									if (
										relatedDocumentType === Constants.DOCUMENT_TYPE.PURCHASE_ORDER &&
										invoice.advance
									) {
										setIsSubmitDisabled(false)
									}
								}}
							/>
							<EditInvoicesButtonsSection
								invoice={invoice}
								errorHandler={errorHandler}
								isSendDisabled={isSubmitDisabled}
								relatedDocumentType={relatedDocumentType}
								currentAssociation={currentAssociation}
								documentSelected={documentSelected}
								invoiceItems={invoiceItems}
								getInvoiceInfo={getInvoiceInfo}
								suggestionsItemsToAssociate={suggestionsItemsToAssociate}
								onFinishedAutoAssociation={handleAutoAssociateInvoice}
							/>
						</NetcurioGrid>
					)}
				</NetcurioGrid>
			</NetcurioGrid>

			<CancelAssociationModal
				open={isCancelAssociationOpen}
				onClose={() => setIsCancelAssociationOpen(false)}
				onAccept={() => history.push(URLS.INVOICE_LIST)}
			/>

			<RejectPendingMessageInvoiceEdit
				open={openRejectPendingMsgModal}
				close={() => {
					setOpenRejectPendingMsgModal(false)
				}}
				onAccept={() => history.push(URLS.INVOICE_LIST)}
			/>
			<ErrorModal
				open={!!errorMessage?.code}
				callClose={() => {
					setErrorMessage(undefined)
				}}
				redirect={false}
				errorCode={errorMessage?.code ? errorMessage?.code : ''}
			/>
			<AttachedDocumentsModal
				open={openAttachedDocumentsModal}
				attachedDocuments={invoice.folder?.files}
				onClose={() => setOpenAttachedDocumentsModal(false)}
				folderUUID={invoice?.folder_uuid}
				getInvoiceInfo={getInvoiceInfo}
			/>
			<AttachDocumentsModal
				open={openAttachDocumentsModal}
				close={() => setOpenAttachDocumentsModal(false)}
				uuid={invoice?.uuid}
				getInvoiceInfo={getInvoiceInfo}
			/>
		</NetcurioGrid>
	)
}
