import { NetcurioCollapse, NetcurioIcons, NetcurioTableCell, NetcurioTableRow } from '@netcurio-ui/components'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { InvoiceValidationEnum } from '../../../../../types'
import { ValidationItem } from '../../../../../types/Validations'
import styles from './ValidationTable.module.scss'

export interface ValidationRowProps {
	validationKey: string
	previousValidation: ValidationItem
	currentValidation: ValidationItem
	passAllValidations: boolean
	customLabel?: string
}

export const ValidationRow = ({
	validationKey,
	previousValidation,
	currentValidation,
	passAllValidations,
	customLabel
}: ValidationRowProps) => {
	const [openCollapsible, setOpenCollapsible] = useState(false)
	const [icon, setIcon] = useState<InvoiceValidationEnum>()
	const headerValidation = ['accountingPeriodValidation', 'amountValidation'].includes(validationKey)
	const hideCollapsible: boolean = passAllValidations
		? previousValidation?.positions?.length === 0 || previousValidation?.positions === null
		: currentValidation?.positions?.length === 0 || currentValidation?.positions === null
	const renderCollapsible: boolean =
		!headerValidation &&
		(passAllValidations
			? previousValidation.value === InvoiceValidationEnum.NOT_OK
			: currentValidation.value === InvoiceValidationEnum.NOT_OK)
	const getPositionsLabel: string = passAllValidations
		? previousValidation?.message ?? previousValidation.positions?.join(' - ')
		: currentValidation?.message ?? currentValidation?.positions?.join(' - ')

	useEffect(() => {
		const selectedIcon = passAllValidations ? previousValidation.value : currentValidation.value
		setIcon(selectedIcon)
		return () => {
			setIcon(InvoiceValidationEnum.OK)
		}
	}, [previousValidation, currentValidation, passAllValidations])

	const iconMap = {
		[InvoiceValidationEnum.NOT_VALIDATED]: (
			<NetcurioIcons.Error className={classNames(styles.btnStatusError)} />
		),
		[InvoiceValidationEnum.NOT_OK]: (
			<div className={styles.iconPosition}>
				<NetcurioIcons.CancelRounded className={classNames(styles.btnStatusWarning)} />
			</div>
		),
		[InvoiceValidationEnum.OK]: (
			<NetcurioIcons.CheckCircleRounded className={classNames(styles.btnStatusValid)} />
		)
	}

	return (
		<>
			<NetcurioTableRow hasCollapsible={renderCollapsible && !hideCollapsible}>
				<NetcurioTableCell>
					<p>{customLabel ? t(customLabel) : t(validationKey)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<div className={classNames(styles.alignTableItems)}>
						{iconMap[icon]}

						{renderCollapsible && !hideCollapsible && (
							<div
								className={styles.arrowIconPosition}
								onClick={() => {
									setOpenCollapsible(!openCollapsible)
								}}
							>
								{openCollapsible ? (
									<NetcurioIcons.ArrowDropUp />
								) : (
									<NetcurioIcons.ArrowDropDown />
								)}
							</div>
						)}
					</div>
				</NetcurioTableCell>
				{passAllValidations && (
					<NetcurioTableCell align="center">{iconMap[InvoiceValidationEnum.OK]}</NetcurioTableCell>
				)}
			</NetcurioTableRow>
			{renderCollapsible && !hideCollapsible && (
				<NetcurioTableRow>
					<NetcurioTableCell
						colSpan={passAllValidations ? 3 : 2}
						sx={{ paddingBottom: 0, paddingTop: 0 }}
					>
						<NetcurioCollapse in={openCollapsible} timeout="auto" unmountOnExit>
							<p className={styles.errorLabel}>
								{validationKey === 'satValidation'
									? getPositionsLabel
									: t('positionsWithError', { error: getPositionsLabel })}
							</p>
						</NetcurioCollapse>
					</NetcurioTableCell>
				</NetcurioTableRow>
			)}
		</>
	)
}
