import { DocumentNode } from 'graphql'

import gql from 'graphql-tag'

export const CREATE_PURCHASE_ORDER: DocumentNode = gql`
	mutation createPurchaseOrder(
		$branch_office: String
		$branch_office_id: String
		$supplier: String!
		$total: Float
		$delivery_address_line_1: String!
		$delivery_address_line_2: String
		$delivery_address_country_code: String!
		$delivery_address_country: String
		$delivery_address_state: String
		$delivery_address_state_code: String!
		$delivery_address_postal_code: String!
		$currency: String
		$items: [PurchaseOrderItemInput!]!
		$price_change_reason: String
		$quotation: ID
		$type: String
		$customer_reference: String
		$user_reference: String
		$comment: CommentInput
		$attachment: String
	) {
		createPurchaseOrder(
			branch_office: $branch_office
			branch_office_id: $branch_office_id
			supplier: $supplier
			total: $total
			delivery_address_line_1: $delivery_address_line_1
			delivery_address_line_2: $delivery_address_line_2
			delivery_address_country_code: $delivery_address_country_code
			delivery_address_country: $delivery_address_country
			delivery_address_state: $delivery_address_state
			delivery_address_state_code: $delivery_address_state_code
			delivery_address_postal_code: $delivery_address_postal_code
			currency: $currency
			items: $items
			price_change_reason: $price_change_reason
			quotation: $quotation
			type: $type
			customer_reference: $customer_reference
			user_reference: $user_reference
			comment: $comment
			attachment: $attachment
		) {
			id
			message
		}
	}
`
