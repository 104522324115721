import React, { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { QuotationRow } from './QuotationRow'
import { QuotationData } from '../types'
import rfq from '@netcurio-ui/assets/src/icons/RFQ.svg'
import styles from './Quotation.module.scss'

interface QuotationsListProps {
	informationListQuotations: Array<QuotationData>
	showButtons(showList: boolean): void
	showQuotationDetailComponent(id: string, supplierRFC: string): void
}

export const QuotationsList: FC<QuotationsListProps> = ({
	informationListQuotations,
	showButtons,
	showQuotationDetailComponent
}) => {
	const [showList, setShowList] = useState<boolean>(false)
	const [height, setHeight] = useState<number>(0)
	const contentRef = useRef<HTMLDivElement>(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (contentRef?.current) {
			setHeight(showList ? contentRef.current.offsetHeight : 0)
		}
	}, [showList])

	const showListQuotation = () => {
		if (informationListQuotations.length) {
			showButtons(showList)
			setShowList(!showList)
		}
	}
	return (
		<div ref={contentRef} className={styles.quotationsListContainer}>
			<button
				className={classNames(
					informationListQuotations.length
						? styles.buttonQuotationListActive
						: styles.buttonQuotationListInactive,
					styles.buttonQuotationList,
					showList ? styles.buttonQuotationListSelected : styles.buttonQuotationListBorder
				)}
				onClick={showListQuotation}
			>
				<img src={rfq} className={styles.imageHeaderQuotationList} alt="RFQ" />
				<p className={styles.titleQuotationList}>{t('quotationsText')}</p>
			</button>
			{
				<div
					className={classNames(styles.grayColorTextGeneralInfo, styles.quotationListContent)}
					style={{
						height
					}}
				>
					<div className={styles.quotationListTitle}>
						<span>{t('quotationsRelatedText')}</span>
					</div>
					<div className={styles.quotationHeaderList}>
						<div className={styles.quotationColumn}>
							<span>{t('billText')}</span>
						</div>
						<div className={styles.quotationColumn}>
							<span>{t('totalDescriptionStatus')}</span>
						</div>
						<div className={styles.quotationColumn}>
							<span>{t('expirationText')}</span>
						</div>
					</div>
					<div>
						{informationListQuotations &&
							informationListQuotations.map((item, key) => (
								<QuotationRow
									key={key}
									dataQuotation={item}
									showQuotationDetailComponent={showQuotationDetailComponent}
								/>
							))}
					</div>
				</div>
			}
		</div>
	)
}
