import React, { useEffect, useMemo, useState } from 'react'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { CommentSectionSize, CommentSectionVariant, CommentsSection } from '@netcurio-ui/components'
import { Comment } from '@netcurio-ui/common'
import { connection } from '../../../../../utilities/connection'
import * as queries from './queries'
import AdminsSection, { AdminsSectionSize } from './adminsSection/adminsSection'
import ButtonAcceptReject from './showButtons'
import styles from './enrollmentDetail.module.scss'

interface Admin {
	status: string
	fullName: string
	email: string
}

interface ILateralSectionProps {
	id: string
	supplier: string
	supplierComment: string
	paymentDays: string
	adminsInformation: Admin[]
	handleError(error: Error): void
	showButtons(): boolean
	setShowApproveModal(value: boolean): void
	setShowRejectModal(value: boolean): void
	setHideButtons(value: boolean): void
}

interface SizeComments {
	currentSize: CommentSectionSize
	previousSize: CommentSectionSize
}
interface SizeAdmins {
	currentSize: AdminsSectionSize
	previousSize: AdminsSectionSize
}

const LateralSection = ({
	id,
	supplier,
	supplierComment,
	handleError,
	showButtons,
	paymentDays,
	setShowApproveModal,
	setShowRejectModal,
	adminsInformation,
	setHideButtons
}: ILateralSectionProps): JSX.Element => {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [informationComments, setInformationComments] = useState<Comment[]>([])
	const [sizeComments, setSizeComments] = useState<SizeComments>({
		currentSize: CommentSectionSize.Intermediate,
		previousSize: CommentSectionSize.Intermediate
	})
	const [sizeAdmins, setSizeAdmins] = useState<SizeAdmins>({
		currentSize: AdminsSectionSize.Intermediate,
		previousSize: AdminsSectionSize.Intermediate
	})
	let isMounted = true

	useEffect(() => {
		getComments()
		return () => {
			isMounted = false
		}
	}, [])

	const getComments = () => {
		client
			.query({
				query: queries.INVITATION_ENROLLMENT_COMMENTS,
				variables: {
					id,
					supplier: supplierComment
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				if (isMounted) {
					setInformationComments(result.data.EnrollmentInvitation.comments)
				}
			})
			.catch(handleError)
	}

	const addNewComment = (newCommentData: { text: string }) => {
		const dataNewComment = {
			invitation: id,
			supplier: supplier,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: queries.CREATE_ENROLLMENT_INVITATION_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				client
					.query({
						query: queries.INVITATION_ENROLLMENT_COMMENTS,
						variables: {
							id,
							supplier: supplier
						},
						fetchPolicy: 'no-cache'
					})
					.then((result) => {
						setInformationComments(result.data.EnrollmentInvitation.comments)
					})
					.catch(handleError)
			})
			.catch(handleError)
	}

	const pendingMessageHandler = (pending: boolean) => {
		// TODO add flag if leaving page
		//if (pendingComment !== pending) setPendingComment(pending)
	}

	useEffect(() => {
		if (sizeComments.currentSize === CommentSectionSize.Extended) {
			setSizeAdmins((state) => ({
				currentSize: AdminsSectionSize.Minimized,
				previousSize: state.currentSize
			}))
		} else if (sizeComments.previousSize === CommentSectionSize.Extended) {
			setSizeAdmins((state) => ({
				currentSize: AdminsSectionSize.Intermediate,
				previousSize: state.currentSize
			}))
		} else if (sizeComments.currentSize === CommentSectionSize.Intermediate) {
			setSizeAdmins((state) =>
				state.currentSize === AdminsSectionSize.Extended
					? {
							currentSize: AdminsSectionSize.Intermediate,
							previousSize: state.currentSize
					  }
					: state
			)
		}
	}, [sizeComments])

	useEffect(() => {
		if (sizeAdmins.currentSize === AdminsSectionSize.Extended) {
			setSizeComments((state) => ({
				currentSize: CommentSectionSize.Minimized,
				previousSize: state.currentSize
			}))
		} else if (sizeAdmins.previousSize === AdminsSectionSize.Extended) {
			setSizeComments((state) => ({
				currentSize: CommentSectionSize.Intermediate,
				previousSize: state.currentSize
			}))
		} else if (sizeAdmins.currentSize === AdminsSectionSize.Intermediate) {
			setSizeComments((state) =>
				state.currentSize === CommentSectionSize.Extended
					? {
							currentSize: CommentSectionSize.Intermediate,
							previousSize: state.currentSize
					  }
					: state
			)
		}
	}, [sizeAdmins])

	function handleCommentsSizeChange(commentsSectionSize: CommentSectionSize) {
		setHideButtons(commentsSectionSize === CommentSectionSize.Extended)
		setSizeComments((state) => ({ currentSize: commentsSectionSize, previousSize: state.currentSize }))
	}

	function handleAdminsSizeChange(adminsSectionSize: AdminsSectionSize) {
		setHideButtons(adminsSectionSize === AdminsSectionSize.Extended)
		setSizeAdmins((state) => ({ currentSize: adminsSectionSize, previousSize: state.currentSize }))
	}

	return (
		<div className={styles.lateralColumnContainer}>
			<CommentsSection
				variant={CommentSectionVariant.WithBody}
				comments={informationComments}
				onAddComment={addNewComment}
				onSizeChange={handleCommentsSizeChange}
				controlledSize={sizeComments}
			/>
			<AdminsSection
				adminsInformation={adminsInformation}
				paddingTop={250}
				adminSectionOpen={handleAdminsSizeChange}
				controlledSize={sizeAdmins}
			/>
			{showButtons() && (
				<ButtonAcceptReject
					paymentDays={paymentDays}
					approveModal={setShowApproveModal}
					showRejectModal={setShowRejectModal}
				/>
			)}
		</div>
	)
}

export default LateralSection
