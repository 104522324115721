import { Company, IBranchOffices } from '@netcurio-ui/common'

export const formatAddress = (company: Company | IBranchOffices) =>
	[
		company.address_line_1,
		company.address_line_2,
		company.postal_code,
		company.state_description,
		company.country_description
	]
		.filter(Boolean)
		.join(', ')
