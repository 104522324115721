import alertWhite from '@netcurio-ui/assets/src/icons/alert.svg'
import brokenAssociated from '@netcurio-ui/assets/src/icons/broken-associated.svg'
import { StatusSquareVariant, type StatusInformation } from '@netcurio-ui/components'
import Constants from '../../../utilities/constants'

export const pendingAsociate = (subLabel?: string): StatusInformation => {
	return {
		label: 'invoices.MISSING_LINK',
		subLabel: subLabel || '',
		icon: brokenAssociated,
		invertIconColor: true
	}
}

export const errorStatus = (subLabel?: string): StatusInformation => {
	return {
		label: 'ERROR',
		subLabel: subLabel || '',
		icon: alertWhite,
		invertIconColor: true,
		variant: StatusSquareVariant.ERROR
	}
}

const statuses = (currentStatus: string) => {
	const statusFunctions = {
		[Constants.LINKS.MISSING_LINK]: [pendingAsociate()],
		[Constants.ERROR.ERROR]: [errorStatus()]
	}
	return statusFunctions[currentStatus] || []
}

export const getVisualStatusInvoiceEdit = (currentStatus: string) => {
	return statuses(currentStatus)
}
