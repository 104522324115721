import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { fetchEnvironment } from './api/fetch-environment'
import App from './app'
import { initializeAmplify } from './utilities/initialize-amplify'
export const Bootstrap = () => {
	const { data: environment, isSuccess } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})
	if (isSuccess) {
		const region = environment?.COGNITO_REGION || ''
		const poolid = environment?.COGNITO_USERPOOLID || ''
		const clientid = environment?.COGNITO_CLIENTID || ''
		initializeAmplify(region, poolid, clientid)
	}

	return <App />
}
