import React from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface CancelNewRFQModalProps {
	open: boolean
	close: () => void
	accept: () => void
}

/**
 * CancelNewRFQModal
 * @description Helps to display a modal message when user try to cancel a new request for quotation
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @returns Modal component
 */
export const CancelNewRFQModal = ({ open, close, accept }: CancelNewRFQModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={t('cancelNewRFQ')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={accept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<div>
				<p>{t('cancelNewRFQquestion')}</p>
			</div>
		</NetcurioDialog>
	)
}
