import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { getSerieFolio, parseTimestampToTimeZone, dateFormatter, Roles, URLS } from '@netcurio-ui/common'
import { TypesTableRow } from './types'
import styles from './paymentComplementList.module.scss'

export const TableRow = ({
	informationTable: { created_at, status, date, folio, receiver, sender, serie, uuid },
	userRole
}: TypesTableRow): ReactElement => {
	const history = useHistory()

	return (
		<div
			className={styles.rowPaymentComplement}
			onClick={() => history.push(`${URLS.COMPLEMENT_DETAIL}?paymentComplement=${uuid}`)}
		>
			<p className={`${styles.textColumnMainTablePaymentComplement} ${styles.serieFolioColumn}`}>
				{getSerieFolio(serie, folio)}
			</p>
			<p className={`${styles.textColumnMainTablePaymentComplement} ${styles.statusColumn}`}>
				{status.value}
			</p>
			<p className={`${styles.textColumnMainTablePaymentComplement} ${styles.dateColumn}`}>
				{dateFormatter.format(parseTimestampToTimeZone(date))}
			</p>
			<p className={`${styles.textColumnMainTablePaymentComplement} ${styles.supplierCustomerColumn}`}>
				{userRole === Roles.CUSTOMER ? sender.name : receiver.name}
			</p>
			<p className={`${styles.textColumnMainTablePaymentComplement} ${styles.createdColumn}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
		</div>
	)
}
