import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import { TableRow } from './ItemPO/ItemPO'
import { PurchaseOrder, PurchaseOrderItem } from '../../../../types'

interface ItemsListProps {
	dataTable: Array<PurchaseOrderItem>
	informationPurchaseOrder: PurchaseOrder
}
export const ItemsList: FC<ItemsListProps> = ({ dataTable, informationPurchaseOrder }) => {
	const { t } = useTranslation()

	return (
		<NetcurioTableContainer minHeight="100%">
			<NetcurioTable size="small">
				<colgroup>
					<col style={{ width: '10%' }} />
					<col style={{ width: '30%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '15%' }} />
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionTooltip')} placement="top">
								<span>{t('positionText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell>
							<NetcurioTooltip title={t('productTooltip')} placement="top">
								<span>{t('productText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('storageLocationTooltip')} placement="top">
								<span>{t('storageLocation')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell>
							<NetcurioTooltip title={t('quantitiesHeaderTooltip')} placement="top">
								<span>{t('quantitiesHeader')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('requiredDateTooltip')} placement="top">
								<span>{t('requiredDate')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('proposalDateTooltip')} placement="top">
								<span>{t('dateProposalDeliverText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{dataTable.map((item: PurchaseOrderItem) => (
						<TableRow
							key={item.position}
							informationTable={item}
							statusPO={informationPurchaseOrder.status}
							typePO={informationPurchaseOrder.type}
						/>
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
