import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip } from '@netcurio-ui/components'
import { getLanguage, Status } from '@netcurio-ui/common'
import { PaymentTermAndFreight } from './PaymentTermAndFreight'
import { QuotationRegisterRowContainer } from './QuotationRegisterRowContainer'
import { ErrorRFQModal } from '../ModalsRFQ/ErrorRFQModal'
import { Product } from '../../../types'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import {
	FreightAndTerms,
	InformationRFQItem,
	ItemQ,
	ItemQuotation,
	NewQuotation as NewQuotationType,
	SelectedProductItemProps,
	ValidationItem
} from '../types'
import * as Queries from '../utilities/queries'
import Constants from '../../../utilities/constants'
import cancel from '@netcurio-ui/assets/src/icons/cancel.svg'
import cancelGray from '@netcurio-ui/assets/src/icons/cancel-gray.svg'
import cancelRed from '@netcurio-ui/assets/src/icons/cancel-red.svg'
import classNames from 'classnames'
import styles from './NewQuotation.module.scss'

interface NewQuotationProps {
	closeNewQuotation(): void
	settingValidateCatalogue: boolean
	newQuotation: NewQuotationType
	informationRFQItems: Array<InformationRFQItem>
	setNewQuotation(newQuotation: NewQuotationType): void
	fullInformationProducts?: Array<Product>
	activateNewQuotationButton(): void
	updateNewQuotation: Dispatch<SetStateAction<NewQuotationType>>
	cleanReasons: boolean
	resetDataQuotation: boolean
	allFreights: Array<FreightAndTerms>
	allPaymentTerms: Array<FreightAndTerms>
	searchFreight(search_text: string): void
	searchPaymentTerms(search_text: string): void
	selectedFreight(event: { originalEvent?: Event; value?: FreightAndTerms }): void
	selectedPaymentTerms(event: { originalEvent?: Event; value?: FreightAndTerms }): void
	setResetDataQuotation(): void
}

export const NewQuotation: FC<NewQuotationProps> = ({
	closeNewQuotation,
	settingValidateCatalogue,
	newQuotation,
	informationRFQItems,
	setNewQuotation,
	fullInformationProducts,
	activateNewQuotationButton,
	updateNewQuotation,
	cleanReasons,
	resetDataQuotation,
	allFreights,
	allPaymentTerms,
	searchFreight,
	searchPaymentTerms,
	selectedPaymentTerms,
	selectedFreight,
	setResetDataQuotation
}) => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [validationItems, setValidationItems] = useState<Array<Array<ValidationItem>>>([])
	const [validateDifferentCurrency, setValidateDifferentCurrency] = useState<Array<boolean>>([])
	const [allReasons, setAllReasons] = useState<Array<Status>>([])
	const [errorModalGetAllReasons, setErrorModalGetAllReasons] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>('')

	useEffect(() => {
		getAllReasonsRejected()
		return () => {
			resetListItemsQ()
		}
	}, [])

	useEffect(() => {
		if (fullInformationProducts.length > 0) {
			fullInformationProducts.forEach((product: Product, position: number) => {
				setFirstProduct(position, false, product)
			})
		}
		if (resetDataQuotation) {
			setResetDataQuotation()
		}
	}, [fullInformationProducts, resetDataQuotation])

	const resetListItemsQ = () => {
		const tempNewQuotation: NewQuotationType = structuredClone(newQuotation)
		tempNewQuotation.itemsQuotation.map((item: ItemQuotation) => {
			item.itemsQ = []
		})
		updateNewQuotation(tempNewQuotation)
	}

	const getAllReasonsRejected = () => {
		client
			.query({
				query: Queries.QUOTATION_REASONS_REJECT,
				variables: {
					language: getLanguage()
				}
			})
			.then((result: ApolloQueryResult<{ QuotationRejectedReasons: Array<Status> }>) => {
				setAllReasons(result.data.QuotationRejectedReasons)
			})
			.catch((error) => {
				if (!expiredToken(errorCode)) {
					setErrorCode(showErrorComponent(error))
					setErrorModalGetAllReasons(true)
				}
			})
	}

	const setFirstProduct = (positionRFQ: number, rejectRow: boolean, productDefault?: Product) => {
		if (fullInformationProducts) {
			const product: Product = productDefault ? productDefault : fullInformationProducts[positionRFQ]
			if (product) {
				selectedProductItem({
					product,
					itemIndex: 0,
					unitPrice: product.unit_price,
					unit: product.unit,
					tax: product.tax,
					referencePrice: product.unit_price,
					requiredDate: undefined,
					amount: informationRFQItems[positionRFQ].quantity,
					requestPosition: positionRFQ,
					currency: product.currency,
					rejectRow: rejectRow
				})
			}
		}
	}

	const checkCurrencyList = () => {
		newQuotation.itemsQuotation.forEach((item) => {
			if (!item.sameCurrencyList) {
				return false
			}
		})
		return true
	}

	const checkCurrency = (items: Array<ItemQ>) => {
		let currency = ''
		items.forEach((item) => {
			const tempProduct = item.product as Product
			if (item.product && currency !== tempProduct.currency && currency === '') {
				if (currency === '') {
					currency = tempProduct.currency
				} else {
					return false
				}
			}
		})
		return true
	}

	const getCurrencyPosition = (itemPosition: ItemQuotation) => {
		let currency = itemPosition.currencyPosition
		const itemsQuotation = itemPosition.itemsQ as Array<{
			product: Product
		}>

		if (itemsQuotation.length === 1) {
			if (
				itemsQuotation[0].product !== undefined &&
				itemPosition.currencyPosition !== itemsQuotation[0].product.currency
			) {
				currency = itemsQuotation[0].product.currency
			} else if (itemsQuotation[0].product === undefined && itemPosition.currencyPosition !== '') {
				currency = ''
			}
		} else {
			currency = itemPosition.currencyPosition
			for (let i = 0; i < itemsQuotation.length; i++) {
				if (itemsQuotation[i].product !== undefined) {
					if (currency !== itemsQuotation[i].product.currency) {
						currency = itemsQuotation[i].product.currency
					}
					break
				}
			}
		}
		return currency
	}

	const validateItem = (positionRFQ: number, field?: string) => {
		if (!validationItems[positionRFQ]) {
			setValidationItems((prevState) => [
				...prevState,
				[
					{
						productError: undefined,
						unitPriceError: undefined,
						unitPriceStringError: undefined,
						referencePriceError: undefined,
						unitError: undefined,
						requiredDate: undefined,
						taxError: undefined,
						amountError: undefined
					}
				]
			])
			return false
		}
		const subItemsPositions: Array<ItemQ> = newQuotation.itemsQuotation[positionRFQ].itemsQ
		const tempValidationItems: Array<Array<ValidationItem>> = [...validationItems]
		if (subItemsPositions.length > 0) {
			subItemsPositions.forEach((item: ItemQ, positionItem: number) => {
				if (field) {
					switch (field) {
						case 'requiredDate':
							tempValidationItems[positionRFQ][positionItem].requiredDate =
								item.requiredDate === undefined
							break
						case 'amount':
							tempValidationItems[positionRFQ][positionItem].amountError =
								item.amount === '' || parseFloat(item.amount.toString()) === 0
							break
						case 'unitPrice':
							tempValidationItems[positionRFQ][positionItem].unitPriceError =
								item.unitPrice === 'unitPrice' ||
								item.unitPrice === '' ||
								item.unitPrice === 0
							break
						case 'taxIVA':
							tempValidationItems[positionRFQ][positionItem].taxError = item.tax === 'taxIVA'
							break
						case 'productItem':
							if (!settingValidateCatalogue) {
								tempValidationItems[positionRFQ][positionItem].productError = !item.product
							}
							break
					}
				} else {
					if (settingValidateCatalogue) {
						tempValidationItems[positionRFQ][positionItem].productError = !item.product
						tempValidationItems[positionRFQ][positionItem].unitPriceError =
							item.unitPrice === 'unitPrice' || item.unitPrice === '' || item.unitPrice === 0
					} else {
						tempValidationItems[positionRFQ][positionItem].productError = !item.product
						tempValidationItems[positionRFQ][positionItem].unitPriceError =
							item.unitPrice === 'unitPrice' || item.unitPrice === '' || item.unitPrice === 0
						tempValidationItems[positionRFQ][positionItem].taxError = item.tax === 'taxIVA'
					}
					tempValidationItems[positionRFQ][positionItem].amountError =
						item.amount === '' || parseFloat(item.amount.toString()) === 0
					tempValidationItems[positionRFQ][positionItem].requiredDate =
						item.requiredDate === undefined
					tempValidationItems[positionRFQ][positionItem].requiredDate =
						item.requiredDate === undefined
				}
			})
		}

		setValidationItems(tempValidationItems)
		return !tempValidationItems[positionRFQ].some(
			(item) =>
				item.productError ||
				item.requiredDate ||
				item.unitPriceError ||
				item.amountError ||
				item.taxError
		)
	}

	const addItem = (positionRFQ: number) => {
		const itemPosition: ItemQuotation = newQuotation.itemsQuotation[positionRFQ]
		if (
			(itemPosition.sameCurrencyList || itemPosition.sameCurrencyList === undefined) &&
			checkCurrencyList()
		) {
			const itemsQuotation: Array<ItemQ> = itemPosition.itemsQ
			if (itemsQuotation.length === 0 || validateItem(positionRFQ)) {
				itemsQuotation.push({
					product: undefined,
					unitPrice: 'unitPrice',
					unitPriceString: 'unitPrice',
					referencePrice: '',
					unit: 'unitMeasure',
					tax: 'taxIVA',
					amount: '',
					requiredDate: undefined,
					netPrice: 'netValue',
					cancelImg: cancelGray,
					request_position: positionRFQ,
					currency: ''
				})

				if (validationItems[positionRFQ]) {
					const temValidationItems: Array<Array<ValidationItem>> = [...validationItems]
					temValidationItems[positionRFQ].push({
						productError: false,
						unitPriceError: false,
						unitPriceStringError: false,
						referencePriceError: false,
						unitError: false,
						requiredDate: false,
						taxError: false,
						amountError: false
					})
					setValidationItems(temValidationItems)
				} else {
					setValidationItems((prevState) => [
						...prevState,
						[
							{
								productError: undefined,
								unitPriceError: undefined,
								unitPriceStringError: undefined,
								referencePriceError: undefined,
								unitError: undefined,
								requiredDate: undefined,
								taxError: undefined,
								amountError: undefined
							}
						]
					])
				}

				setValidateDifferentCurrency((prevState) => [...prevState, false])
			}
			itemPosition.itemsQ = itemsQuotation
			itemPosition.currencyPosition = getCurrencyPosition(itemPosition)
			setNewQuotation(newQuotation)
		}
	}

	const selectedProductItem = ({
		product,
		itemIndex,
		unitPrice,
		unit,
		tax,
		referencePrice,
		requiredDate,
		amount,
		requestPosition,
		currency,
		rejectRow
	}: SelectedProductItemProps) => {
		const tempUpdateNewQuotation: NewQuotationType = newQuotation
		const cancelImg =
			product && unitPrice !== 'unitPrice' && requiredDate && amount !== '' ? cancelRed : cancelGray
		const objectItem: ItemQ = {
			product: product,
			unitPrice: unitPrice,
			unitPriceString: unitPrice.toString(),
			referencePrice: referencePrice,
			unit: unit,
			tax: tax,
			amount: amount,
			requiredDate: requiredDate,
			netPrice:
				amount !== '' && unitPrice !== 'unitPrice'
					? (typeof amount === 'string' ? 0 : amount) *
					  (typeof unitPrice === 'string' ? 0 : unitPrice)
					: 'netValue',
			cancelImg: cancelImg,
			request_position: requestPosition,
			currency: currency
		}

		tempUpdateNewQuotation.itemsQuotation[requestPosition].itemsQ.splice(itemIndex, 1, objectItem)
		updateNewQuotation(tempUpdateNewQuotation)
		if (!rejectRow) {
			updateInfoRow(requestPosition)
		}
	}

	const handleItemFieldChange = (
		event: ChangeEvent<HTMLInputElement>,
		index: number,
		field: string,
		requestPosition: number
	) => {
		if (event.preventDefault) {
			event.preventDefault()
		}

		const itemData: Array<ItemQ> = newQuotation.itemsQuotation[requestPosition].itemsQ
		const objectItem: ItemQ = itemData[index]

		switch (field) {
			case 'amount':
				const amountValue: number = parseFloat(event.target.value === '' ? '0' : event.target.value)
				if (itemData[index].unitPrice !== 'unitPrice') {
					const unitPrice: number =
						itemData[index].unitPrice === ''
							? 0
							: parseFloat(itemData[index].unitPrice.toString())
					objectItem.netPrice = amountValue * unitPrice
				}
				objectItem.amount = amountValue
				break
			case 'requiredDate':
				objectItem.requiredDate = event.target.value
				break
			case 'unitPrice':
				objectItem.unitPrice = event.target.value === '' ? '0' : event.target.value
				const amount: number =
					itemData[index].amount === '' ? 0 : parseFloat(itemData[index].amount.toString())
				objectItem.netPrice = amount * parseFloat(objectItem.unitPrice)
				break
			case 'taxIVA':
				objectItem.tax = event.target.value
				break
			case 'unitMeasure':
				objectItem.unit = event.target.value
				break
			case 'productItem':
				objectItem.product = event.target.value as Product
				break
		}

		objectItem.unitPriceString =
			Number.isNaN(objectItem.unitPrice) || objectItem.unitPrice === 'unitPrice'
				? 'unitPrice'
				: objectItem.unitPrice
		if (
			itemData[index].product !== undefined &&
			objectItem.unitPrice !== 'unitPrice' &&
			objectItem.requiredDate !== undefined &&
			objectItem.amount !== ''
		) {
			objectItem.cancelImg = cancelRed
		}
		itemData.splice(index, 1, objectItem)
		newQuotation.itemsQuotation[requestPosition].netTotal = getNetTotal(itemData)
		newQuotation.itemsQuotation[requestPosition].itemsQ = itemData
		setNewQuotation(newQuotation)
		validateItem(requestPosition, field)
		activateNewQuotationButton()
	}

	const getNetTotal = (itemData: Array<ItemQ>) => {
		return itemData.reduce((total: number, num: ItemQ) => {
			if (typeof num.netPrice === 'number') {
				return total + num.netPrice
			}
			return total
		}, 0)
	}

	const addMessageCurrency = (positionSelector) => {
		const temValidateDifferentCurrency = validateDifferentCurrency
		temValidateDifferentCurrency[positionSelector] = true
		setValidateDifferentCurrency(temValidateDifferentCurrency)
	}

	const removeMessageCurrency = (positionSelector) => {
		const temValidateDifferentCurrency = validateDifferentCurrency
		temValidateDifferentCurrency[positionSelector] = false
		setValidateDifferentCurrency(temValidateDifferentCurrency)
	}

	const deleteItem = (keyRFQ: number, itemPosition: number) => {
		const tempNewQuotation: NewQuotationType = newQuotation
		const items: Array<ItemQ> = tempNewQuotation.itemsQuotation[keyRFQ].itemsQ
		items.splice(itemPosition, 1)
		const sameCurrentList = checkCurrency(items)
		tempNewQuotation.itemsQuotation[keyRFQ].currencyPosition = getCurrencyPosition(
			tempNewQuotation.itemsQuotation[keyRFQ]
		)
		tempNewQuotation.itemsQuotation[keyRFQ].sameCurrencyList = sameCurrentList
		tempNewQuotation.itemsQuotation[keyRFQ].netTotal = getNetTotal(items)
		tempNewQuotation.itemsQuotation[keyRFQ].itemsQ = items

		if (!sameCurrentList) {
			addMessageCurrency(keyRFQ)
		} else {
			removeMessageCurrency(keyRFQ)
		}
		if (items.length > 0) {
			const tempValidationItems: Array<Array<ValidationItem>> = [...validationItems]
			tempValidationItems[keyRFQ].splice(itemPosition, 1)
			setValidationItems(tempValidationItems)
		} else {
			addItem(keyRFQ)
		}
		updateNewQuotation(tempNewQuotation)
		activateNewQuotationButton()
	}

	const updateInfoRow = (positionRFQ: number) => {
		const tempNewQuotation: NewQuotationType = newQuotation
		const itemPosition: ItemQuotation = tempNewQuotation.itemsQuotation[positionRFQ]
		const itemsQuotation: Array<ItemQ> = tempNewQuotation.itemsQuotation[positionRFQ].itemsQ
		const sameCurrencyList: boolean = checkCurrency(itemsQuotation)

		itemPosition.currencyPosition = getCurrencyPosition(itemPosition)
		itemPosition.sameCurrencyList = sameCurrencyList
		itemPosition.netTotal = getNetTotal(itemsQuotation)
		itemPosition.itemsQ = itemsQuotation
		itemPosition.status = Constants.STATUS.CONFIRMED

		if (!sameCurrencyList) {
			addMessageCurrency(positionRFQ)
		} else {
			removeMessageCurrency(positionRFQ)
		}
		tempNewQuotation.itemsQuotation[positionRFQ] = itemPosition
		setNewQuotation(tempNewQuotation)
		activateNewQuotationButton()
	}

	const rejectItemForQ = (rfqIndex: number) => {
		const tempNewQuotation = newQuotation
		const items = tempNewQuotation.itemsQuotation[rfqIndex].itemsQ
		items.splice(0, items.length)
		tempNewQuotation.itemsQuotation[rfqIndex].netTotal = 0
		tempNewQuotation.itemsQuotation[rfqIndex].status = Constants.STATUS.REJECTED
		addItem(rfqIndex)
	}

	const setReasonRejected = (rfqIndex: number, reason: Status) => {
		const tempNewQuotation = newQuotation
		tempNewQuotation.itemsQuotation[rfqIndex].rejectReason = reason
		setNewQuotation(tempNewQuotation)
		activateNewQuotationButton()
	}

	return (
		<div className={styles.newQuotationContainer}>
			<div className={styles.headerContainerQuotationListDetails}>
				<div className={styles.titleTableAlignRFQ}>
					<p className={styles.titleTableEntriesRFQ}>{t('quotationText')}</p>
					<img
						src={cancel}
						alt="cancel"
						className={styles.closeIconNewRFQ}
						onClick={() => closeNewQuotation()}
					/>
				</div>
			</div>
			<div id="container-new-quotation" className={styles.containerEntriesNewQuotations}>
				{!settingValidateCatalogue && (
					<PaymentTermAndFreight
						newQuotation={newQuotation}
						allFreights={allFreights}
						allPaymentTerms={allPaymentTerms}
						searchFreight={searchFreight}
						activateNewQuotationButton={activateNewQuotationButton}
						searchPaymentTerms={searchPaymentTerms}
						selectedFreight={selectedFreight}
						selectedPaymentTerms={selectedPaymentTerms}
						resetDataQuotation={resetDataQuotation}
						setResetDataQuotation={setResetDataQuotation}
					/>
				)}
				<div className={classNames(styles.tableHeader, styles.grayColorTextGeneralInfo)}>
					<NetcurioTooltip title={<label>{t('positionTooltip')}</label>} enterDelay={500}>
						<div className={classNames(styles.rowInformationCode, styles.headerTableEntries)}>
							<label>{t('positionText')}</label>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={<label>{t('productRequested')}</label>} enterDelay={500}>
						<div
							className={classNames(
								styles.rowInformationDescription,
								styles.headerTableEntries
							)}
						>
							<label className={styles.descriptionAlign}>{t('productRequested')}</label>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip
						title={<label>{t('requestedAmountQuotationListHeader')}</label>}
						enterDelay={500}
					>
						<div className={classNames(styles.rowInformationQuantity, styles.headerTableEntries)}>
							<label>{t('requestedAmountQuotationListHeader')}</label>
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip
						title={<label>{t('dateRequiredQuotationListHeader')}</label>}
						enterDelay={500}
					>
						<div
							className={classNames(
								styles.rowInformationDateRequired,
								styles.headerTableEntries
							)}
						>
							<label>{t('dateRequiredQuotationListHeader')}</label>
						</div>
					</NetcurioTooltip>
					<div className={styles.widthToIcon} />
				</div>
				<div
					className={classNames({
						[styles.containerRowsEntriesNewQuotation]: !settingValidateCatalogue,
						[styles.containerRowsEntriesNewQuotationCatalogue]: settingValidateCatalogue
					})}
				>
					{informationRFQItems &&
						informationRFQItems.map((item: InformationRFQItem, index: number) => (
							<QuotationRegisterRowContainer
								key={item.position}
								keyRFQ={index}
								informationItem={item}
								addItem={addItem}
								settingValidateCatalogue={settingValidateCatalogue}
								itemsQuotation={newQuotation.itemsQuotation[index].itemsQ}
								selectedProductItem={selectedProductItem}
								handleItemFieldChange={handleItemFieldChange}
								deleteItem={deleteItem}
								updateInfoRow={updateInfoRow}
								validationItems={validationItems[index]}
								validateDifferentCurrency={validateDifferentCurrency}
								cleanReasons={cleanReasons}
								resetDataQuotation={resetDataQuotation}
								allReasons={allReasons}
								rejectItemForQ={rejectItemForQ}
								setFirstProduct={setFirstProduct}
								setReasonRejected={setReasonRejected}
							/>
						))}
				</div>
			</div>
			<ErrorRFQModal open={errorModalGetAllReasons} errorCode={errorCode} />
		</div>
	)
}
