import React from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'

interface RejectPendingMessageInvoiceEditProps {
	open: boolean
	close(): void
	onAccept(): void
}

/**
 * RejectPendingMessageInvoiceEdit
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param open <boolean>: If `true`, the component is shown
 * @param close <boolean>: If `false`, the component is hide
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const RejectPendingMessageInvoiceEdit = ({
	open,
	close,
	onAccept
}: RejectPendingMessageInvoiceEditProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			titleText={t('goToList')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={close}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionCommentWithoutSend')}</p>
		</NetcurioDialog>
	)
}
