import React, { FC } from 'react'
import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderItem, RegisterBatch } from '../../../types'
import { ItemEntriesRecord } from './ItemEntriesRecord/ItemEntriesRecord'

interface TableEntriesRecordProps {
	itemsPendingForGoodsReceipt: Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }>
	receivedAmount(valueReceivedAmount: string, indexPosition: number): void
	saveBatchItems(batchItems: any, position: number): void
	isFiltering: boolean
}

export const TableEntriesRecord: FC<TableEntriesRecordProps> = ({
	itemsPendingForGoodsReceipt,
	receivedAmount,
	saveBatchItems,
	isFiltering
}) => {
	const { t } = useTranslation()

	return (
		<NetcurioTableContainer minHeight="100%">
			<NetcurioTable size="small">
				<colgroup>
					<col style={{ width: '10%' }} />
					<col style={{ width: '27%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '8%' }} />
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionTooltip')} placement="bottom">
								<span>{t('positionText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('productTooltip')} placement="bottom">
								<span>{t('descriptionProductHeader')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('storageLocationTooltip')} placement="bottom">
								<span>{t('storageLocation')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('confirmedAmountTooltip')} placement="bottom">
								<span>{t('confirmedAmount')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('quantityPendingTooltip')} placement="bottom">
								<span>{t('quantityPending')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('deliveredAmountTooltip')} placement="bottom">
								<span>{t('deliveredAmountText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('batchTooltip')} placement="bottom">
								<span>{t('batchText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{itemsPendingForGoodsReceipt.map((item: PurchaseOrderItem, index: number) => (
						<ItemEntriesRecord
							key={item.position}
							positionKey={index}
							receivedAmount={receivedAmount}
							saveBatchItems={saveBatchItems}
							dataPendingForGoodsReceipt={item}
							isFiltering={isFiltering}
						/>
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
