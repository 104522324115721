import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CommentSectionVariant, CommentsSection, UploadDocument } from '@netcurio-ui/components'
import { CommentSend, DocumentCommentsProps } from './types'
import Constants from '../../utilities/constants'
import checksvg from '@netcurio-ui/assets/src/icons/check.svg'
import classNames from 'classnames'
import styles from './styles.module.scss'

const DocumentComments = ({
	nameFile,
	setFile,
	setComment,
	disabledButtonSend,
	actionButtonSend
}: DocumentCommentsProps): ReactElement => {
	const { t } = useTranslation()
	const addNewComment = (newComment: CommentSend): void => {
		setComment(newComment.text)
	}

	return (
		<div className={styles.mainModule}>
			<div className={styles.mainContainerNewTicket}>
				<div className={styles.attachedDocumentContainer}>
					<div className={styles.headerContainerNewTicket}>
						<p className={styles.titleHeaderNewTicket}>{t('attacheddocumentText')}</p>
					</div>
					<div className={styles.containerDataNewTicket}>
						<UploadDocument
							id="newTicketField"
							label={t('fileInPDFText')}
							accept={Constants.MIME_TYPES.PDF}
							fileName={nameFile || ''}
							onFileChange={(file: File) => setFile(file)}
							className={styles.uploadDocumentTicket}
						/>
					</div>
				</div>
			</div>
			<div className={classNames(styles.module, styles.sizeModule)}>
				<div className={styles.totalModuleContainer}>
					<CommentsSection
						onAddComment={addNewComment}
						variant={CommentSectionVariant.Simple}
						onChange={setComment}
					/>
					<div className={styles.containerButton}>
						<button
							disabled={disabledButtonSend}
							className={`${styles.buttonLateralNewTicket} ${styles.buttonSendTicket}`}
							onClick={actionButtonSend}
						>
							<div className={styles.displayFlex}>
								<p className={styles.txtBtnSendTicket}>{t('sendInvoice')}</p>
								<img src={checksvg} className={styles.iconCheckSendTicket} alt="send" />
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DocumentComments
