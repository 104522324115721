import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface CancelAssociationProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

export const CancelModal: FC<CancelAssociationProps> = ({ onClose, onAccept, open }): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('cancelConsumption')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('cancelNewConsumption')}</p>
		</NetcurioDialog>
	)
}
