import React, { FC } from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'

interface RegisterConsumptionProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

export const RegisterConsumption: FC<RegisterConsumptionProps> = ({ open, onClose, onAccept }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('registerConsumptionModalTitle')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('textActionRegister')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('registerConsumptionMessage')}</p>
		</NetcurioDialog>
	)
}
