import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIconButton,
	NetcurioIcons,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTextField,
	NetcurioTooltip,
	useNetcurioLoader
} from '@netcurio-ui/components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ErrorWrapper, PurchaseOrderItem, RegisterBatch } from '../../../../types'
import { beforeUnloadListener } from '../../../../utilities/beforeUnloadListener'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { CREATE_GOOD_RECEIPT, ITEM_PENDING_GOOD_RECEIPT } from '../../graphQl'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import { SaveNewGRModal } from '../Modals/SaveNewGRModal/SaveNewGRModal'
import { ItemEntriesRecord } from './ItemEntriesRecord/ItemEntriesRecord'
import styles from './tableEntriesRecord.module.scss'

interface NewGoodsReceiptProps {
	resetAllDataPO(): void
	hideEntriesRecord(): void
	editGoodReceipt(): void
}

interface ObjItemToSave {
	product_code: string
	product_description: string
	quantity: number
	purchase_order_position: number
	unit: string
	batches?: Array<{
		quantity: number
		batch: string
	}>
}

export const NewGoodsReceipt: FC<NewGoodsReceiptProps> = ({
	hideEntriesRecord,
	editGoodReceipt,
	resetAllDataPO
}) => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { search } = useLocation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const queryParams = new URLSearchParams(search)
	const [disabledButtonRegisterGRS, setDisabledButtonRegisterGRS] = useState<boolean>(true)
	const [itemsPendingForGoodsReceipt, setItemsPendingForGoodsReceipt] = useState<
		Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }>
	>([])
	const [itemsPendingForGoodsReceiptCache, setItemsPendingForGoodsReceiptCache] = useState<
		Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }>
	>([])
	const [errorModal, setErrorModal] = useState<ErrorWrapper>()
	const [openSaveGRModal, setOpenSaveGRModal] = useState<boolean>(false)
	const [filterText, setFilterText] = useState<string>('')
	const [isFiltering, setIsFiltering] = useState<boolean>(false)

	useEffect(() => {
		getItemsPending(queryParams.get('order'), queryParams.get('customer'))
		return () => {
			beforeUnloadListener('remove')
		}
	}, [])

	const errorHandler = (error: Error) => {
		console.error(error)
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorModal({
				code: errorCode,
				message: error?.message
			})
		}
		hideLoadingSpinner()
	}

	const getItemsPending = (purchaseOrder, customer) => {
		client
			.query({
				query: ITEM_PENDING_GOOD_RECEIPT,
				variables: {
					purchase_order: purchaseOrder,
					customer: customer
				}
			})
			.then((result: ApolloQueryResult<{ ItemsPendingForGoodsReceipt: Array<PurchaseOrderItem> }>) => {
				setItemsPendingForGoodsReceipt(result.data.ItemsPendingForGoodsReceipt)
				setItemsPendingForGoodsReceiptCache(result.data.ItemsPendingForGoodsReceipt)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const receivedAmount = (valueReceivedAmount: string, indexPosition: number) => {
		const updatedItems: Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }> = [
			...itemsPendingForGoodsReceipt
		]
		updatedItems[indexPosition].receivedAmount = valueReceivedAmount

		if (
			!valueReceivedAmount &&
			(updatedItems[indexPosition]?.batches || updatedItems[indexPosition].receivedAmount)
		) {
			updatedItems[indexPosition].batches = undefined
			updatedItems[indexPosition].receivedAmount = undefined
		}

		const newReceivedAmount: boolean = updatedItems.some((item: PurchaseOrderItem) => item.receivedAmount)

		setDisabledButtonRegisterGRS(!newReceivedAmount)

		setItemsPendingForGoodsReceipt(updatedItems)
		editGoodReceipt()
	}

	const saveBatchItems = (batchItems: Array<RegisterBatch>, position: number) => {
		const updatedItemsPendingForGoodsReceipt: Array<
			PurchaseOrderItem & { batches?: Array<RegisterBatch> }
		> = [...itemsPendingForGoodsReceipt]
		if (position >= 0 && position <= updatedItemsPendingForGoodsReceipt.length) {
			updatedItemsPendingForGoodsReceipt[position] = {
				...updatedItemsPendingForGoodsReceipt[position],
				batches: batchItems
			}
		}
		setItemsPendingForGoodsReceipt(updatedItemsPendingForGoodsReceipt)
	}

	const saveNewGR = () => {
		showLoadingSpinner()
		const itemsToSaveGoodReceive: Array<ObjItemToSave> = itemsPendingForGoodsReceipt
			.filter((item: PurchaseOrderItem & { batches?: Array<RegisterBatch> }) => item.receivedAmount)
			.map((item: PurchaseOrderItem & { batches?: Array<RegisterBatch> }) => {
				const objItemToSave: ObjItemToSave = {
					product_code: item.code,
					product_description: item.name,
					quantity: parseFloat(item.receivedAmount),
					purchase_order_position: item.position,
					unit: item.unit
				}

				if (item.batches) {
					objItemToSave.batches = item.batches.map((batchItem) => ({
						quantity: batchItem.quantity,
						batch: batchItem.batch
					}))
				}

				return objItemToSave
			})
		client
			.mutate({
				mutation: CREATE_GOOD_RECEIPT,
				variables: {
					purchase_order: queryParams.get('order'),
					items: itemsToSaveGoodReceive
				}
			})
			.then(() => {
				beforeUnloadListener('remove')
				sessionStorage.setItem('showMessage', 'true')
				sessionStorage.setItem('showMessageTemp', 'true')
				setOpenSaveGRModal(false)
				resetAllDataPO()
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const filterItemsPendingForGoodsReceipt = (event: ChangeEvent<HTMLInputElement>) => {
		const value: string = event.target.value
		setFilterText(value)
		setIsFiltering(true)
		const filtered: Array<PurchaseOrderItem> = itemsPendingForGoodsReceiptCache.filter(
			(item: PurchaseOrderItem) =>
				item.name.toLowerCase().includes(value.toLowerCase()) || item.code.includes(value)
		)
		setItemsPendingForGoodsReceipt(filtered)
	}

	return (
		<NetcurioGrid
			container
			display="grid"
			gridTemplateRows="4rem 6rem 1fr"
			width="100%"
			height="100%"
			className={styles.containerEntriesGR}
		>
			<NetcurioGrid item xs={12}>
				<div className={styles.containerTitleTableEntries}>
					<p className={styles.titleTableEntries}>{t('titleTableRegisterEntries')}</p>

					<NetcurioIconButton size="large" onClick={hideEntriesRecord} color="inherit">
						<NetcurioIcons.CancelOutlined color="inherit" />
					</NetcurioIconButton>
				</div>
			</NetcurioGrid>

			<NetcurioGrid item xs={12} className={styles.containerSearchAndButton}>
				<NetcurioTextField
					size="small"
					placeholder={t('textSearchGR')}
					variant="outlined"
					value={filterText}
					onChange={filterItemsPendingForGoodsReceipt}
					adornment={<NetcurioIcons.Search />}
					adornmentPosition="end"
				/>
				<div className={styles.buttonsAlignment}>
					<NetcurioButton
						size="small"
						variant="outlined"
						color="secondary"
						fullWidth
						disabled={disabledButtonRegisterGRS}
						onClick={() => setOpenSaveGRModal(true)}
					>
						{t('registerEntries')}
					</NetcurioButton>
				</div>
			</NetcurioGrid>
			<NetcurioGrid item xs={12}>
				<NetcurioTableContainer minHeight="calc(100vh - 46.5rem)">
					<NetcurioTable size="small">
						<colgroup>
							<col style={{ width: '10%' }} />
							<col style={{ width: '27%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '15%' }} />
							<col style={{ width: '10%' }} />
							<col style={{ width: '8%' }} />
						</colgroup>
						<NetcurioTableHead>
							<NetcurioTableRow isDetailHeader>
								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('positionTooltip')} placement="bottom">
										<span>{t('positionText')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('productTooltip')} placement="bottom">
										<span>{t('descriptionProductHeader')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('storageLocationTooltip')} placement="bottom">
										<span>{t('storageLocation')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('confirmedAmountTooltip')} placement="bottom">
										<span>{t('confirmedAmount')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('quantityPendingTooltip')} placement="bottom">
										<span>{t('quantityPending')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('deliveredAmountTooltip')} placement="bottom">
										<span>{t('deliveredAmountText')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>

								<NetcurioTableCell align="center">
									<NetcurioTooltip title={t('batchTooltip')} placement="bottom">
										<span>{t('batchText')}</span>
									</NetcurioTooltip>
								</NetcurioTableCell>
							</NetcurioTableRow>
						</NetcurioTableHead>
						<NetcurioTableBody>
							{itemsPendingForGoodsReceipt.map((item: PurchaseOrderItem, index: number) => (
								<ItemEntriesRecord
									key={item.position}
									positionKey={index}
									receivedAmount={receivedAmount}
									saveBatchItems={saveBatchItems}
									dataPendingForGoodsReceipt={item}
									isFiltering={isFiltering}
								/>
							))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			</NetcurioGrid>
			<ErrorModal open={!!errorModal?.code} errorCode={errorModal?.message} />
			<SaveNewGRModal
				open={openSaveGRModal}
				onClose={() => {
					setOpenSaveGRModal(false)
				}}
				onAccept={saveNewGR}
			/>
		</NetcurioGrid>
	)
}
