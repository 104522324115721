import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const INVOICES_EDIT: DocumentNode = gql`
	query Invoice($uuid: ID!) {
		Invoice(uuid: $uuid) {
			uuid
			serie
			folio
			status {
				key
				value
			}
			sender {
				rfc
				name
			}
			receiver {
				rfc
				name
			}
			reference
			reference_type
			purchase_order {
				id
				customer_reference
				customer {
					rfc
				}
			}
			consumption {
				id
				customer_reference
				customer {
					rfc
				}
			}
			uploaded_by {
				email
				name
				lastname
			}
			modified_by_supplier {
				email
				name
				lastname
			}
			modified_by_customer {
				email
				name
				lastname
			}
			payment_method
			currency
			subtotal
			iva
			total
			date
			proposed_payment_date
			payment_date
			updated_at
			created_at
			error {
				key
				value
			}
			items {
				position
				concept
				quantity
				unit_price
				net_amount
				tax
				reference_position
				error
				code
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			invoice_documents {
				link
				document_type {
					code
					description
				}
			}
			ieps
			isr
			sat_validation_message
			folder_uuid
			folder_uuid
			folder {
				created_by
				created_at
				files {
					name
					description
					url
					created_by
					created_at
					file_size
				}
			}
			discount
			iva_ret
			advance
		}
	}
`
