import { NetcurioButton, NetcurioIcons, NetcurioTooltip } from '@netcurio-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ExportButtonProps {
	onClick: () => void
	translationKey?: string
}

export const ExportButton = ({ onClick, translationKey = 'exportbtn' }: ExportButtonProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioTooltip title={t('buttonExportTooltip')}>
			<NetcurioButton
				variant="outlined"
				color="primary"
				endIcon={<NetcurioIcons.Download />}
				onClick={onClick}
				size="small"
			>
				{t(translationKey)}
			</NetcurioButton>
		</NetcurioTooltip>
	)
}
