import gql from 'graphql-tag'

export const CREDIT_MEMO_DETAIL = gql`
	query CreditMemo($uuid: ID!) {
		CreditMemo(uuid: $uuid) {
			uuid
			sender {
				name
				rfc
			}
			receiver {
				name
				rfc
			}
			total
		}
	}
`

export const VOID_CREDIT_MEMO = gql`
	mutation voidCreditMemo($uuid: ID!, $sat_validation_message: String) {
		voidCreditMemo(uuid: $uuid, sat_validation_message: $sat_validation_message) {
			code
			message
		}
	}
`

export const COMPANY_SETTINGS = gql`
	query CompanySettings($rfc: String!) {
		CompanySettings(rfc: $rfc) {
			tolerance
			affect_consignment_stock
			validate_catalogue_on_po_creation
			validate_confirmed_on_gr_creation
			process_invoice_in_external_system
			validate_quantity_invoice_vs_consumption
			validate_sat
		}
	}
`
