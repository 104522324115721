import React, { FC } from 'react'
import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import { useTranslation } from 'react-i18next'
import { GoodReceiptItem } from '../../types'
import { Item } from './Item'

interface ItemsListProps {
	items: Array<GoodReceiptItem>
}
export const ItemsList: FC<ItemsListProps> = ({ items }) => {
	const { t } = useTranslation()

	return (
		<NetcurioTableContainer minHeight="100%">
			<NetcurioTable size="small">
				<colgroup>
					<col style={{ width: '20%' }} />
					<col style={{ width: '30%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '20%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionTooltip')} placement="top">
								<span>{t('positionText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('productTooltip')} placement="top">
								<span>{t('descriptionHeader')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('storageLocationTooltip')} placement="top">
								<span>{t('storageLocation')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('deliveredAmountTooltipLotr')} placement="top">
								<span>{t('deliveredAmountText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>

						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('batchTooltip')} placement="top">
								<span>{t('batchText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{items.map((item: GoodReceiptItem) => (
						<Item key={item.position} data={item} />
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
