import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { parseTimestampToTimeZone, dateFormatter, Roles, URLS } from '@netcurio-ui/common'
import { TypesPropsTableRow } from './types'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import styles from './consumptionList.module.scss'

export const TableRow = ({
	dataConsumption: { branch_office, created_at, created_by, customer, customer_reference, id },
	userRole
}: TypesPropsTableRow): ReactElement => {
	const history = useHistory()

	return (
		<div
			className={`${styles.rowConsumptionList}`}
			onClick={() =>
				history.push(`${URLS.CONSUMPTION_DETAIL}?consumption=${id}&customer=${customer.rfc}`)
			}
		>
			<p className={`${styles.fieldConsumption} ${styles.fieldId}`}>{Formatter.id(id)}</p>
			<p className={`${styles.fieldConsumption} ${styles.fieldCustomerReference}`}>
				{customer_reference ? customer_reference : Constants.SYMBOL.HYPHEN}
			</p>
			{userRole === Roles.SUPPLIER && (
				<p className={`${styles.fieldConsumption} ${styles.fieldCustomerName}`}>{customer.name}</p>
			)}
			<p className={`${styles.fieldConsumption} ${styles.fieldBranchOffice}`}>{branch_office}</p>
			{userRole === Roles.CUSTOMER && (
				<p className={`${styles.fieldConsumption} ${styles.fieldUsername}`}>
					{created_by ? `${created_by.name} ${created_by.lastname}` : null}
				</p>
			)}
			<p className={`${styles.fieldConsumption} ${styles.fieldCreatedAt}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
		</div>
	)
}
