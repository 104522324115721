import React from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip } from '@netcurio-ui/components'
import Constants from '../../utilities/constants'
import classNames from 'classnames'
import styles from './HeaderTable.module.scss'

interface dataMainHeaderTableType {
	name
	parameterField
	parameterFieldClass
	secondaryClass
	selectedFieldClass
}

interface mainHeaderTableProps {
	textHeader: string
	showFilters?: (field, fieldStyle) => void
	dataMainHeaderTable: dataMainHeaderTableType
	isForGridArea?: boolean
}

/**
 * MainHeaderTable
 * @param textHeader <string> class to be used in the main div of each header
 * @param showFilters <function> function called to show or hide the table's filters
 * @param dataMainHeaderTable <object> group of classes used to give custom style to each table's headers
 * @param isForGridArea <boolean>
 */
export const HeaderTable = ({
	textHeader,
	showFilters,
	dataMainHeaderTable,
	isForGridArea
}: mainHeaderTableProps) => {
	const { t } = useTranslation()
	return (
		<div className={isForGridArea && styles.headerForGridArea}>
			<NetcurioTooltip title={t(Constants.TOOLTIP.TOOLTIP_HEADER[dataMainHeaderTable.name])}>
				<div
					className={classNames(
						textHeader,
						dataMainHeaderTable.secondaryClass,
						dataMainHeaderTable.selectedFieldClass,
						styles.textHeaderOrderList,
						isForGridArea && styles.textHeaderOrderListFoGridArea
					)}
					onClick={() =>
						showFilters(
							dataMainHeaderTable.parameterField,
							dataMainHeaderTable.parameterFieldClass
						)
					}
				>
					<div>{t(dataMainHeaderTable.name)}</div>
				</div>
			</NetcurioTooltip>
		</div>
	)
}
