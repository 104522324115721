import arrowIcon from '@netcurio-ui/assets/src/icons/arrow-go-purchase.svg'
import { removeLeadingZeros } from '@netcurio-ui/common'
import { t } from 'i18next'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Consumption, PurchaseOrder } from '../../../../types'
import styles from './associationSection.module.scss'

interface AssociationSectionProps {
	associationType: 'consumption' | 'po' | 'noPO' | null
	entityData: PurchaseOrder | Consumption
}

export const AssociationSection = ({ associationType, entityData }: AssociationSectionProps) => {
	const history = useHistory()
	const isPO = associationType === 'po'
	const isConsumption = associationType === 'consumption'

	const handleArrowIconClick = (): void => {
		const customer = entityData.customer.rfc
		const path = `/detail${isPO ? 'purchaseorder' : 'consumption'}?${isPO ? 'order' : 'consumption'}=${
			entityData.id
		}&customer=${customer}`
		history.push(path)
	}

	const associationTypeText = () => {
		if (isPO) {
			return t('purchaseOrderText')
		}
		if (isConsumption) {
			return t('consumptionText')
		}

		return null
	}

	return (
		<div>
			<div className={styles.associationHeader}>
				<p className={styles.associationHeaderText}>{t('workFlow')}</p>
			</div>
			<div className={styles.bodyContainer}>
				<div className={styles.associationBody}>
					<p className={styles.textBodyAssociation}>{associationTypeText()}</p>
					<div className={styles.inputAssociation}>
						{associationType === 'noPO' ? (
							<p className={styles.associationText}>{t('invoice.noPurchaseOrderText')}</p>
						) : (
							entityData && (
								<>
									<p className={styles.associationText}>
										{`${removeLeadingZeros(entityData.id)} - ${
											entityData?.customer_reference === 'null' ||
											entityData?.customer_reference === null
												? t('NAtext')
												: entityData?.customer_reference
										}`}
									</p>
									<img
										src={arrowIcon}
										className={styles.iconArrow}
										alt="Arrow Icon"
										onClick={handleArrowIconClick}
									/>
								</>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
