import { PURCHASE } from '@netcurio-ui/common'
import { t } from 'i18next'
import { StatusFilter } from '../../types/StatusFilter'
import Constants from '../../utilities/constants'

export const statusFilterPO: StatusFilter[] = [
	{
		label: t(Constants.STATUS.NEW),
		value: Constants.STATUS.NEW
	},
	{
		label: t(Constants.STATUS.CONFIRMED),
		value: Constants.STATUS.CONFIRMED
	},
	{
		label: t(PURCHASE.HALF_CONFIRMED),
		value: PURCHASE.HALF_CONFIRMED
	},
	{
		label: t(Constants.STATUS.DELIVERED),
		value: Constants.STATUS.DELIVERED
	},
	{
		label: t(PURCHASE.HALF_DELIVERED),
		value: PURCHASE.HALF_DELIVERED
	},
	{
		label: t(Constants.STATUS.REJECTED),
		value: Constants.STATUS.REJECTED
	}
]

export const statusFilterInvoice: StatusFilter[] = [
	{
		label: t(PURCHASE.PAYMENT_SCHEDULED),
		value: PURCHASE.PAYMENT_SCHEDULED
	},
	{
		label: t(PURCHASE.PAID),
		value: PURCHASE.PAID
	},
	{
		label: t(PURCHASE.VOIDED),
		value: PURCHASE.VOIDED
	},
	{
		label: t(Constants.ERROR.ERROR),
		value: Constants.ERROR.ERROR
	},
	{
		label: t(Constants.LINKS.MISSING_LINK),
		value: Constants.LINKS.MISSING_LINK
	},
	{
		label: t(Constants.INVOICE.IN_APPROVAL),
		value: Constants.INVOICE.IN_APPROVAL
	},
	{
		label: t(Constants.STATUS.IN_PROCESS),
		value: Constants.STATUS.IN_PROCESS
	}
]

export const statusFilterCreditMemo: StatusFilter[] = [
	{
		label: t(Constants.CREDIT_MEMO_STATUS.ERROR),
		value: Constants.CREDIT_MEMO_STATUS.ERROR
	},
	{
		label: t(Constants.CREDIT_MEMO_STATUS.MISSING_LINK),
		value: Constants.CREDIT_MEMO_STATUS.MISSING_LINK
	},
	{
		label: t(Constants.CREDIT_MEMO_STATUS.VOIDED),
		value: Constants.CREDIT_MEMO_STATUS.VOIDED
	},
	{
		label: t(Constants.CREDIT_MEMO_STATUS.ACCOUNTED),
		value: Constants.CREDIT_MEMO_STATUS.ACCOUNTED
	},
	{
		label: t(Constants.CREDIT_MEMO_STATUS.CANCELED),
		value: Constants.CREDIT_MEMO_STATUS.CANCELED
	}
]

export const statusFilterPaymentComplement: StatusFilter[] = [
	{
		label: t(Constants.PAYMENT_COMPLEMENT_STATUS.ACCOUNTED, { context: 'MALE' }),
		value: Constants.PAYMENT_COMPLEMENT_STATUS.ACCOUNTED
	},
	{
		label: t(Constants.PAYMENT_COMPLEMENT_STATUS.HALF),
		value: Constants.PAYMENT_COMPLEMENT_STATUS.HALF
	},
	{
		label: t(Constants.PAYMENT_COMPLEMENT_STATUS.VOIDED, { context: 'MALE' }),
		value: Constants.PAYMENT_COMPLEMENT_STATUS.VOIDED
	}
]
