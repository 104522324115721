import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip } from '@netcurio-ui/components'
import { parseTimestampToTimeZone, dateFormatter } from '@netcurio-ui/common'
import { RequestPosition } from '../types'
import Formatter from '../../../utilities/formatter'
import styles from './Quotation.module.scss'

interface QuotationReferenceRowProps {
	rfqItem: RequestPosition
}

export const QuotationReferenceRow: FC<QuotationReferenceRowProps> = ({
	rfqItem: { position, product_description, quantity, unit, required_delivery_date, product_code }
}) => {
	const { t } = useTranslation()
	return (
		<div className={styles.rfqReferenceRowContainer}>
			<div className={styles.rqfRowPosition}>
				<span>{position}</span>
			</div>
			<div className={styles.rqfRowDescription}>
				<NetcurioTooltip title={<label>{product_description}</label>} placement="right">
					<div className={styles.rfqDescriptionContainer}>
						<span>
							{t('itemCode')} {t(Formatter.nullValueFormattingToNA(product_code))}{' '}
						</span>
						<span className={styles.rfqDescription}>
							{t('ProductServiceRequested')} {product_description}
						</span>
					</div>
				</NetcurioTooltip>
			</div>
			<div className={styles.rqfRowAmount}>
				<span>
					{quantity} {unit}
				</span>
			</div>
			<div className={styles.rqfRowDate}>
				{dateFormatter.format(parseTimestampToTimeZone(required_delivery_date))}
			</div>
		</div>
	)
}
