import { URLS } from '@netcurio-ui/common'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { ConditionalRender } from '../../../../components/ConditionalRender/ConditionalRender'
import {
	Consumption,
	ConsumptionItem,
	Invoice,
	InvoiceItem,
	PurchaseOrder,
	PurchaseOrderItem
} from '../../../../types'
import documentType from '../../../../utilities/constants/documentType'
import { AutoAssociateInvoice } from '../../InvoiceActions/AutoAssociateInvoice'
import { SendInvoice } from '../../InvoiceActions/SendInvoice'
import { VoidInvoice } from '../../InvoiceActions/VoidInvoice'
import styles from './ButtonsSection.module.scss'

interface EditInvoicesButtonsSectionProps {
	isSendDisabled: boolean
	currentAssociation: PurchaseOrder | Consumption
	invoice: Invoice
	errorHandler: (error: Error) => void
	relatedDocumentType: string
	documentSelected: string
	invoiceItems: InvoiceItem[]
	getInvoiceInfo?: () => void
	suggestionsItemsToAssociate: Array<PurchaseOrderItem | ConsumptionItem>
	onFinishedAutoAssociation: (invoiceItems: InvoiceItem[], hasExceedItems: boolean) => void
}

export const EditInvoicesButtonsSection = ({
	isSendDisabled,
	invoice,
	errorHandler,
	relatedDocumentType,
	documentSelected,
	invoiceItems,
	getInvoiceInfo,
	suggestionsItemsToAssociate,
	currentAssociation,
	onFinishedAutoAssociation
}: EditInvoicesButtonsSectionProps): ReactElement => {
	const isStandaloneFlow = relatedDocumentType === documentType.STANDALONE
	const history = useHistory()

	const handleSendInvoiceSuccess = () => {
		history.replace(`${URLS.INVOICE_DETAIL}?invoice=${invoice.uuid}`)
	}

	return (
		<div className={styles.containerButtons}>
			<ConditionalRender condition={!invoice.advance}>
				<AutoAssociateInvoice
					invoiceItems={invoiceItems}
					suggestionsItemsToAssociate={suggestionsItemsToAssociate}
					currentAssociation={currentAssociation}
					onFinishedAutoAssociation={onFinishedAutoAssociation}
				/>
			</ConditionalRender>

			<SendInvoice
				invoice={invoice}
				disabled={!isStandaloneFlow && isSendDisabled}
				errorHandler={errorHandler}
				relatedDocumentType={relatedDocumentType}
				documentSelected={documentSelected}
				invoiceItems={invoiceItems}
				getInvoiceInfo={getInvoiceInfo}
				onSendInvoiceSuccess={handleSendInvoiceSuccess}
				isStandaloneFlow={isStandaloneFlow}
			/>

			<VoidInvoice invoice={invoice} />
		</div>
	)
}
