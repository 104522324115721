import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { URLS } from '@netcurio-ui/common'
import { DataRowProduct } from './types'
import Constants from '../../../../../utilities/constants'
import Formatter from '../../../../../utilities/formatter'
import styles from '../../../adminConsole.module.scss'

export const TableRow = ({
	dataProduct: { id, description, unit_price, currency, unit, is_active, supplier }
}: DataRowProduct): ReactElement => {
	return (
		<Link
			className={styles.rowContainerAdmin}
			to={`${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB}/${supplier.rfc}&${id}`}
		>
			<p className={`${styles.tableFieldAdmin} ${styles.productRowStandard}`}>{id}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.productRowxLarge} ${styles.textOverflow}`}>
				{description.length > 150 ? description.substr(0, 168) + '...' : description.substr(0, 168)}
			</p>
			<p className={`${styles.tableFieldAdmin}  ${styles.productRowStandard}`}>
				{Formatter.currency.format(unit_price)}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.productRowSmall}`}>{currency}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.productRowSmall}`}>{unit}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.productRowSmall}`}>
				{t(is_active ? 'ACTIVE' : 'INACTIVE')}
			</p>
		</Link>
	)
}
