import { PURCHASE, Status } from '@netcurio-ui/common'
import { NetcurioButton, NetcurioGrid, NetcurioIcons } from '@netcurio-ui/components'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Constants from '../../../../utilities/constants'

interface InvoiceDetailButtons {
	isCustomer: boolean
	statusInvoice: Status
	openRegisterPaymentModal: Dispatch<SetStateAction<boolean>>
	cancelButton: ReactElement
}

export const InvoiceDetailButtons = ({
	isCustomer,
	statusInvoice,
	openRegisterPaymentModal,
	cancelButton
}: InvoiceDetailButtons): ReactElement => {
	const { t } = useTranslation()

	const canCancelInvoice =
		statusInvoice.key === Constants.STATUS.IN_PROCESS || statusInvoice.key === PURCHASE.PAYMENT_SCHEDULED

	return (
		<>
			{isCustomer ? (
				<NetcurioGrid container display="flex" justifyContent="end">
					{statusInvoice.key === PURCHASE.PAYMENT_SCHEDULED && (
						<NetcurioGrid item marginY="0.5rem" width="18rem">
							<NetcurioButton
								variant="outlined"
								endIcon={<NetcurioIcons.Check />}
								fullWidth
								size="medium"
								onClick={() => {
									openRegisterPaymentModal(true)
								}}
							>
								<span> {t('registerPayment')} </span>
							</NetcurioButton>
						</NetcurioGrid>
					)}
					{canCancelInvoice && cancelButton}
				</NetcurioGrid>
			) : null}
		</>
	)
}
