import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import add from '@netcurio-ui/assets/src/icons/plus.svg'
import { QuotationRegisterRow } from './QuotationRegisterRow'
import { QuotationRegisterUncataloguedRow } from './QuotationRegisterUncataloguedRow'
import { InformationRFQItem, ItemQ, SelectedProductItemProps, ValidationItem } from '../types'
import classNames from 'classnames'
import styles from './NewQuotation.module.scss'

interface QuotationRegisterItemListProps {
	keyRFQ: number
	addItem(keyRFQ: number): void
	settingValidateCatalogue: boolean
	itemsQuotation: Array<ItemQ>
	selectedProductItem(selectedProductItemProps: SelectedProductItemProps): void
	handleItemFieldChange(
		event: ChangeEvent<HTMLInputElement>,
		index: number,
		field: string,
		requestPosition: number
	): void
	deleteItem(keyRFQ: number, itemPosition: number): void
	updateInfoRow(updateInfoRow: number): void
	validationItems?: Array<ValidationItem>
	validateDifferentCurrency: Array<boolean>
	informationItem: InformationRFQItem
	cleanReason: boolean
	resetDataQuotation: boolean
}

export const QuotationRegisterItemList: FC<QuotationRegisterItemListProps> = ({
	keyRFQ,
	addItem,
	settingValidateCatalogue,
	itemsQuotation,
	selectedProductItem,
	handleItemFieldChange,
	deleteItem,
	updateInfoRow,
	validationItems,
	validateDifferentCurrency,
	informationItem,
	cleanReason,
	resetDataQuotation
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.containerListRegisterProduct}>
			{settingValidateCatalogue
				? validationItems &&
				  itemsQuotation &&
				  itemsQuotation.map((item: ItemQ, index: number) => (
						<QuotationRegisterRow
							key={index}
							itemPosition={index}
							keyRFQ={keyRFQ}
							itemQuotation={item}
							selectedProductItem={selectedProductItem}
							handleItemFieldChange={handleItemFieldChange}
							deleteItem={deleteItem}
							validationItem={validationItems[index]}
						/>
				  ))
				: validationItems &&
				  itemsQuotation &&
				  itemsQuotation.map((item: ItemQ, index: number) => (
						<QuotationRegisterUncataloguedRow
							key={index}
							itemPosition={index}
							keyRFQ={keyRFQ}
							informationItem={informationItem}
							itemQuotation={item}
							selectedProductItem={selectedProductItem}
							handleItemFieldChange={handleItemFieldChange}
							deleteItem={deleteItem}
							cleanReason={cleanReason}
							updateInfoRow={updateInfoRow}
							resetDataQuotation={resetDataQuotation}
							validationItem={validationItems[index]}
						/>
				  ))}

			{validationItems &&
				validationItems.some(
					(item: ValidationItem) =>
						item.productError ||
						item.requiredDate ||
						item.unitPriceError ||
						item.amountError ||
						item.taxError
				) && (
					<div className={styles.messageError}>
						<label>{t('missingFields')}</label>
					</div>
				)}
			{validateDifferentCurrency[keyRFQ] && (
				<div className={styles.messageError}>
					<label>{t('messageDifferentCurrency')}</label>
				</div>
			)}
			<button
				className={classNames(styles.buttonAddProductCreateQuotation, styles.backgroundTransparent)}
				onClick={() => addItem(keyRFQ)}
			>
				<img src={add} alt="cancel" className={styles.addIcon} />
				<label className={styles.textButtonAddProduct}>{t('addItem')}</label>
			</button>
		</div>
	)
}
