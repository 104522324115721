import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
interface RemoveDocumentAssociatedModalProps {
	onClose(): void
	onAccept(): void
	open: boolean
}

export const RemoveDocumentAssociatedModal: FC<RemoveDocumentAssociatedModalProps> = ({
	onClose,
	onAccept,
	open
}): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('removeAssociationTitle')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('removeAssociationMessage')}</p>
		</NetcurioDialog>
	)
}
