import { fillModal } from '../components/dialogModal/fillModal'

export function expiredSessionModal() {
	const expiredSession = new fillModal(
		'expiredSession',
		'expireSession',
		'expireSessionText',
		'session',
		'continueText',
		'exitText'
	)
	const objectModal = [expiredSession]
	return objectModal
}
