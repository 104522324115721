import Constants from '../constants'

export function downloadTxtFile(blob: string, fileName: string) {
	generateBinaryDownloadElement(blob, Constants.MIME_TYPES.TXT, fileName)
}

const generateBinaryDownloadElement = (b64encoded: string, type: string, fileName: string) => {
	let oldLink = document.getElementById('downloadBinaryElement')
	if (oldLink) {
		oldLink.remove()
	}
	let bin = atob(b64encoded)
	let arrayBuffer = s2ab(bin)
	let blob = new Blob([arrayBuffer], {
		type: type
	})

	let link = document.createElement('a')
	link.setAttribute('id', 'downloadBinaryElement')
	document.body.appendChild(link)

	link.href = URL.createObjectURL(blob)
	link.download = fileName

	link.click()
}

const s2ab = (s: string) => {
	let buf = new ArrayBuffer(s.length)
	let view = new Uint8Array(buf)
	for (let i = 0; i < s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
	return buf
}
