import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextArea, NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface CancelPurchaseOrderModalProps {
	open: boolean
	close: () => void
	accept: (reason: string) => void
}

/**
 * PriceModifiedPurchaseOrderModal
 * @description Helps to display a message when user try to save the changes to ticket permissions
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @returns Modal component
 */
export const PriceModifiedModal = ({ open, close, accept }: CancelPurchaseOrderModalProps) => {
	const { t } = useTranslation()
	const [reason, setReason] = useState<string>(undefined)

	useEffect(() => {
		if (!open) {
			setReason(undefined)
		}
	}, [open])

	const triggerAccept = () => {
		accept(reason.trim())
	}

	return (
		<NetcurioDialog
			open={open}
			titleText={t('sendNewOrder')}
			actionButtons={
				<>
					<NetcurioButton onClick={close}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton
						variant="contained"
						onClick={triggerAccept}
						disabled={reason ? !reason.trim() : !reason}
					>
						{t('buttonSendTextHco')}
					</NetcurioButton>
				</>
			}
		>
			<label>{t('createNewOrderWithPriceModified')}</label>
			<div>
				<TextArea
					placeholder={t('placehorlderMotiveChange')}
					maxLength={120}
					rows={3}
					onChangeText={(value) => setReason(value)}
					readValue={reason}
				/>
			</div>
			{!reason && (
				<div className="align-text-modal">
					<label className=" message-error">{t('requiredField')}</label>
				</div>
			)}
		</NetcurioDialog>
	)
}
