/**
 * Do not edit directly
 * Generated on Thu, 14 Nov 2024 16:16:25 GMT
 */

export const MdRefPalettePrimaryAMain = "#00A99D";
export const MdRefPalettePrimaryBDark = "#005D56";
export const MdRefPalettePrimaryCLight = "#7FD4CE";
export const MdRefPalettePrimaryDContrast = "#E5F6F5";
export const MdRefPaletteSecondaryAMain = "#0071BC";
export const MdRefPaletteSecondaryBDark = "#003E67";
export const MdRefPaletteSecondaryCLight = "#7Fb8DD";
export const MdRefPaletteSecondaryDContrast = "#E5F1F8";
export const MdRefPaletteTertiaryAMain = "#00000087";
export const MdRefPaletteTertiaryDContrast = "#E5F1F8";
export const MdRefPaletteErrorAMain = "#D32F2F";
export const MdRefTypefaceFont = "Helvetica Neue";
export const MdSysColorPrimaryBackground = "#00A99D";
export const MdSysColorPrimaryHover = "#005D56";
export const MdSysColorOnPrimary = "#E5F1F8";
export const MdSysColorSecondaryBackground = "#00A99D";
export const MdSysColorSecondary = "#0071BC";
export const MdSysColorSecondaryHover = "#E5F1F8";
export const MdSysColorTertiary = "#00000087";
export const MdSysColorTertiaryHover = "#E5F1F8";
export const MdSysLabelMediumLineHeight = "2.4rem";
export const MdSysLabelMediumSize = "1.4rem";
export const MdSysLabelFont = "Helvetica Neue";
export const NetcurioRefPrimaryAMain = "#7C80EF";
export const NetcurioRefPrimaryBDark = "#494FE9";
export const NetcurioRefPrimaryCLight = "#B6B8F6";
export const NetcurioRefPrimaryDContrast = "#FFFFFF";
export const NetcurioRefSecondaryAMain = "#2C2F7D";
export const NetcurioRefSecondaryBDark = "#20225B";
export const NetcurioRefSecondaryCLight = "#4044B5";
export const NetcurioRefSecondaryDContrast = "#FFFFFF";
export const NetcurioRefWarningAMain = "#ED6C02";
export const NetcurioRefWarningBDark = "#E65100";
export const NetcurioRefWarningCLight = "#FF9800";
export const NetcurioRefWarningDAlertText = "#663C01";
export const NetcurioRefWarningEContrast = "#FFFFFF";
export const NetcurioRefErrorAMain = "#D32F2F";
export const NetcurioRefErrorBDark = "#C62828";
export const NetcurioRefErrorCLight = "#EF5350";
export const NetcurioRefErrorDAlertText = "#611A15";
export const NetcurioRefErrorEContrast = "#FFFFFF";
export const NetcurioRefSuccessAMain = "#33D53A";
export const NetcurioRefSuccessBDark = "#116D16";
export const NetcurioRefSuccessCLight = "#67E06D";
export const NetcurioRefSuccessDAlertText = "#115515";
export const NetcurioRefSuccessEContrast = "#FFFFFF";
export const NetcurioRefActionCDisabled = "#BDBDBD";
export const ColorBaseBlack = "#000000";
export const ColorBaseWhite = "#ffffff";
export const ColorBaseGrayLight = "#cccccc";
export const ColorBaseGrayMedium = "#999999";
export const ColorBaseGrayDark = "#111111";
export const ColorBaseGrayGranite = "#666666";
export const ColorBaseGraySilver = "#d8d8d8";
export const ColorBaseGrayLynx = "#f7f7f7";
export const ColorBaseGrayFog = "#e6e6e6";
export const ColorBaseGrayPebble = "#333333";
export const ColorBaseGrayNero = "#2b2b2b";
export const ColorBaseGrayAntiFlash = "#f0f0f0";
export const ColorBaseGrayDashed = "#b3b3b3";
export const ColorBaseGrayGrotto = "#eaeaea";
export const ColorBaseRed = "#ff0000";
export const ColorBaseDarkRed = "#9e3333";
export const ColorBaseGreen = "#00ff00";
export const ColorBasePersianGreen = "#00a99d";
export const ColorBasePineGreen = "#007a66";
export const ColorBaseDeepTeal = "#005d56";
export const ColorBaseDarkAqua = "#016e65";
export const ColorBaseVeniceBlue = "#045a89";
export const ColorBaseFunBlue = "#2362a6";
export const ColorBaseAliceBlue = "#f0f6fb";
export const ColorBaseCelestialBlue = "#4c9bd0";
export const ColorBaseMalachite = "#1bc053";
export const ColorBaseSunray = "#e4b05a";
export const ColorBasePaleLavander = "#d8d9fa";
export const ColorBaseReportButtonFilterCreditMemo = "#45766e";
export const ColorBaseReportButtonFilterGoodReceipts = "#4487a9";
export const ColorBaseReportButtonFilterTicket = "#f9a21e";
export const ColorBaseReportButtonFilterPaymentComplement = "#5caa9d";
export const ColorBaseReportButtonFilterRfqList = "#97c5e3";
export const ColorBaseReportButtonFilterPurchaseOrder = "#4c9bd0";
export const ColorBaseReportButtonLineConsumption = "#03324b";
export const ColorBaseReportButtonLineCreditMemo = "#00372e";
export const ColorBaseReportButtonLineGoodReceipt = "#03324b";
export const ColorBaseReportButtonLineTicket = "#eca12e";
export const ColorBaseReportButtonLineRfcm = "#00726a";
export const ColorBaseReportButtonLineInvoices = "#007a6e";
export const ColorBaseReportButtonLineRfq = "#335a74";
export const ColorBaseReportButtonLineAdministration = "#328593";
export const ColorBaseReportButtonLinePaymentComplement = "#007a66";
export const ColorBaseReportButtonLinePurchaseOrder = "#045a89";
export const ColorBaseReportButtonDefault = "#45979d";
export const ColorBaseReportButtonHistoricPurchaseOrder = "#0071BC";
export const ColorBaseReportButtonHistoricInvoices = "#00a99d";
export const ColorBaseReportButtonHistoricPaymentComplement = "#007a66";
export const ColorBaseReportButtonHistoricTickets = "#005d56";
export const ColorBaseReportButtonHistoricCreditMemos = "#016e65";
export const ColorListPending = "#f7e9d2";
export const ColorThemeQuotation = "#5ca4d4";
export const ColorThemeGoodReceipts = "#045a89";
export const ColorThemeConsumption = "#044a71";
export const ColorThemeInvoices = "#00a99d";
export const ColorThemeRequestCreditMemo = "#008b81";
export const ColorThemeCreditMemo = "#005d56";
export const ColorThemePaymentComplement = "#007a66";
export const ColorThemeTicket = "#d27d00";
export const ColorThemeReport = "#45979d";
export const ColorThemeSettings = "#aab4bf";
export const ColorThemeAdministration = "#44b6ca";
export const ColorThemeRfcmHeaderHover = "#45aaa3";
export const ColorThemeInvoicesHeaderHover = "#4cc3ba";
export const ColorThemeGoodsReceiptHeaderHover = "#4887a9";
export const ColorThemeFrequentlyAskQuestion = "#6495ed";
export const ColorThemeFrequentlyAskQuestionHover = "#87cefa";
export const ColorThemeErrorLight = "#eba2a2";
export const ColorThemeErrorBackground = "#fbeded";
export const ColorThemeDisabled = "#f4f4f4";
export const ColorThemeWarning = "#d84646";
export const ColorThemeWarningLight = "#fbecec";
export const ColorThemeSuccess = "#59d181";
export const ColorThemeSuccessHover = "#1bc053";
export const ColorThemeSuccessActive = "#179e44";
export const ColorThemeListsPending = "#f7e9d2";
export const ColorThemeListsHover = "#e6f1f8";
export const ColorFontBase = "#ff0000";
export const ColorFontSecondary = "#00ff00";
export const ColorFontTertiary = "#cccccc";
export const ColorFontDarkBlue = "#000000";
export const SizeBorderRadiusStandard = {"original":"0.313","number":0.313,"decimal":0.00313,"scale":5.008}; // standard border radius for boxes and buttons
export const SizeFontSmall = {"original":"0.75","number":0.75,"decimal":0.0075,"scale":12}; // the small size of the font
export const SizeFontMedium = {"original":"1","number":1,"decimal":0.01,"scale":16}; // the medium size of the font
export const SizeFontLarge = {"original":"2","number":2,"decimal":0.02,"scale":32}; // the large size of the font
export const SizeFontBase = {"original":"1","number":1,"decimal":0.01,"scale":16}; // the base size of the font
export const SizeMarginSmall = {"original":"0.625","number":0.625,"decimal":0.00625,"scale":10}; // small margin for most components
export const SizeHeightAppHeader = {"original":"65px","number":65,"decimal":0.65,"scale":1040}; // Height for header of the app
