import { fillModal } from '../components/dialogModal/fillModal'
import Constants from './constants'

export function errorModal(errorCode, errorMessage) {
	let textQuestion = 'errorCodeText'
	if (errorCode === Constants.USER.USER_BLOCKED) {
		textQuestion = 'errorTextUserBlocked'
	} else if (errorCode === Constants.USER.USER_UNSUBSCRIBED) {
		textQuestion = 'errorTextUserUnsubscribed'
	}
	const errorModal = new fillModal(
		'errors',
		'',
		errorMessage || textQuestion,
		'errorAction',
		'acceptTextButton',
		'',
		textQuestion !== 'errorCodeText' ? '' : 'errorStatus'
	)
	return [errorModal]
}
