import React, { ReactElement } from 'react'
import { Trans, Translation } from 'react-i18next'
import { getSerieFolio, parseTimestampToTimeZone, dateFormatter, URLS } from '@netcurio-ui/common'
import { ITableRow } from './types'
import Formatter from '../../utilities/formatter'
import { NetcurioIconButton, NetcurioTooltip, NetcurioIcons } from '@netcurio-ui/components'
import styles from './styles.module.scss'
import classNames from 'classnames'
import '../../style.scss'

const TableRow = ({ informationTable }: ITableRow): ReactElement => {
	const showIcon = (item) => {
		if (item.invoice === null) {
			return (
				<Translation>
					{(t) => (
						<div>
							<NetcurioTooltip title={t('invoiceNotRelated')} placement={'top'}>
								<NetcurioIconButton color={'warning'} className={styles.invoicesNotRelated}>
									<NetcurioIcons.Error />
								</NetcurioIconButton>
							</NetcurioTooltip>
						</div>
					)}
				</Translation>
			)
		} else {
			return (
				<Translation>
					{(t) => (
						<div>
							<NetcurioTooltip title={t('goToInvoice')} placement={'top'}>
								<NetcurioIconButton
									color={'primary'}
									className={styles.invoicesRelated}
									onClick={() => onClick(item.uuid)}
								>
									<NetcurioIcons.ArrowForward />
								</NetcurioIconButton>
							</NetcurioTooltip>
						</div>
					)}
				</Translation>
			)
		}
	}

	const onClick = (uuid) => {
		location.href = URLS.INVOICE_DETAIL + '?invoice=' + uuid
	}

	const showSerieFolio = (serie, folio, uuid) => {
		if (getSerieFolio(serie, folio) === 'NA') {
			return <div>{uuid}</div>
		} else {
			return (
				<div>
					<p className={styles.complementAmountsRelatedInvoices}>{getSerieFolio(serie, folio)}</p>
				</div>
			)
		}
	}

	const getItems = () => {
		const itemsTable = informationTable.items
		return (
			<div className={styles.itemsTable}>
				{Object.keys(itemsTable).map((key) => (
					<div className={styles.eachComplementPosition} key={key}>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.bigHeaderComplement,
								styles.relatedInvoicesBorder
							)}
						>
							<div className={styles.complementAmountsRelatedInvoices}>
								{itemsTable[key].invoice !== null
									? showSerieFolio(
											itemsTable[key].invoice.serie,
											itemsTable[key].invoice.folio,
											itemsTable[key].invoice.uuid
									  )
									: itemsTable[key].uuid}
							</div>
						</div>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.smallHeaderComplement,
								styles.amountsStyles
							)}
						>
							<div
								className={classNames(
									styles.complementAmountsRelatedInvoices,
									styles.borderNone
								)}
							>
								{requiredPreviousBalance(itemsTable[key].previous_balance)}
							</div>
						</div>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.smallHeaderComplement,
								styles.amountsStyles
							)}
						>
							<div
								className={classNames(
									styles.complementAmountsRelatedInvoices,
									styles.bordernone
								)}
							>
								{requiredAmount(itemsTable[key].paid_amount)}
							</div>
						</div>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.smallHeaderComplement,
								styles.amountsStyles
							)}
						>
							<div
								className={classNames(
									(styles.complementAmountsRelatedInvoices, styles.bordernone)
								)}
							>
								{requiredPendingBalance(itemsTable[key].pending_balance)}
							</div>
						</div>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.iconHeaderComplement
							)}
						>
							<div
								className={classNames(
									styles.complementAmountsRelatedInvoices,
									styles.bordernone
								)}
							>
								{showIcon(itemsTable[key])}
							</div>
						</div>
					</div>
				))}
			</div>
		)
	}

	const requiredPreviousBalance = (previous_balance) => {
		if (previous_balance === null) {
			return (
				<Trans>
					<p>NA</p>
				</Trans>
			)
		} else {
			return <p>{Formatter.currency.format(previous_balance)}</p>
		}
	}

	const requiredAmount = (amount) => {
		if (amount === null) {
			return (
				<Trans>
					<p>NA</p>
				</Trans>
			)
		} else {
			return <p>{Formatter.currency.format(amount)}</p>
		}
	}

	const requiredPendingBalance = (pending_balance) => {
		if (pending_balance === null) {
			return (
				<Trans>
					<p>NA</p>
				</Trans>
			)
		} else {
			return <p>{Formatter.currency.format(pending_balance)}</p>
		}
	}

	const itemsTable = informationTable.items
	const { position, date, amount, currency } = informationTable
	let rowSize
	let lineLeft
	let showInfo
	if (itemsTable.length < 3) {
		rowSize = styles.rowSize
		lineLeft = styles.lineSmallLeft
		showInfo = styles.sizeInfoSmall
	} else {
		rowSize = ''
		lineLeft = styles.lineLeft
		showInfo = styles.textInLine
	}
	return (
		<Translation>
			{(t) => (
				<div className={styles.tableRow}>
					<div className={styles.containerColumnComplementDetail + ' ' + rowSize}>
						<p
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.miniSizeComplement
							)}
						>
							{position}
						</p>
						<div
							className={classNames(
								styles.textColumnMainTableComplementDetail,
								styles.bigHeaderComplement,
								styles.showInfo
							)}
						>
							<div className={styles.textInLine + ' ' + showInfo}>
								<div className={styles.complementInfoText}>{t('dateComplement')}</div>
								<div className={styles.complementInfo}>
									{dateFormatter.format(parseTimestampToTimeZone(date))}
								</div>
							</div>
							<div className={styles.textInLine + ' ' + showInfo}>
								<div className={styles.complementInfoText}>{t('amountComplement')}</div>
								<div className={styles.complementInfo}>
									{Formatter.currency.format(amount)}
								</div>
							</div>
							<div className={styles.textInLine + ' ' + showInfo}>
								<div className={styles.complementInfoText}>{t('currencyComplement')}</div>
								<div className={styles.complementInfo}>{currency}</div>
							</div>
						</div>
						<div className={styles.lineLeft + ' ' + lineLeft} />
						{getItems()}
					</div>
				</div>
			)}
		</Translation>
	)
}

export default TableRow
