import React, { ReactElement } from 'react'
import { Translation } from 'react-i18next'
import { getCurrentUser, parseTimestampToTimeZone, dateFormatterLong } from '@netcurio-ui/common'
import styles from './styles.module.scss'
import classNames from 'classnames'

const HeaderInformation = (): ReactElement => {
	const currentUser = getCurrentUser()

	const formatAddress = () => {
		const company = currentUser.company
		return [
			company.address_line_1,
			company.address_line_2,
			company.postal_code,
			company.state_description,
			company.country_description
		]
			.filter(Boolean)
			.join(', ')
	}
	return (
		<Translation>
			{(t) => (
				<>
					<div className={classNames(styles.grayColorTextGeneralInfo, styles.titleSection)}>
						{t('informationUserCompany')}
					</div>
					<div className={classNames(styles.alignModulesNewTicket, styles.marginBottom20)}>
						<div className={styles.moduleNewTicket}>
							<div className={styles.titleFieldsNewTicket}>{t('createByText')}</div>
							<div className={styles.onlyReadFieldsTicket}>
								{currentUser.name + ' ' + currentUser.lastname}
							</div>
						</div>
						<div className={styles.moduleNewTicket}>
							<div className={styles.titleFieldsNewTicket}>{t('created_at_PO')}</div>
							<div className={styles.onlyReadFieldsTicket}>
								{dateFormatterLong.format(parseTimestampToTimeZone(new Date()))}
							</div>
						</div>
						<div className={styles.moduleNewTicket}>
							<div className={styles.titleFieldsNewTicket}>{t('companyNameText')}</div>
							<div className={styles.onlyReadFieldsTicket}>{currentUser.company.name}</div>
						</div>
						<div className={styles.moduleNewTicket}>
							<div className={styles.titleFieldsNewTicket}>{t('rfc')}</div>
							<div className={styles.onlyReadFieldsTicket}>{currentUser.company.rfc}</div>
						</div>
					</div>
					<div className={classNames(styles.alignModulesNewTicket, styles.marginBottom20)}>
						<div className={styles.moduleNewTicket}>
							<div className={styles.titleFieldsNewTicket}>{t('addressInputNewCompany')}</div>
							<div className={styles.onlyReadFieldsTicket}>{formatAddress()}</div>
						</div>
						<div className={styles.moduleNewTicket}></div>
					</div>
				</>
			)}
		</Translation>
	)
}

export default HeaderInformation
