import React, { FC } from 'react'
import { parseTimestampToTimeZone, dateFormatter, URLS } from '@netcurio-ui/common'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { TableRowProp } from './types'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'

export const TableRow: FC<TableRowProp> = ({
	dataUser: { email, name, lastname, status, created_at, created_by, is_admin }
}) => {
	return (
		<Link
			className={`${styles.rowContainerAdmin} ${styles.rowUsers}`}
			to={`${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.USER_TAB}/${email
				.replace('@', '%40')
				.replaceAll('.', '&')}`}
		>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowMediumSize}`}>{email}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowSmallSize}`}>
				{t(is_admin ? Constants.USER_TYPES.ADMINISTRATOR : Constants.USER_TYPES.STANDARD)}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowMediumSize}`}>
				{name !== null && name.length > 0 && lastname.length > 0
					? name + ' ' + lastname
					: t('pendingText')}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowxSmallSize}`}>{status.value}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowxSmallSize}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.tableRowMediumSize}`}>
				{created_by ? created_by.name + ' ' + created_by.lastname : ''}
			</p>
		</Link>
	)
}
