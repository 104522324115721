import { getSerieFolio } from '@netcurio-ui/common'
import { NetcurioIcons } from '@netcurio-ui/components'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../../components/ConditionalRender/ConditionalRender'
import { Invoice } from '../../../../types'
import styles from './InvoiceInfoBar.module.scss'

interface InvoiceInfoBarProps {
	invoice: Invoice
	getManualAuthorizationSummary?: () => void
	mode?: 'edit' | 'view'
}

export const InvoiceInfoBar = ({
	invoice,
	getManualAuthorizationSummary,
	mode = 'view'
}: InvoiceInfoBarProps) => {
	const { t } = useTranslation()
	return (
		<div className={classNames(styles.container)}>
			<span>{`${t('invoiceText')}`}</span>
			<span>{`${getSerieFolio(invoice.serie, invoice.folio)}`}</span>
			<ConditionalRender condition={Boolean(invoice.override_by) || invoice.advance}>
				<div className={classNames(styles.rightContentContainer)}>
					<ConditionalRender condition={mode === 'view'}>
						<ConditionalRender condition={Boolean(invoice.override_by)}>
							<div
								onClick={() => getManualAuthorizationSummary?.()}
								className={styles.manualAuthorizationLink}
							>
								<span>{t('manuallyProcessed')}</span>
								<NetcurioIcons.InfoOutlined />
							</div>
						</ConditionalRender>
						<ConditionalRender condition={Boolean(invoice.override_by) && invoice.advance}>
							<div className={classNames(styles.separator)}>-</div>
						</ConditionalRender>
					</ConditionalRender>
					<ConditionalRender condition={Boolean(invoice.advance)}>
						<div>{t('advance')}</div>
					</ConditionalRender>
				</div>
			</ConditionalRender>
		</div>
	)
}
