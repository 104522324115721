import { InputBaseProps } from '@mui/material'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { SxProps, Theme, ThemeOptions } from '@mui/material/styles'
import {
	ColorBaseWhite,
	MdRefTypefaceFont,
	NetcurioRefActionCDisabled,
	NetcurioRefPrimaryBDark,
	NetcurioRefPrimaryCLight,
	NetcurioRefWarningCLight
} from '@netcurio-ui/design-tokens/dist/_variables'
import React, { ReactNode, forwardRef, useEffect, useState } from 'react'

const heights = {
	xl: {
		standard: '9.8rem',
		outlined: '10.6rem',
		filled: '10.6rem'
	},
	medium: {
		standard: '4.8rem',
		outlined: '5.6rem',
		filled: '5.6rem'
	},
	small: {
		standard: '4.5rem',
		outlined: '4rem',
		filled: '4.8rem'
	},
	smaller: {
		standard: '4rem',
		outlined: '3.2rem',
		filled: '4.2rem'
	}
}

export const NetcurioTextFieldThemeOptions: ThemeOptions = {
	components: {
		MuiInputBase: {
			styleOverrides: {
				formControl: {
					backgroundColor: ColorBaseWhite,
					borderColor: NetcurioRefPrimaryCLight
				},
				input: {
					fontFamily: MdRefTypefaceFont,
					borderColor: NetcurioRefPrimaryCLight
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontFamily: MdRefTypefaceFont
				}
			}
		}
	}
}

export interface NetcurioTextFieldProps extends Partial<AutocompleteRenderInputParams> {
	id?: string
	autoFocus?: boolean
	disabled?: boolean
	error?: boolean
	fullWidth?: boolean
	helperText?: ReactNode
	label?: React.ReactNode
	multiline?: boolean
	placeholder?: string
	rows?: string | number
	maxRows?: string | number
	minRows?: string | number
	value?: unknown
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	type?: 'email' | 'password'
	adornment?: ReactNode
	adornmentPosition?: 'end' | 'start'
	hiddenLabel?: boolean
	inputProps?: InputBaseProps['inputProps']
	warning?: boolean
	maxLength?: number
	minLength?: number
	variant?: 'standard' | 'filled' | 'outlined'
	height?: 'small' | 'medium' | 'smaller' | 'xl'
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

/**
 * NetcurioTextField
 * @param id <string>: The id of the `input` element.
 * Use this prop to make `label` and `helperText` accessible for screen readers.
 * @param autoFocus <boolean>: If `true`, the `input` element is focused during the first mount.
 * @default false
 * @param disabled <boolean>: If `true`, the component is disabled.
 * @default false
 * @param error <boolean>: If `true`, the label is displayed in an error state.
 * @default false
 * @param fullWidth <boolean>: If `true`, the input will take up the full width of its container.
 * @default false
 * @param helperText <ReactNode>: The helper text content. Also useful in validation.
 * @param label <ReactNode>: The label content.
 * @param multiline <boolean>: If `true`, a `textarea` element is rendered instead of an input.
 * @default false
 * @param placeholder <string>: The short hint displayed in the `input` before the user enters a value.
 * @param rows <string | number>: Number of rows to display when multiline option is set to true.
 * @param maxRows <string | number>: Maximum number of rows to display when multiline option is set to true.
 * @param minRows <string | number>: Minimum number of rows to display when multiline option is set to true.
 * @param value <unknown>: The value of the `input` element, required for a controlled component.
 * @param onChange <(event: React.ChangeEvent<HTMLInputElement>) => void>: Callback fired when the value is changed.
 * @param type <undefined | 'password'>: Set to 'password' to use a password field
 * @param variant: <'standard' | 'filled' | 'outlined'>: The variant to use. @default 'outlined'
 * @param height: <'smallest' | 'smaller' | 'small' | 'medium'>: The height of the component.
 * @returns component
 */
export const NetcurioTextField = forwardRef<HTMLDivElement, NetcurioTextFieldProps>((props, ref) => {
	const {
		variant = 'outlined',
		height = 'smaller',
		size = 'small',
		adornment,
		adornmentPosition,
		inputProps,
		warning,
		maxLength,
		minLength,
		onBlur,
		onFocus,
		onKeyDown,
		...rest
	} = props
	const [sxValue, setSxValue] = useState<SxProps<Theme>>({})

	useEffect(() => {
		if (warning) {
			setSxValue({
				'& .MuiInputBase-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefWarningCLight
				},
				'& .MuiTextField-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				'& .MuiInputBase-input': {
					maxHeight: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefActionCDisabled,
					marginTop: '0rem'
				},
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					},
					'&:hover fieldset': {
						borderColor: NetcurioRefWarningCLight
					},
					'&.Mui-focused fieldset': {
						borderColor: NetcurioRefWarningCLight
					},
					'&.Mui-focused:after': {
						borderBottomColor: NetcurioRefWarningCLight
					},
					'&.Mui-error:after': {
						borderBottomColor: NetcurioRefWarningCLight
					}
				},
				'& .MuiStandardInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				'& .MuiFilledInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderBottom: '1px solid transparent',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				/* Font Sizes */
				'& .MuiOutlinedInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				'& .MuiStandardInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				'& .MuiFilledInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				/* Labels Section */
				'& .MuiFormLabel-root': {
					zIndex: 1,
					position: 'absolute',
					marginTop: '-0.6rem'
				},
				'& .MuiInputLabel-outlined': {
					top: '-0.2rem'
				},
				'& .MuiInputLabel-standard': {
					top: '0rem'
				},
				'& .MuiInputLabel-filled': {
					top: '-1.2rem'
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.4rem',
					marginLeft: '0.2rem'
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				}
			})
		} else {
			setSxValue({
				'& .MuiInputBase-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefPrimaryCLight
				},
				'& .MuiTextField-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				'& .MuiInputBase-input': {
					maxHeight: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefActionCDisabled,
					marginTop: '0rem'
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem',
						borderColor: NetcurioRefPrimaryCLight
					},
					'&:hover fieldset': {
						borderColor: NetcurioRefPrimaryBDark
					}
				},
				'& .MuiStandardInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				'& .MuiFilledInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderBottom: '1px solid transparent',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				/* Font Sizes */
				'& .MuiOutlinedInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				'& .MuiStandardInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				'& .MuiFilledInput-input': {
					fontSize: height == 'smaller' ? '1.4rem' : '1.6rem'
				},
				/* Labels Section */
				'& .MuiFormLabel-root': {
					zIndex: 1,
					position: 'absolute',
					marginTop: '-0.6rem'
				},
				'& .MuiInputLabel-outlined': {
					top: '-0.2rem'
				},
				'& .MuiInputLabel-standard': {
					top: '0rem'
				},
				'& .MuiInputLabel-filled': {
					top: '-1.2rem'
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.4rem',
					marginLeft: '0.2rem'
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				}
			})
		}
	}, [warning])

	return (
		<TextField
			{...rest}
			ref={ref}
			inputProps={
				inputProps ?? {
					minLength: minLength,
					maxLength: maxLength,
					style: {
						fontFamily: MdRefTypefaceFont,
						paddingTop: height == 'xl' ? '2.4rem' : '0.6rem',
						paddingBottom: '0.6rem',
						paddingRight: '1.2rem',
						paddingLeft: '1.2rem'
					}
				}
			}
			sx={sxValue}
			variant={variant}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			{...(adornment
				? {
						InputProps: {
							...rest.InputProps,
							...(adornmentPosition === 'start'
								? {
										startAdornment: (
											<>
												<InputAdornment position="start">{adornment}</InputAdornment>
												{rest.InputProps.startAdornment}
											</>
										)
									}
								: {
										endAdornment: (
											<>
												<InputAdornment position="end">{adornment}</InputAdornment>
												{rest.InputProps?.endAdornment}
											</>
										)
									})
						}
					}
				: {})}
		/>
	)
})

NetcurioTextField.displayName = 'NetcurioTextField'
