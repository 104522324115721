import React from 'react'
import DialogModal from './dialogModal'
import ErrorModal from './errorModal'
import ExpiredSessionModal from './expiredSessionModal'

interface MainModalProps {
	expiredSessionModal?: boolean
	errorModalShow?: boolean
	fillModal?: Array<{
		getActionModal: () => string
		getActionAccept: () => string
		getTextCancel: () => string
		getTextAction: () => string
		getQuestion: () => string
		getTitle: () => string
	}>
	errorCode?: string
	errorByActionClient?: boolean
	unit?: string
	acceptActionModal?: () => void
	hideModal?: () => void
	timeOutToken?: number
	setReason?: unknown
}

const defaultProps: MainModalProps = {
	expiredSessionModal: false,
	errorModalShow: false,
	fillModal: [],
	errorCode: '',
	errorByActionClient: false,
	unit: '',
	acceptActionModal: () => console.warn('acceptActionModal is missing on MainModal'),
	hideModal: () => console.warn('hideModal is missing on MainModal'),
	timeOutToken: 0,
	setReason: null
}

const MainModal = ({
	expiredSessionModal,
	errorModalShow,
	fillModal,
	errorCode,
	errorByActionClient,
	acceptActionModal,
	hideModal,
	timeOutToken,
	setReason
}: MainModalProps = defaultProps) => {
	const showModal = () => {
		if (expiredSessionModal) {
			return (
				<ExpiredSessionModal
					fillModal={fillModal}
					acceptActionModal={acceptActionModal}
					hideModal={hideModal}
					timeOutToken={timeOutToken}
				/>
			)
		}
		if (errorModalShow) {
			return (
				<ErrorModal
					fillModal={fillModal}
					acceptActionModal={acceptActionModal}
					hideModal={hideModal}
					errorCode={errorCode}
					errorByActionClient={errorByActionClient}
				/>
			)
		}

		return (
			<DialogModal
				fillModal={fillModal}
				acceptActionModal={acceptActionModal}
				hideModal={hideModal}
				setReason={setReason}
			/>
		)
	}

	return (
		<div id="modal" className={`modal-position displayFlex`}>
			{showModal()}
		</div>
	)
}

export default MainModal
