import { Company, Status, URLS, User, getCurrentUser, setCurrentUser } from '@netcurio-ui/common'
import { ApolloQueryResult } from 'apollo-boost'
import { Auth } from 'aws-amplify'
import i18next from 'i18next'
import { LOGIN_USER } from '../views/login/queries'
import { beforeUnloadListener } from './beforeUnloadListener'
import { connection } from './connection'
import Constants from './constants'
import UserInformation from './userInformation'

interface GetErrorCodeModalProps {
	errorCode: string
	errorMessage?: string
}
interface GetErrorCodeModal {
	isUserBlocked: boolean
	title?: string
	message: string
	idCode: string | undefined
}

const errorTexts: Record<string, string> = {
	[Constants.USER.USER_BLOCKED]: 'errorTextUserBlocked',
	[Constants.USER.USER_UNSUBSCRIBED]: 'errorTextUserUnsubscribed'
}

export const getErrorCodeModal = ({ errorCode, errorMessage }: GetErrorCodeModalProps): GetErrorCodeModal => {
	const textQuestion: string = errorTexts[errorCode] || 'errorCodeText'
	const graphQLErrorKey = `graphQLErrors.${errorCode}`

	return {
		isUserBlocked: !errorTexts[errorCode],
		title: 'ERROR',
		message: i18next.exists(graphQLErrorKey) ? graphQLErrorKey : errorMessage ?? textQuestion,
		idCode: textQuestion !== 'errorCodeText' ? undefined : errorCode
	}
}

const updateCompaniesUser = () => {
	const client = connection()
	return new Promise(function (resolve) {
		client
			.mutate({
				mutation: LOGIN_USER
			})
			.then((result: ApolloQueryResult<{ loginUser: User }>) => {
				UserInformation.setCurrentUser(result.data.loginUser, undefined)
				resolve(result)
			})
			.catch((error) => {
				console.error(error)
				resolve(error)
			})
	})
}

export const acceptActionErrorModal = (history) => {
	beforeUnloadListener('remove')
	const companiesUnblocked = UserInformation.getCompaniesUnblocked()
	if (companiesUnblocked.length === 1) {
		Auth.signOut()
			.then(() => {
				location.href = URLS.LOGIN
				localStorage.clear()
			})
			.catch((err) => console.error(err))
	} else {
		updateCompaniesUser()
			.then((result: ApolloQueryResult<{ loginUser: User }>) => {
				const companiesUpdated = result.data.loginUser.companies.filter(
					(item: Company) => (item.status as Status).key !== 'BLOCKED'
				)

				const user: User = getCurrentUser()
				if (companiesUpdated.length >= 1) {
					location.replace(URLS.SELECT_COMPANY_AT_LOGIN)
				} else if (companiesUpdated.length === 0) {
					Auth.signOut()
						.then(() => {
							location.href = URLS.LOGIN
							localStorage.clear()
						})
						.catch((err) => console.error(err))
				} else {
					setCurrentUser(user, companiesUpdated[0])
					history.push(
						companiesUpdated[0].homepage
							? URLS[Constants.RELATION_KEY_URL[companiesUpdated[0].homepage]]
							: URLS.PO_LIST
					)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}
}
