export class tableFillHeader {
	constructor(value, secondaryClass, selectedFieldClass, parameterField, parameterFieldClass) {
		this.name = value
		this.secondaryClass = secondaryClass
		this.selectedFieldClass = selectedFieldClass
		this.parameterField = parameterField
		this.parameterFieldClass = parameterFieldClass
	}

	getName() {
		return this.name
	}

	getSelectedFieldClass() {
		return this.selectedFieldClass
	}

	getSecondaryClass() {
		return this.secondaryClass
	}

	getParameterField() {
		return this.parameterField
	}

	getParameterFieldStyle() {
		return this.parameterFieldClass
	}

	setSelectedFieldClass(selectedFieldClass) {
		this.selectedFieldClass = selectedFieldClass
	}
}
