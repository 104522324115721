import React, { useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { NetcurioButton } from '@netcurio-ui/components'
import paymentsComplementsvg from '@netcurio-ui/assets/src/icons/complement.svg'
import { getSerieFolio, parseTimestampToTimeZone, dateFormatter } from '@netcurio-ui/common'
import { PaymentComplement, PaymentComplementShape } from '../../../../types'
import styles from './PaymentComplementInfo.module.scss'
import {
	themePaymentComplementButton,
	themePaymentComplementButtonDisabled
} from '../utilities/defaultValues'

interface PaymentComplementInfo {
	disabled: boolean
	paymentComplementInfo: Array<PaymentComplement>
	setPaymentSelected: Dispatch<SetStateAction<PaymentComplement>>
	onShapeChange: (shape: PaymentComplementShape) => void
}

export function PaymentComplementInfo({
	disabled,
	paymentComplementInfo,
	setPaymentSelected,
	onShapeChange
}: PaymentComplementInfo) {
	const { t } = useTranslation()

	const [shape, setShape] = useState<PaymentComplementShape>(PaymentComplementShape.BUTTON)

	function onButtonClick() {
		setShape((state) => {
			const shape = !disabled ? PaymentComplementShape.LIST : state
			onShapeChange(shape)
			return shape
		})
	}
	function onListHeaderClick() {
		setShape(PaymentComplementShape.BUTTON)
		onShapeChange(PaymentComplementShape.BUTTON)
	}
	function formatDate(date: number) {
		return dateFormatter.format(parseTimestampToTimeZone(date))
	}
	function selectPaymentComplement(complement: PaymentComplement) {
		setPaymentSelected(complement)
	}

	return (
		<>
			{shape === PaymentComplementShape.BUTTON && (
				<NetcurioButton
					sx={!disabled ? themePaymentComplementButton : themePaymentComplementButtonDisabled}
					disabled={disabled}
					onClick={onButtonClick}
					startIcon={
						<img
							src={paymentsComplementsvg}
							className={styles.iconPaymentComplement}
							alt={t('paymentComplementText')}
						/>
					}
					fullWidth
				>
					{t('paymentComplementText')}
				</NetcurioButton>
			)}
			{shape === PaymentComplementShape.LIST && (
				<div className={classNames(styles.container, styles.paymentComplementList)}>
					<header
						className={classNames(styles.headerContainer, styles.headerText)}
						onClick={onListHeaderClick}
					>
						<img
							src={paymentsComplementsvg}
							className={styles.iconPaymentComplement}
							alt={t('paymentComplementText')}
						/>
						<span>{t('paymentComplementText')}</span>
					</header>
					<div className={classNames(styles.paymentComplementListContent, styles.contentContainer)}>
						<p>{t('paymentComplementsRelated')}</p>
						<section className={styles.list}>
							<div className={classNames(styles.listRow, styles.listRowHeader)}>
								<div>{t('serieFolio')}</div>
								<div>{t('dateDocument')}</div>
								<div>{t('upload')}</div>
							</div>
							<div className={styles.listBody}>
								{paymentComplementInfo.map((complement, i) => (
									<div
										key={i}
										className={classNames(styles.listRow, styles.listRowBody)}
										onClick={() => selectPaymentComplement(complement)}
									>
										<div>{getSerieFolio(complement.serie, complement.folio)}</div>
										<div>{formatDate(complement.date)}</div>
										<div>{formatDate(complement.created_at)}</div>
									</div>
								))}
							</div>
						</section>
					</div>
				</div>
			)}
		</>
	)
}
