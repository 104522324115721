import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip } from '@netcurio-ui/components'
import styles from './styles.module.scss'
import classNames from 'classnames'

const HeaderTable = (): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.containerHeaderTable}>
			<div
				className={classNames(
					styles.headerMainTableComplementDetail,
					styles.grayColorTextGeneralInfo
				)}
			>
				<NetcurioTooltip title={t('complementPosition')} placement={'top'}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.miniHeaderComplement
						)}
					>
						{t('positionText')}
					</div>
				</NetcurioTooltip>
				<NetcurioTooltip title={t('complementPaymentInformation')} placement={'top'}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.bigHeaderComplement
						)}
					>
						{t('paymentInfo')}
					</div>
				</NetcurioTooltip>
				<NetcurioTooltip title={t('complementInvoicesIdentifier')} placement={'top'}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.bigHeaderComplement
						)}
					>
						{t('invoicesRelated')}
					</div>
				</NetcurioTooltip>
				<NetcurioTooltip title={t('complementPreviousBalance')} placement={'top'}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.smallHeaderComplement
						)}
					>
						{t('previousBalance')}
					</div>
				</NetcurioTooltip>
				<NetcurioTooltip title={t('complementAmountPaid')} placement={'top'}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.smallHeaderComplement
						)}
					>
						{t('amountPaid')}
					</div>
				</NetcurioTooltip>
				<NetcurioTooltip title={t('complementCurrentBalance')}>
					<div
						className={classNames(
							styles.textHeaderMainTableComplement,
							styles.smallHeaderComplement
						)}
					>
						{t('currentBalance')}
					</div>
				</NetcurioTooltip>
			</div>
		</div>
	)
}

export default HeaderTable
