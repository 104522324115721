import { NetcurioAlert, Severity, TextArea, useNetcurioLoader } from '@netcurio-ui/components'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { getUrlParameter } from '../../../../utilities/getUrlParameter'
import * as queries from '../../queries'
import styles from '../../requestForCreditMemoDetail.module.scss'

/**
 * @param disabledButton <void>: Callback fired if TextArea is empty or not in order to enable / disable the RejectButton
 * @param closeMainModal <void>>: Callback fired when the component requests to be closed.
 * @param rejectCreditMemo <boolean>: If `true`, callback rejectCreditMemoRequest() will activate
 * @param resetBtnValue <void>: Callback fired to reset setRejectRCM state in RejectRCMModal component.
 */
export interface RejectRequestCreditMemoProps {
	disabledButton(stateButton: boolean): void
	rejectCreditMemo: boolean
	closeMainModal: () => void
	emptyServerError: string
	resetBtnValue: () => void
}

export const RejectRequestCreditMemo = ({
	disabledButton,
	rejectCreditMemo,
	closeMainModal,
	emptyServerError,
	resetBtnValue
}: RejectRequestCreditMemoProps) => {
	const [motiveChange, setMotiveChange] = useState<string>('')
	const [emptyTextAreaError, setEmptyTextAreaError] = useState<string>('')
	const [serverErrorMessage, setServerErrorMessage] = useState<string>('warningAction')
	const [disabledRejectButton, setDisabledRejectButton] = useState<boolean>(true)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	useEffect(() => {
		if (rejectCreditMemo) {
			rejectCreditMemoRequest()
			resetBtnValue()
		}
	}, [rejectCreditMemo])

	useEffect(() => {
		disabledButton(disabledRejectButton)
	}, [disabledRejectButton])

	useEffect(() => {
		setServerErrorMessage(emptyServerError)
	}, [emptyServerError])

	const rejectCreditMemoRequest = () => {
		showLoadingSpinner()
		const client = connection()
		const id = getUrlParameter('requestforcreditmemo')
		const customer = getUrlParameter('customer')
		client
			.mutate({
				mutation: queries.REJECT_CREDIT_MEMO_REQUEST,
				variables: {
					id,
					customer,
					rejected_reason: motiveChange
				}
			})
			.then(() => {
				setServerErrorMessage('warningAction')
				hideLoadingSpinner()
				closeMainModal()
				location.reload()
			})
			.catch((error) => {
				console.error(error)
				const idCode = showErrorComponent(error)
				if (!expiredToken(idCode)) {
					setServerErrorMessage(t('errorCodeText', { idCode }))
				}
				hideLoadingSpinner()
			})
	}

	const changeMotive = (motive: string) => {
		if (motive !== '') {
			setDisabledRejectButton(false)
			setMotiveChange(motive)
			setEmptyTextAreaError('')
		} else {
			setDisabledRejectButton(true)
			setMotiveChange('')
			setEmptyTextAreaError('emptyFieldError')
		}
	}

	return (
		<div>
			<NetcurioAlert
				severity={serverErrorMessage === 'warningAction' ? Severity.Warning : Severity.Error}
			>
				{t(serverErrorMessage)}
			</NetcurioAlert>
			<div className={styles.containerTextarea}>
				<TextArea
					maxLength={50}
					rows={3}
					placeholder={t('placeHolderRejectReason')}
					readValue={motiveChange}
					onChangeText={(motive) => changeMotive(motive)}
					errorMessage={motiveChange !== '' ? '' : emptyTextAreaError}
				/>
			</div>
		</div>
	)
}
