import React from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio-ui/components'

interface AlertClosedRFQModalProps {
	open: boolean
	accept: () => void
	title?: string
	bodyText: string
	textButton: string
	showInput?: boolean
	headerTitleSeverity?: Severity
}

/**
 * AlertClosedRFQModal
 * @description Helps to display a message when user try to save the changes to ticket permissions
 * @param open <boolean>: tells if the dialog is open or not
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @param title <string>: is a text that describe the tittle of modal
 * @param bodyText <string>: is a text that text of body of modal
 * @param textButton <string>: is a text that describe the action of the button
 * @param headerTitleSeverity <Severity>: is a type of message on the modal
 * @returns Modal component
 */
export const AlertClosedRFQModal = ({
	open,
	accept,
	title,
	bodyText,
	textButton,
	headerTitleSeverity
}: AlertClosedRFQModalProps) => {
	return (
		<NetcurioDialog
			open={open}
			titleText={title}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<NetcurioButton variant="contained" onClick={accept}>
					{textButton}
				</NetcurioButton>
			}
		>
			<p>{bodyText}</p>
		</NetcurioDialog>
	)
}
