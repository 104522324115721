import React, { useState } from 'react'
import { t } from 'i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import { AcceptRequestCreditMemo } from './AcceptRequestCreditMemo'

/**
 * @param openModal <boolean>: If `true`, the component is shown.
 * @param closeModal <void>: Callback fired when the component requests to be closed.
 */
export interface AcceptRequestCreditMemoModalProps {
	openModal: boolean
	closeModal: () => void
}
export const AcceptRequestCreditMemoModal = ({
	openModal,
	closeModal
}: AcceptRequestCreditMemoModalProps) => {
	const [accept, setAccept] = useState<boolean>(false)
	const [emptyServerError, setEmptyServerError] = useState<string>('')

	const resetModal = () => {
		closeModal()
		setEmptyServerError('')
	}

	return (
		<>
			<NetcurioDialog
				titleText={t('titleCreditMemoDetailsAccept')}
				open={openModal}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={resetModal}>
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={() => setAccept(true)}>
							{t('acceptTextButton')}
						</NetcurioButton>
					</>
				}
			>
				<AcceptRequestCreditMemo
					closeMainModal={closeModal}
					acceptedRequest={accept}
					emptyServerError={emptyServerError}
					resetBtnValue={() => setAccept(false)}
				/>
			</NetcurioDialog>
		</>
	)
}
