import { Severity } from '@netcurio-ui/components'

export enum TypesOfStatusMessagesPurchaseOrderDetail {
	Default = 'Default'
}

interface MessageProperties {
	message: string
	variant?: Severity
}

interface Message {
	[key: string]: MessageProperties
}

export const purchaseOrderDetialAlertMessages: Message = {
	Default: {
		message: ''
	}
}
