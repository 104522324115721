export class fillModal {
	constructor(actionModal, textTitle, textQuestion, actionAccept, textAction, textCancel, contentTitle) {
		this.actionModal = actionModal
		this.textTitle = textTitle
		this.textQuestion = textQuestion
		this.actionAccept = actionAccept
		this.textAction = textAction
		this.textCancel = textCancel
		this.contentTitle = contentTitle
	}

	getActionModal() {
		return this.actionModal
	}

	getTitle() {
		return this.textTitle
	}

	getQuestion() {
		return this.textQuestion
	}

	getActionAccept() {
		return this.actionAccept
	}

	getTextAction() {
		return this.textAction
	}

	getTextCancel() {
		return this.textCancel
	}

	getContentTitle() {
		return this.contentTitle
	}
}
