import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { TextArea, NetcurioTextField, NetcurioAutocomplete } from '@netcurio-ui/components'
import { Roles } from '@netcurio-ui/common'
import { Categories, IHeaderInformationProps, Receiver } from './types'
import { useDebounce } from '../../utilities/useDebounce'
import { connection } from '../../utilities/connection'
import HeaderInformation from './headerInformation'
import UserInformation from '../../utilities/userInformation'
import GlobalQuerys from '../../components/queries'
import * as queries from './queries'

import styles from './styles.module.scss'
import classNames from 'classnames'

const HeaderTicket = ({
	dataHeader,
	setDataHeader,
	handleError,
	description,
	setDescription,
	changeReceiver,
	verifyReceiverReset,
	setVerifyReceiverReset
}: IHeaderInformationProps): ReactElement => {
	const userRole: string = UserInformation.getCompanyRole()
	const isSupplier: boolean = userRole === Roles.SUPPLIER
	const receiverText: string = userRole === Roles.SUPPLIER ? 'customerText' : 'supplierText'
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])

	const { t } = useTranslation()

	const [suggestionReceiver, setSuggestionReceiver] = useState<Array<Receiver>>([])
	const [suggestionSelected, setSuggestionSelected] = useState<Receiver | null>(null)
	const [suggestionInput, setSuggestionInput] = useState('')
	const [suggestionListLoading, setSuggestionListLoading] = useState(false)
	const [suggestionCategory, setSuggestionCategory] = useState<Array<Categories>>([])
	const [categorySelected, setCategorySelected] = useState<Categories | null>(null)
	const [categoryListLoading, setCategoryListLoading] = useState(false)

	const debouncedSuggestionInput = useDebounce<string>(suggestionInput, 500)

	useEffect(() => {
		if (verifyReceiverReset) {
			setSuggestionSelected(null)
			setCategorySelected(null)
			setVerifyReceiverReset(false)
		}
	}, [setVerifyReceiverReset, verifyReceiverReset])

	useEffect(() => {
		if (debouncedSuggestionInput.length >= 3) {
			setSuggestionListLoading(true)
			client
				.query({
					query: isSupplier
						? GlobalQuerys.CUSTOMERS_BY_NAME_OR_CODE
						: GlobalQuerys.SUPPLIERS_BY_NAME_OR_CODE,
					variables: { search_text: debouncedSuggestionInput.toLowerCase() }
				})
				.then((result) => {
					if (result.data) {
						let results = isSupplier
							? result.data.CustomersByNameOrCode
							: result.data.SuppliersByNameOrCode
						results = results.map((item: Receiver) => ({
							name: item.name,
							rfc: item.rfc,
							label: item.rfc + ' - ' + item.name
						}))
						setSuggestionReceiver(results)
					}
				})
				.catch(handleError)
				.finally(() => setSuggestionListLoading(false))
		}
	}, [client, debouncedSuggestionInput, handleError, isSupplier])

	useEffect(() => {
		if (suggestionSelected) {
			setCategoryListLoading(true)
			client
				.query({
					query: queries.TICEKT_CATEGORY,
					variables: { search_text: '' }
				})
				.then((result) => setSuggestionCategory(result.data.TicketCategoriesCreate || []))
				.catch(handleError)
				.finally(() => setCategoryListLoading(false))
		} else {
			setSuggestionCategory([])
		}
	}, [client, suggestionSelected, handleError])

	const selectedReceiver = (receiver: Receiver) => {
		if (receiver !== null) {
			setDataHeader((state) => ({ ...state, receiver }))
			setSuggestionSelected(receiver)
		} else {
			if (!!dataHeader.category.code || !!dataHeader.subject || !!description) {
				changeReceiver()
			} else {
				setDataHeader((state) => ({
					...state,
					receiver: { name: undefined, rfc: undefined }
				}))
				setSuggestionSelected(receiver)
			}
		}
	}

	const selectedCategory = (category: Categories) => {
		setCategorySelected(category)
		if (category !== null) {
			setDataHeader((state) => ({ ...state, category }))
		} else {
			setDataHeader((state) => ({
				...state,
				category: { code: undefined, description: undefined }
			}))
		}
	}

	const getSuggestionLabel = (value: Receiver) => value.label
	const validateSuggestionValue = (option: Receiver, value: Receiver) => option.rfc === value.rfc

	const getCategoryLabel = (value: Categories) => value.description
	const validateCategoryValue = (option: Categories, value: Categories) => option.code === value.code

	return (
		<div className={styles.newTicketContainerHeader}>
			<div className={styles.headerContainerNewTicket}>
				<p className={styles.titleHeaderNewTicket}>{t('titleNewTicket')}</p>
			</div>
			<div className={styles.containerDataNewTicket}>
				<div className={classNames(styles.grayColorTextGeneralInfo, styles.titleSection)}>
					{t(receiverText)}
				</div>
				<div className={classNames(styles.alignModulesNewTicket, styles.marginBottom20)}>
					<div className={styles.moduleNewTicket}>
						<div className={styles.selectorFieldTicket}>
							<NetcurioAutocomplete
								size="small"
								height="smaller"
								placeholder={t('inputCustomerTextNewTicket', {
									role: t(receiverText)
								})}
								variant="outlined"
								options={suggestionReceiver}
								getOptionLabel={getSuggestionLabel}
								isOptionEqualToValue={validateSuggestionValue}
								value={suggestionSelected}
								onSelectValue={selectedReceiver}
								inputValue={suggestionInput}
								onInputValueChange={setSuggestionInput}
								loading={suggestionListLoading}
								minLength={3}
							/>
						</div>
					</div>
					<div className={styles.moduleNewTicket}></div>
				</div>
				<div className={classNames(styles.grayColorTextGeneralInfo, styles.titleSection)}>
					{t('ticketCategory')}
				</div>
				<div className={classNames(styles.alignModulesNewTicket, styles.marginBottom20)}>
					<div className={styles.moduleNewTicket}>
						<div className={styles.selectorFieldTicket}>
							<NetcurioAutocomplete
								size="small"
								height="smaller"
								placeholder={t('categorySelectText')}
								variant="outlined"
								options={suggestionCategory}
								getOptionLabel={getCategoryLabel}
								isOptionEqualToValue={validateCategoryValue}
								value={categorySelected}
								onSelectValue={selectedCategory}
								loading={categoryListLoading}
								disabled={!suggestionSelected}
							/>
						</div>
					</div>
					<div className={styles.moduleNewTicket}></div>
				</div>
				<HeaderInformation />
				<div className={classNames(styles.grayColorTextGeneralInfo, styles.titleSection)}>
					{t('informationTicket')}
				</div>
				<div className={classNames(styles.alignModulesNewTicket, styles.resizeInputSubject)}>
					<div className={styles.moduleNewTicket}>
						<NetcurioTextField
							label={t('subjectText')}
							placeholder={t('reasonForTicketText')}
							maxLength={70}
							disabled={!suggestionSelected}
							value={dataHeader.subject}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setDataHeader({ ...dataHeader, subject: e.target.value })
							}
							error={dataHeader.subject === ''}
						/>
					</div>
				</div>
				<div className={classNames(styles.alignModulesNewTicket, styles.resizeInputSubject)}>
					<TextArea
						label={'textDescription'}
						placeholder={'descriptionSubjectText'}
						readValue={description}
						disabledInput={!suggestionSelected}
						maxLength={500}
						rows={5}
						onChangeText={(value) => setDescription(value)}
						errorMessage={description !== '' ? '' : 'emptyFieldError'}
					/>
				</div>
			</div>
		</div>
	)
}

export default HeaderTicket
