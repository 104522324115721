import { NetcurioButton, NetcurioIcons, useNetcurioLoader } from '@netcurio-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../components/ConditionalRender/ConditionalRender'
import { useClient } from '../../../hooks/useClient'
import { Invoice } from '../../../types'
import { CANCEL_INVOICE } from '../graphql'
import { CancelInvoiceModal } from '../InvoiceDetail/Modals/CancelInvoiceModal/CancelInvoiceModal'

interface CancelInvoiceProps {
	invoice: Invoice
	errorHandler?: (error: Error) => void
	getInvoiceInfo?: () => void
	isModalOpen?: boolean
	onModalClose?(): void
	showButton?: boolean
}

export const CancelInvoice: React.FC<CancelInvoiceProps> = ({
	invoice,
	errorHandler,
	getInvoiceInfo,
	isModalOpen: initShowModalValue = false,
	onModalClose,
	showButton = true
}) => {
	const [isModalVisible, setIsModalVisible] = useState(initShowModalValue)
	const [reasonToCancel, setReasonToCancel] = useState<string>('')
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const client = useClient()

	const hideModal = useCallback(() => {
		setIsModalVisible(false)
		setReasonToCancel('')
		onModalClose?.()
	}, [onModalClose])

	const handleCancelInvoice = useCallback(() => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: CANCEL_INVOICE,
				variables: {
					uuid: invoice.uuid,
					reason: reasonToCancel
				}
			})
			.then(() => {
				hideModal()
				getInvoiceInfo?.()
			})
			.catch(errorHandler)
			.finally(hideLoadingSpinner)
	}, [
		client,
		hideLoadingSpinner,
		hideModal,
		invoice.uuid,
		reasonToCancel,
		showLoadingSpinner,
		errorHandler,
		getInvoiceInfo
	])

	useEffect(() => {
		if (initShowModalValue) {
			setIsModalVisible(initShowModalValue)
		}
	}, [initShowModalValue])

	return (
		<>
			<ConditionalRender condition={showButton}>
				<NetcurioButton
					variant="text"
					endIcon={<NetcurioIcons.Close />}
					size="medium"
					onClick={() => {
						setIsModalVisible(true)
					}}
					color="secondary"
				>
					{t('invoice.cancelInvoice')}
				</NetcurioButton>
			</ConditionalRender>
			<CancelInvoiceModal
				open={isModalVisible}
				onClose={hideModal}
				onAccept={handleCancelInvoice}
				isReasonCancelInvoice
				setReasonToCancel={setReasonToCancel}
				reasonToCancel={reasonToCancel}
				referenceType={invoice?.reference_type}
			/>
		</>
	)
}
