import { getCurrentUser } from '@netcurio-ui/common'
import { getRelationRolesAndStatus } from './relationRolesAndStatus'
import UserInformation from './userInformation'

export function redirectByRoles(urlToGo: string, rolUser: string) {
	if (getCurrentUser()?.company && UserInformation.getCompanyRole() === rolUser) location.href = urlToGo
}

export function redirectByStatus(objectType: string, status: string, paramsToUrl: string) {
	let userRole = UserInformation.getCompanyRole()
	let objectRelationRolesAndStatus = getRelationRolesAndStatus()
	let urlToGo = objectRelationRolesAndStatus[userRole][objectType][status]
	if (window.location.pathname !== urlToGo) location.href = urlToGo + paramsToUrl
}
