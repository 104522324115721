import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
interface ChangeDocumentTypeModalProps {
	onClose(): void
	onAccept(): void
	open: boolean
}

export const ChangeDocumentTypeModal: FC<ChangeDocumentTypeModalProps> = ({
	onClose,
	onAccept,
	open
}): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('changeWorkFlowTitle')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('changeWorkFlowQuestion')}</p>
		</NetcurioDialog>
	)
}
