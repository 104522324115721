import '@netcurio-ui/assets/src/images/netcurio-logo.png'
import { URLS } from '@netcurio-ui/common'
import { ScreenNotFound, ScreenWithoutAccess } from '@netcurio-ui/components'
import React from 'react'
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import '../style.scss'
import { MainViewLoginComponent } from './login/login'
import { PasswordRecovery } from './passwordrecovery/passwordRecovery'
import { PassResetSuccessful } from './passwordrecovery/passwordResetSuccessful/passwordResetSuccessful'
import { ResetPassword } from './passwordrecovery/resetPassword'
import { SelectCompanyAtLogin } from './selectCompanyAtLogin/SelectCompanyAtLogin'
import { SignUp } from './signUp/SignUp'

interface MainViewProps {
	homeView: React.FunctionComponent
}

const MainView = ({ homeView }: MainViewProps) => {
	const screenWithoutAccess = () => (
		<ScreenWithoutAccess useHistory={useHistory} useLocation={useLocation} />
	)
	const screenNotFound = () => <ScreenNotFound useHistory={useHistory} useLocation={useLocation} />
	const registerCompanyComponent = () => <div>Register Company Screen</div>

	const sendComponentMain = () => {
		// eslint-disable-next-line no-undef
		if (window.location.pathname === '/') {
			return (
				<BrowserRouter>
					<Switch>
						<Route path="/" component={MainViewLoginComponent} />
					</Switch>
				</BrowserRouter>
			)
		} else {
			return (
				<BrowserRouter>
					<Switch>
						<Route path={URLS.LOGIN} component={MainViewLoginComponent} />
						<Route path={URLS.PASS_RESET_SUCCESSFUL} component={PassResetSuccessful} />
						<Route path={URLS.REGISTER} component={SignUp} />
						<Route path={URLS.RESET_PASSWORD} component={ResetPassword} />
						<Route path={URLS.REGISTER_COMPANY} component={registerCompanyComponent} />
						<Route path={URLS.SELECT_COMPANY_AT_LOGIN} component={SelectCompanyAtLogin} />
						<Route path={URLS.PO_LIST} component={homeView} />
						<Route path={URLS.PO_EDIT} component={homeView} />
						<Route path={URLS.PO_DETAIL} component={homeView} />
						<Route path={URLS.PO_NEW} component={homeView} />
						<Route path={URLS.INVOICE_LIST} component={homeView} />
						<Route path={URLS.PASSWORD_RECOVERY} component={PasswordRecovery} />
						<Route path={URLS.INVOICE_EDIT} component={homeView} />
						<Route path={URLS.INVOICE_DETAIL} component={homeView} />
						<Route path={URLS.GR_LIST} component={homeView} />
						<Route path={URLS.GR_NEW} component={homeView} />
						<Route path={URLS.GR_DETAIL} component={homeView} />
						<Route path={URLS.COMPLEMENT_LIST} component={homeView} />
						<Route path={URLS.COMPLEMENT_DETAIL} component={homeView} />
						<Route path={URLS.REPORT_LIST} component={homeView} />
						<Route path={URLS.DASHBOARDS} component={homeView} />
						<Route path={URLS.RFQ} component={homeView} />
						<Route path={URLS.RFQ_NEW} component={homeView} />
						<Route path={URLS.RFQ_DETAIL} component={homeView} />
						<Route path={URLS.ADMINISTRATION} component={homeView} />
						<Route path={URLS.WITHOUT_ACCESS} component={screenWithoutAccess} />
						<Route path={URLS.USER_SETTINGS} component={homeView} />
						<Route path={URLS.NOT_FOUND} component={screenNotFound} />
						<Route path={URLS.NEW_PRODUCT} component={homeView} />
						<Route path={URLS.NEW_ENROLLMENT} component={homeView} />
						<Route path={URLS.CONSUMPTION_LIST} component={homeView} />
						<Route path={URLS.TICKET_LIST} component={homeView} />
						<Route path={URLS.CONSUMPTION_DETAIL} component={homeView} />
						<Route path={URLS.NEW_CONSUMPTION} component={homeView} />
						<Route path={URLS.REQUEST_FOR_CREDIT_MEMO_LIST} component={homeView} />
						<Route path={URLS.RFCM_DETAIL} component={homeView} />
						<Route path={URLS.CREDIT_MEMO_LIST} component={homeView} />
						<Route path={URLS.NEW_REQUEST_FOR_CREDIT_MEMO} component={homeView} />
						<Route path={URLS.CREDIT_MEMO_DETAIL} component={homeView} />
						<Route path={URLS.NEW_TICKET} component={homeView} />
						<Route path={URLS.TICKET_DETAIL} component={homeView} />
						<Route path={URLS.INVOICE_LIST_ARCHIVED} component={homeView} />
					</Switch>
				</BrowserRouter>
			)
		}
	}

	return (
		<div>
			<div id="parentModalMainView" />
			<div id="parentModal" />
			{sendComponentMain()}
		</div>
	)
}

export default MainView
