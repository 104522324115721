import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Company, getCompany } from '@netcurio-ui/common'
import Constants from '../../utilities/constants'
import { ReviewButtonsProps } from './types'

export const ReviewButtons = ({ ticketDetail, actionButton }: ReviewButtonsProps): ReactElement => {
	const { t } = useTranslation()

	const userCompany: Company = getCompany()

	if (!ticketDetail) {
		return null
	}

	const { status, can_review, can_create, receiver, sender } = ticketDetail

	const canReview =
		status.key === Constants.TICKET_STATUS.OPEN && can_review && receiver.rfc === userCompany.rfc
	const canReopen =
		status.key === Constants.TICKET_STATUS.CLOSED_TICKET && can_create && sender.rfc === userCompany.rfc
	const canClose =
		[Constants.TICKET_STATUS.OPEN, Constants.TICKET_STATUS.REVIEW].includes(status.key) && can_review

	const reviewOrReopenAction: string = canReview ? 'reviewTicket' : 'reopenTicket'

	return (
		<div className="container-buttons-ticket">
			{(canReview || canReopen) && (
				<button
					className="button-lateral-ticket button-review-ticket"
					onClick={() => actionButton(reviewOrReopenAction)}
				>
					<p className="textButtonReviewTicket">{t(reviewOrReopenAction)}</p>
				</button>
			)}
			{canClose && (
				<button
					className="button-lateral-ticket button-cancel"
					onClick={() => actionButton('closeTicket')}
				>
					<p className="textButtonReviewTicket">{t('closeTicket')}</p>
				</button>
			)}
		</div>
	)
}
