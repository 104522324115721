import { fillModal } from '../components/dialogModal/fillModal'

export function modalCompanyChange() {
	const modalCompanyChange = new fillModal(
		'changeCompany',
		'',
		'alertChangeCompanyTitle',
		'changeAction',
		'acceptTextButton',
		'',
		''
	)
	const objectModal = [modalCompanyChange]
	return objectModal
}
