import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface ApproveEnrollmentProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

/**
 * ApprovedEnrollmentModal
 * @description Helps to display a modal when the user is trying to confirm a PO
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <void>: Callback fired to close modal
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/

export const ApprovedEnrollmentModal: FC<ApproveEnrollmentProps> = ({ onAccept, onClose, open }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('titleApproveEnrollment')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('approveActionText')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionApproveEnrollment')}</p>
		</NetcurioDialog>
	)
}
