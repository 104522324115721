import { NetcurioButton, NetcurioIcons } from '@netcurio-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface BackButtonProps {
	onClick: () => void
	icon?: React.ReactNode
	translationKey?: string
}

export const BackButton = ({
	onClick,
	icon = <NetcurioIcons.ArrowBack />,
	translationKey = 'comeBackText'
}: BackButtonProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioButton variant="outlined" color="error" size="small" onClick={onClick} endIcon={icon}>
			{t(translationKey)}
		</NetcurioButton>
	)
}
