import { t } from 'i18next'
import React, { useState } from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio-ui/components'
import { RejectRequestForQuotation } from './RejectRequestForQuotation'
export interface RejectRequestForQuotationModalProps {
	openModal: boolean
	addNewComment(): void
	onCloseModal(): void
	resetDataPage(): void
}
/**
 * RejectRequestForQuotationModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param openModal <boolean>: If `true`, the component is shown
 * @param addNewComment <void>: Callback fired when the component requests to add a new comment into CommentSection
 * @param closeModal <void>: Callback fired when the modal requests to be closed
 * @param resetDataPage <void>: Callback for reset the page
 * @return component <ReactNode>
 **/
export const RejectRequestForQuotationModal = ({
	openModal,
	addNewComment,
	onCloseModal,
	resetDataPage
}: RejectRequestForQuotationModalProps) => {
	const [reject, setRejectRFQ] = useState<boolean>(false)
	const [serverError, setServerError] = useState<string>('warningAction')

	const resetModal = () => {
		onCloseModal()
		setServerError('warningAction')
	}

	return (
		<>
			<NetcurioDialog
				headerTitleSeverity={serverError === 'warningAction' ? Severity.Warning : Severity.Error}
				titleText={t(serverError)}
				open={openModal}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={resetModal}>
							{t('goBackText')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={() => setRejectRFQ(true)}>
							{t('rejectButtonText')}
						</NetcurioButton>
					</>
				}
			>
				<RejectRequestForQuotation
					rejectRequest={reject}
					addNewComment={addNewComment}
					closeMainModal={onCloseModal}
					sendErrorCode={(error) => {
						setServerError(error)
					}}
					resetBtnValue={() => setRejectRFQ(false)}
					resetDataPage={resetDataPage}
				/>
			</NetcurioDialog>
		</>
	)
}
