import React, { FC, Dispatch, SetStateAction, useState } from 'react'
import styles from '../requestForQuotation.module.scss'
import { useTranslation } from 'react-i18next'
import { Roles, Status } from '@netcurio-ui/common'
import Formatter from '../../../utilities/formatter'
import { FreightAndTerms, NewQuotation } from '../types'
import classNames from 'classnames'
import Constants from '../../../utilities/constants'
import {
	NetcurioButton,
	NetcurioIcons,
	NetcurioDatePicker,
	NetcurioSelect,
	NetcurioMenuItem
} from '@netcurio-ui/components'
import dayjs from 'dayjs'

interface ButtonNewQuotationProps {
	expiredDate: dayjs.Dayjs
	setExpiredDate: Dispatch<SetStateAction<dayjs.Dayjs>>
	settingValidateCatalogue: boolean
	newQuotation: NewQuotation
	setNewQuotation: Dispatch<SetStateAction<NewQuotation>>
	currencies: Array<FreightAndTerms>
	disabledNewQuotationButton: boolean
	validateAllFields(): void
	statusRFQ: Status
	userRole: string
	actionButton(button: string): void
	dateErrorRequired: boolean
	currencyQuotationRequired: boolean
}

export const ButtonNewQuotation: FC<ButtonNewQuotationProps> = ({
	expiredDate,
	setExpiredDate,
	settingValidateCatalogue,
	newQuotation,
	setNewQuotation,
	currencies,
	disabledNewQuotationButton,
	validateAllFields,
	statusRFQ,
	userRole,
	actionButton,
	dateErrorRequired,
	currencyQuotationRequired
}) => {
	const minDate = dayjs()
	const { t } = useTranslation()
	const isSupplier: boolean = userRole === Roles.SUPPLIER
	const showRejectButton: boolean = statusRFQ && statusRFQ.key === Constants.STATUS.NEW

	const handleOnChangeDate = (date: dayjs.Dayjs) => {
		setExpiredDate(date)
	}

	const handleOnChangeCurrency = (currency: any) => {
		setNewQuotation((prevState) => ({
			...prevState,
			currency: currency.target.value
		}))
	}

	return (
		<div className={styles.containerSelectExpirationDate}>
			<div className={styles.titleContainerSelectExpirationDate}>
				<p className={styles.titleSectionNewRFQ}>{t('expiredDateTitle')}</p>
			</div>
			<div className={styles.containerSearchExpirationDate}>
				<div className={styles.divSearchExpirationDate}>
					<NetcurioDatePicker
						height="smaller"
						className={classNames(styles.widthContainerExpiredDate, {
							[styles.borderError]: dateErrorRequired
						})}
						label={t('requiredDateText')}
						format="DD/MM/YY"
						value={expiredDate}
						onChange={handleOnChangeDate}
						minDate={minDate}
					/>
				</div>
			</div>
			<div className={styles.totalContainerNewRFQ}>
				<div className={styles.headerContainerTotalRFQ}>
					<p className={styles.titleSectionNewRFQ}>{t('total_PO')}</p>
				</div>
				<div className={styles.totalContainerValuesRFQ}>
					<div className={styles.containerTotalText}>
						<p className={styles.totalTextRFQ}>{t('currencyQuotationDetail')}</p>
					</div>
					{settingValidateCatalogue ? (
						<p className={styles.totalValueRFQ}>{newQuotation?.currency}</p>
					) : (
						newQuotation?.netTotal > 0 && (
							<div className={styles.containerCurrencyRFQ}>
								<NetcurioSelect
									fullWidth
									variant="outlined"
									size="medium"
									value={newQuotation?.currency}
									onChange={(event) => handleOnChangeCurrency(event)}
									height={'smaller'}
									error={currencyQuotationRequired}
								>
									{currencies.map((currency, i) => (
										<NetcurioMenuItem key={i} value={currency.code}>
											<span>{currency.code}</span>
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</div>
						)
					)}
				</div>

				<div className={classNames(styles.totalContainerValuesRFQ, styles.totalContainerLineRFQ)}>
					<p className={styles.totalTextRFQ}>{t('totalText')}</p>
					<p className={styles.totalValueRFQ}>
						{Formatter.currency.format(newQuotation?.netTotal)}
					</p>
				</div>
			</div>
			<div className={styles.buttonsPositon}>
				<NetcurioButton
					variant="outlined"
					color="primary"
					onClick={validateAllFields}
					endIcon={<NetcurioIcons.Check />}
					disabled={disabledNewQuotationButton}
				>
					{t('sendQuotationText')}
				</NetcurioButton>
				{isSupplier && showRejectButton && (
					<NetcurioButton
						variant="text"
						color="secondary"
						onClick={() => actionButton('rejectRFQ')}
						endIcon={<NetcurioIcons.HighlightOff />}
					>
						{t('rejectRequestText')}
					</NetcurioButton>
				)}
			</div>
		</div>
	)
}
