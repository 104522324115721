import React, { ReactElement, useCallback } from 'react'
import { Translation } from 'react-i18next'
import {
	TrackInformation,
	StatusInformation,
	closedStatus,
	openStatus,
	reviewStatus,
	statusLoading
} from '@netcurio-ui/components'
import { getCompany, parseTimestampToTimeZone, Company, dateFormatter, Roles } from '@netcurio-ui/common'
import DocumentSection from './documentSection'
import Constants from '../../utilities/constants'
import UserInformation from '../../utilities/userInformation'
import { InformationContainerProps } from './types'

const InformationContainer = ({
	ticketDetail,
	openModalPDF,
	additionalFile,
	setAdditionalFile,
	addRevisionDocument,
	sender
}: InformationContainerProps): ReactElement => {
	const userRole: string = UserInformation.getCompanyRole()
	const userCompany: Company = getCompany()
	const isSupplier: boolean = userRole === Roles.SUPPLIER
	const trackInformationHeight = 565

	const mapTrackInformationData = useCallback(() => {
		const dataHeader = {
			creation_date: dateFormatter.format(parseTimestampToTimeZone(ticketDetail?.created_at)),
			category: ticketDetail?.category.description,
			subject: ticketDetail?.subject,
			customer: undefined,
			customer_rfc: undefined,
			supplier: undefined,
			supplier_rfc: undefined
		}
		if (sender === userCompany.rfc) {
			if (isSupplier) {
				dataHeader.customer = ticketDetail?.receiver.name
				dataHeader.customer_rfc = ticketDetail?.receiver.rfc
				delete dataHeader.supplier
				delete dataHeader.supplier_rfc
			} else {
				dataHeader.supplier = ticketDetail?.receiver.name
				dataHeader.supplier_rfc = ticketDetail?.receiver.rfc
				delete dataHeader.customer
				delete dataHeader.customer_rfc
			}
		} else {
			if (isSupplier) {
				dataHeader.customer = ticketDetail?.sender.name
				dataHeader.customer_rfc = ticketDetail?.sender.rfc
				delete dataHeader.supplier
				delete dataHeader.supplier_rfc
			} else {
				dataHeader.supplier = ticketDetail?.sender.name
				dataHeader.supplier_rfc = ticketDetail?.sender.rfc
				delete dataHeader.customer
				delete dataHeader.customer_rfc
			}
		}

		return dataHeader
	}, [ticketDetail])

	const getStatuses = useCallback((): StatusInformation[] => {
		const dateCreated: string = dateFormatter.format(parseTimestampToTimeZone(ticketDetail?.created_at))
		const dateReview: string = dateFormatter.format(parseTimestampToTimeZone(ticketDetail?.date_review))
		const dateClose: string = dateFormatter.format(parseTimestampToTimeZone(ticketDetail?.date_close))
		const statuses = {
			[Constants.TICKET_STATUS.OPEN]: [openStatus(dateCreated)],
			[Constants.TICKET_STATUS.REVIEW]: [openStatus(dateCreated), reviewStatus(dateReview)],
			[Constants.TICKET_STATUS.CLOSED_TICKET]: ticketDetail?.date_review
				? [openStatus(dateCreated), reviewStatus(dateReview), closedStatus(dateClose)]
				: [openStatus(dateCreated), closedStatus(dateClose)],
			DEFAULT: [statusLoading()]
		}
		return statuses[ticketDetail?.status.key] || statuses['DEFAULT']
	}, [ticketDetail])
	return (
		<Translation>
			{(t) => (
				<div className="ticket-detail-information-container">
					<TrackInformation
						id={ticketDetail?.id}
						information={mapTrackInformationData()}
						title="ticketText"
						gridTemplateAreas={`${
							isSupplier
								? "'customer customer_rfc creation_date x' 'category category category x' 'subject subject subject x'"
								: "'supplier supplier_rfc creation_date x' 'category category category x' 'subject subject subject x'"
						}`}
						statuses={getStatuses()}
					/>
					<div className="ticket-detail-description-container">
						<div className="ticket-detail-section-description">
							<div className="ticket-detail-title-field-description">
								{t('textDescription')}
							</div>
							<div className="ticket-detail-only-read-description">
								{ticketDetail?.description}
							</div>
						</div>
					</div>
					{ticketDetail?.closing_reason && (
						<div className="ticket-detail-closing-reason-container">
							<div className="ticket-detail-section-description">
								<div className="ticket-detail-title-field-closing-reason">
									{t('closingReasonTitle')}
								</div>
								<div className="ticket-detail-only-read-closing-reason">
									{ticketDetail?.closing_reason}
								</div>
							</div>
						</div>
					)}

					<div className="separator-gray"></div>
					<DocumentSection
						tableHeight={`calc(100vh - ${trackInformationHeight}px)`}
						ticketDetail={ticketDetail}
						openModalPDF={openModalPDF}
						additionalFile={additionalFile}
						setAdditionalFile={setAdditionalFile}
						addRevisionDocument={addRevisionDocument}
					/>
				</div>
			)}
		</Translation>
	)
}

export default InformationContainer
