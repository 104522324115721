import {
	NetcurioFormControl,
	NetcurioGrid,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect
} from '@netcurio-ui/components'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './StandaloneDocumentManuallyAuthModal.module.scss'

type Options = { value: string; key: string }[]

interface SelectorProps {
	label: string
	options: Options
	readonly: boolean
	value: string
	onChange: (value: string) => void
	isInputValid: boolean
}

const Selector = ({ label, options, readonly, value, onChange, isInputValid }: SelectorProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioFormControl fullWidth>
			<NetcurioInputLabel id={label} size="small">
				{label}
			</NetcurioInputLabel>
			<NetcurioSelect
				labelId={label}
				variant="outlined"
				label={label}
				size="medium"
				value={value ?? ''}
				height="smaller"
				fullWidth
				disabled={readonly}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
				error={isInputValid}
				helperText={t('missingFields')}
			>
				{options.map((option, index) => (
					<NetcurioMenuItem key={index} value={option.key}>
						{option.value}
					</NetcurioMenuItem>
				))}
			</NetcurioSelect>
			{isInputValid ? <p className={styles.errorMessage}>{t('missingFields')}</p> : <></>}
		</NetcurioFormControl>
	)
}

interface SelectorsProps {
	costCenterOptions: Options
	expenseTypeOptions: Options
	expenseSubtypeOptions: Options
	costCenterValue: string
	expenseTypeValue: string
	expenseSubtypeValue: string
	readonly: boolean
	onChangeCostCenter: (value: string) => void
	onChangeExpenseType: (value: string) => void
	onChangeExpenseSubtype: (value: string) => void
	costCenterError: boolean
	expenseTypeError: boolean
	expenseSubtypeError: boolean
}

export const Selectors = ({
	costCenterOptions,
	expenseSubtypeOptions,
	expenseTypeOptions,
	costCenterValue,
	expenseTypeValue,
	expenseSubtypeValue,
	readonly,
	onChangeCostCenter,
	onChangeExpenseType,
	onChangeExpenseSubtype,
	costCenterError,
	expenseTypeError,
	expenseSubtypeError
}: SelectorsProps) => {
	const hasOptions = useMemo(() => {
		return costCenterOptions.length > 0 && expenseTypeOptions.length > 0
	}, [costCenterOptions, expenseTypeOptions])

	if (!hasOptions) {
		return null
	}

	return (
		<NetcurioGrid width="100%" display="flex" justifyContent="space-between" gap="12px">
			<Selector
				label={t('invoice.constCenter')}
				options={costCenterOptions}
				readonly={readonly}
				value={costCenterValue}
				onChange={(value) => onChangeCostCenter(value)}
				isInputValid={costCenterError}
			/>
			<Selector
				label={t('invoice.expenseType')}
				options={expenseTypeOptions}
				readonly={readonly}
				value={expenseTypeValue}
				onChange={(value) => onChangeExpenseType(value)}
				isInputValid={expenseTypeError}
			/>
			<Selector
				label={t('invoice.expenseSubtype')}
				options={expenseSubtypeOptions}
				readonly={readonly || expenseSubtypeOptions.length === 0}
				value={expenseSubtypeValue}
				onChange={(value) => onChangeExpenseSubtype(value)}
				isInputValid={expenseSubtypeError}
			/>
		</NetcurioGrid>
	)
}
