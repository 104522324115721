export const defaultInvitation = { uuid: '', email: '', companies: [] }

export const defaultErrorData = {
	show: false,
	title: '',
	message: '',
	buttonText: '',
	onClick: () => undefined
}

export const defaultErrorInput = {
	name: false,
	lastname: false,
	phone_number: false,
	password: false,
	confirmPassword: false
}

export const defaultFormValues = {
	name: '',
	lastname: '',
	phone_number: '',
	password: '',
	confirmPassword: '',
	time_zone: ''
}
