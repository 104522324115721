import signUpArrow from '@netcurio-ui/assets/src/icons/arrow-signup.svg'
import arrows from '@netcurio-ui/assets/src/icons/arrow-up-reports.svg'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import S from 'react-horizontal-scrolling-menu'
import styles from './HorizontalScrollMenu.module.scss'
// TODO: THIS IS A HACK to make the build work in production, revisit once we migrate to latest ScrollMenu
//@typescript-eslint/no-explicit-any
const ScrollMenu = (S as any).default ? (S as any).default : S

export enum HorizontalScrollMenuType {
	Reports = 'Reports',
	Companies = 'Companies'
}

export interface HorizontalScrollMenuProps {
	horizontalScrollMenuType: HorizontalScrollMenuType
}

export const HorizontalScrollMenu = ({
	children,
	horizontalScrollMenuType
}: PropsWithChildren<HorizontalScrollMenuProps>) => {
	const getScrollMenuProps = () => {
		const initalProps = {
			data: children,
			dragging: true,
			hideArrows: true,
			hideSingleArrow: true,
			wheel: true,
			alignOnResize: true,
			alignCenter: false,
			clickWhenDrag: true,
			scrollToSelected: true,
			useButtonRole: false,
			itemClass: styles.outlineNone
		}

		if (horizontalScrollMenuType === HorizontalScrollMenuType.Reports) {
			return {
				...initalProps,
				arrowLeft: <img className={styles.arrowPrev} src={arrows} alt="arrow" />,
				arrowRight: <img className={styles.arrowNext} src={arrows} alt="arrow" />,
				arrowClass: styles.arrowReports,
				innerWrapperClass: styles.innerReports,
				menuClass: styles.menuScrollGeneralReports,
				arrowDisabledClass: styles.scrollMenuArrowDisabled,
				scrollBy: 2
			}
		} else {
			return {
				...initalProps,
				arrowLeft: (
					<img
						className={classNames(styles.arrowSizeSignUpView, styles.arrowPrevSignUpView)}
						src={signUpArrow}
					/>
				),
				arrowRight: (
					<img
						className={classNames(styles.arrowSizeSignUpView, styles.arrowNextSignUpView)}
						src={signUpArrow}
					/>
				),
				arrowClass: styles.scrollMenuArrowSignUpView,
				innerWrapperClass: null,
				menuClass: null,
				arrowDisabledClass: styles.scrollMenuArrowSignUpViewDisabled,
				scrollBy: 1
			}
		}
	}

	return <ScrollMenu {...getScrollMenuProps()} />
}
