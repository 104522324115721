import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog, NetcurioTextField } from '@netcurio-ui/components'

interface VoidPaymentComplementModalProps {
	open: boolean
	voidPayment: (reason: string) => void
	close: () => void
}

/**
 * VoidPaymentComplementModal
 * @description Helps to display a modal whe user tries to void a payment complement
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param voidPayment <function>: this function handles any action on the parent when void action is triggered and also sends the reason.
 * @returns Modal component
 */

export const VoidPaymentComplementModal = ({ open, voidPayment, close }: VoidPaymentComplementModalProps) => {
	const { t } = useTranslation()
	const [voidReason, setVoidReason] = useState<string>('')

	const triggerVoidPayment = () => {
		voidPayment(voidReason)
	}

	const triggerClose = () => {
		close()
	}

	return (
		<>
			<NetcurioDialog
				open={open}
				onClose={triggerClose}
				titleText={t('cancelReason')}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={triggerClose}>
							{t('goBackText')}
						</NetcurioButton>
						<NetcurioButton
							variant="contained"
							onClick={triggerVoidPayment}
							disabled={!voidReason}
						>
							{t('voidPaymentComplement')}
						</NetcurioButton>
					</>
				}
				maxWidth="xs"
				minWidth="xs"
			>
				<p>{t('cancelPaymentComplement')}</p>
				<NetcurioTextField
					placeholder={t('placeHolderRejectReasonTicket')}
					fullWidth
					multiline
					rows={2}
					height="medium"
					maxLength={80}
					value={voidReason}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setVoidReason(event.target.value)
					}}
				/>
			</NetcurioDialog>
		</>
	)
}
