import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface RejectEditPOProps {
	open: boolean
	onClose(): void
	onAccept: (dropdownValue) => any
}

/**
 * RejectEditPOProps
 * @description Helps to display a modal when the user is trying to reject a PO
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <void>: Callback fired to close modal
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const RejectEditPO: FC<RejectEditPOProps> = ({ onAccept, onClose, open }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('titleDetailsExit')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('goBackText')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('cancelButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('cancelOrderDetail')}</p>
		</NetcurioDialog>
	)
}
