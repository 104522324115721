import { getCurrentUser, URLS } from '@netcurio-ui/common'
import * as queries from '../views/login/queries'
import { connection } from './connection'
import Constants from './constants'
import { getUrlParameter } from './getUrlParameter'
import UserInformation from './userInformation'

export function redirectToPage() {
	if (window.history.length > 2) {
		window.history.back()
	} else location.href = URLS.SITE
}

function getParameterSession(session, param) {
	let valueParam
	valueParam = session.searchParams.get(param)
	return valueParam
}

export function getInformationDocType(fromLogin, sessionPath) {
	let company = undefined
	let id = ''
	let docType = getDocType(fromLogin, sessionPath)
	if (docType !== '') {
		switch (docType) {
			case Constants.DOCUMENT_TYPE.PURCHASE_ORDER:
				id = fromLogin ? getParameterSession(sessionPath, 'order') : getUrlParameter('order')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.INVOICE:
				id = fromLogin ? getParameterSession(sessionPath, 'invoice') : getUrlParameter('invoice')
				break
			case Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT:
				id = fromLogin
					? getParameterSession(sessionPath, 'paymentComplement')
					: getUrlParameter('paymentComplement')
				break
			case Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION:
				id = fromLogin ? getParameterSession(sessionPath, 'rfq') : getUrlParameter('rfq')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.CONSUMPTION:
				id = fromLogin
					? getParameterSession(sessionPath, 'consumption')
					: getUrlParameter('consumption')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO:
				id = fromLogin
					? getParameterSession(sessionPath, 'requestforcreditmemo')
					: getUrlParameter('requestforcreditmemo')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.CREDIT_MEMO:
				id = fromLogin
					? getParameterSession(sessionPath, 'creditmemo')
					: getUrlParameter('creditmemo')
				break
			case Constants.DOCUMENT_TYPE.TICKET:
				id = fromLogin ? getParameterSession(sessionPath, 'ticket') : getUrlParameter('ticket')
				company = fromLogin ? getParameterSession(sessionPath, 'sender') : getUrlParameter('sender')
				break
		}
		let client = connection()
		let infoDocument = new Promise(function (resolve, reject) {
			client
				.query({
					query: queries.DOCUMENT_FROM_LINK,
					variables: {
						id: id,
						company: company,
						docType: docType
					}
				})
				.then((result) => {
					const suppliersRfcs = result.data.getDocumentFromLink?.suppliers ?? []
					const customerRfc = result.data.getDocumentFromLink?.customer ?? ''
					const currentCompanyRfc = getCurrentUser().company?.rfc
					const companiesUnblocked = UserInformation.getCompaniesUnblocked()
					let companySelected

					if (currentCompanyRfc && [customerRfc, ...suppliersRfcs].includes(currentCompanyRfc)) {
						companySelected = UserInformation.getCompanySelected(
							companiesUnblocked,
							currentCompanyRfc
						)
					} else {
						companySelected = UserInformation.getCompanySelected(companiesUnblocked, customerRfc)
						if (companySelected === undefined) {
							companySelected = UserInformation.getCompanySelected(
								companiesUnblocked,
								suppliersRfcs
							)
						}
					}
					sessionStorage.clear()
					resolve(companySelected)
				})
				.catch((error) => {
					console.error(error)
				})
		})

		return infoDocument
	} else resolve(undefined)
}

export function validateMulticompany(companiesUnblocked, goTo) {
	let user = getCurrentUser()
	if (companiesUnblocked.length > 1) location.href = URLS.SELECT_COMPANY_AT_LOGIN
	else {
		UserInformation.setCurrentUser(user, companiesUnblocked[0])
		if (goTo !== undefined) {
			location.href = goTo
		} else {
			location.href = companiesUnblocked[0].homepage
				? URLS[Constants.RELATION_KEY_URL[companiesUnblocked[0].homepage]]
				: URLS.PO_LIST
		}

		sessionStorage.clear()
	}
}

export function getDocType(fromLogin, sessionPath) {
	let docType = ''
	let evalDoc = ''
	if (fromLogin) {
		evalDoc = sessionPath.pathname
	} else {
		evalDoc = window.location.pathname
	}
	switch (evalDoc) {
		case URLS.PO_DETAIL:
		case URLS.PO_EDIT:
			docType = Constants.DOCUMENT_TYPE.PURCHASE_ORDER
			break
		case URLS.INVOICE_DETAIL:
		case URLS.INVOICE_EDIT:
			docType = Constants.DOCUMENT_TYPE.INVOICE
			break
		case URLS.COMPLEMENT_DETAIL:
			docType = Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT
			break
		case URLS.RFQ_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION
			break
		case URLS.CONSUMPTION_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CONSUMPTION
			break
		case URLS.RFCM_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
			break
		case URLS.CREDIT_MEMO_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CREDIT_MEMO
			break
		case URLS.TICKET_DETAIL:
			docType = Constants.DOCUMENT_TYPE.TICKET
			break
	}
	return docType
}

export function updateUser(newCompany) {
	let client = connection()
	let dataUserUpdate = new Promise(function (resolve, reject) {
		client
			.mutate({
				mutation: queries.LOGIN_USER
			})
			.then((result) => {
				UserInformation.setCurrentUser(result.data.loginUser, undefined)
				let companiesUnblocked = UserInformation.getCompaniesUnblocked()
				let companySelected = UserInformation.getCompanySelected(companiesUnblocked, newCompany)
				resolve(companySelected)
			})
			.catch((error) => {
				console.error(error)
			})
	})
	return dataUserUpdate
}

export function validationToRedirection(fromLogin) {
	let validator = new Promise(function (resolve, reject) {
		let user = getCurrentUser()
		if (user) {
			let companiesUnblocked = UserInformation.getCompaniesUnblocked()
			let newCompany = ''
			let sessionPath = null
			let companySelected = undefined
			let searchUrl = ''
			let haveDefaultCompany = UserInformation.getDefaultCompany()

			if (companiesUnblocked.length > 0) {
				if (fromLogin) {
					if (sessionStorage.getItem('internalUrl') !== null) {
						sessionPath = new URL(sessionStorage.getItem('internalUrl'))
						searchUrl = sessionPath.search
						newCompany =
							sessionPath.searchParams.get('companyRFC') === null
								? ''
								: sessionPath.searchParams.get('companyRFC')
					}
				} else {
					searchUrl = location.search
					newCompany = getUrlParameter('companyRFC')
					sessionPath = window.location
				}
				if (sessionPath !== null) {
					if (
						(sessionPath.pathname === URLS.PO_LIST && newCompany !== '') ||
						(newCompany === '' && searchUrl !== '' && sessionPath.pathname !== URLS.GR_DETAIL)
					) {
						if (newCompany !== '') {
							updateUser(newCompany)
								.then((response) => {
									UserInformation.setCurrentUser(user, response)
									if (response !== undefined) {
										location.href = response.homepage
											? URLS[Constants.RELATION_KEY_URL[response.homepage]]
											: URLS.PO_LIST
										resolve(companySelected)
									} else {
										location.href = URLS.WITHOUT_ACCESS
										resolve(companySelected)
									}
								})
								.catch((error) => {
									console.error(error)
								})
						} else {
							/* Case URL of documents */
							getInformationDocType(fromLogin, sessionPath)
								.then((companySelected) => {
									if (companySelected !== undefined) {
										UserInformation.setCurrentUser(user, companySelected)
										if (fromLogin) {
											location.href = sessionPath.href
										}
										resolve(companySelected)
									} else {
										location.href = URLS.WITHOUT_ACCESS
										resolve(companySelected)
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}
					} else {
						/* Case URL of lists */
						if (!user.company) {
							if (haveDefaultCompany) {
								companySelected = UserInformation.getCompanySelected(
									companiesUnblocked,
									haveDefaultCompany
								)
								if (companySelected !== undefined) {
									UserInformation.setCurrentUser(user, companySelected)
									location.href = sessionPath.href
									sessionStorage.clear()
								} else {
									validateMulticompany(companiesUnblocked, sessionPath.href)
								}
							} else {
								validateMulticompany(companiesUnblocked, sessionPath.href)
							}
						} else if (user.company && location.href !== sessionPath.href) {
							location.href = sessionPath.href
							sessionStorage.clear()
						}
						resolve(companySelected)
					}
				} else {
					/* Cases of validation to status of Default Company */
					if (haveDefaultCompany) {
						companySelected = UserInformation.getCompanySelected(
							companiesUnblocked,
							haveDefaultCompany
						)
						if (companySelected !== undefined) {
							UserInformation.setCurrentUser(user, companySelected)
							location.href = companySelected.homepage
								? URLS[Constants.RELATION_KEY_URL[companySelected.homepage]]
								: URLS.PO_LIST
						} else {
							let statusCompanyDefault = UserInformation.getCompanySelected(
								user.companies,
								haveDefaultCompany
							)
							if (statusCompanyDefault === undefined) {
								resolve(Constants.USER_STATUS.UNSUBSCRIBED)
							} else {
								resolve(Constants.USER_STATUS.BLOCKED)
							}
						}
					} else {
						validateMulticompany(companiesUnblocked, undefined)
					}
					resolve(companySelected)
				}
			} else {
				/*Case all Companies Blocked */
				resolve(Constants.AUTH.ALLCOMPANIESBLOCKED)
			}
		} else {
			resolve(undefined)
		}
	})

	return validator
}
