import { t } from 'i18next'

import { CommentSectionSize } from './CommentsSection.context'
import openCommentsIcon from '@netcurio-ui/assets/src/icons/open-comment-section.svg'
import closeCommentsIcon from '@netcurio-ui/assets/src/icons/close-comment-section.svg'
import minimizedCommentsIcon from '@netcurio-ui/assets/src/icons/minimized-comment-section.svg'

const headerIconMap = new Map<CommentSectionSize, string>([
	[CommentSectionSize.Minimized, minimizedCommentsIcon],
	[CommentSectionSize.Intermediate, openCommentsIcon],
	[CommentSectionSize.Extended, closeCommentsIcon]
])

const extendButtonDataTipMap = new Map<CommentSectionSize, string>([
	[CommentSectionSize.Minimized, t('expand')],
	[CommentSectionSize.Intermediate, t('expandAll')],
	[CommentSectionSize.Extended, t('showLess')]
])

const minimizeButtonDataTipMap = new Map<CommentSectionSize, string>([
	[CommentSectionSize.Minimized, t('expand')],
	[CommentSectionSize.Intermediate, t('minimize')],
	[CommentSectionSize.Extended, t('minimize')]
])

export { headerIconMap, extendButtonDataTipMap, minimizeButtonDataTipMap }
