import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Filter, Roles, URLS } from '@netcurio-ui/common'
import { Order } from '../../../../types'
import Constants from '../../../../utilities/constants'
import { EnrollmentsList } from './enrollmentsList/enrollmentsList'
import EnrollmentDetailScreen from './enrollmentDetail/enrollmentDetail'
import UserInformation from '../../../../utilities/userInformation'

interface Props {
	chooseMainHeaderButton: (button: string) => void
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}

export default function EnrollmentSelectionScreen({ chooseMainHeaderButton, setDataFilterArray }: Props) {
	const { path } = useRouteMatch()

	useEffect(() => {
		if (
			window.location.pathname ===
			URLS.ADMINISTRATION + '/' + Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB
		) {
			UserInformation.getCompanyRole() === Roles.SUPPLIER
				? chooseMainHeaderButton('enrollmentsList')
				: UserInformation.isEnrollmentRequestApprover() && chooseMainHeaderButton('inviteSupplier')
			if (
				UserInformation.getCompanyRole() === Roles.CUSTOMER &&
				!UserInformation.isEnrollmentRequestApprover()
			) {
				chooseMainHeaderButton('enrollmentsListExport')
			}
		} else chooseMainHeaderButton('enrollmentsDetail')
	}, [window.location.pathname])

	return (
		<Switch>
			<Route exact path={path}>
				<EnrollmentsList setDataFilterArray={setDataFilterArray} />
			</Route>
			<Route path={`${path}/:invitation`}>
				<EnrollmentDetailScreen />
			</Route>
		</Switch>
	)
}
