import React from 'react'
import { useTranslation } from 'react-i18next'
import { Severity, NetcurioButton, NetcurioDialog } from '@netcurio-ui/components'

interface CancelPoCreationModalProps {
	open: boolean
	close: () => void
	accept: () => void
	title?: string
	showInput?: boolean
	headerTitleSeverity?: Severity
}

/**
 * ConfirmPurchaseOrderModal
 * @description Helps to display a message when user try to save the changes to ticket permissions
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @param title <string>: is a text that describe the tittle of modal
 * @param headerTitleSeverity <Severity>: is a type of message on the modal
 * @returns Modal component
 */
export const CancelPOCreationModal = ({
	open,
	close,
	accept,
	title,
	headerTitleSeverity
}: CancelPoCreationModalProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t(title)}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={accept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<label>{t('cancelNewOrder')}</label>
		</NetcurioDialog>
	)
}
