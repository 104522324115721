import { NetcurioButton, NetcurioDialog, NetcurioIcons } from '@netcurio-ui/components'
import classNames from 'classnames'
import { t } from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { InvoiceValidationEnum } from '../../../../../types'
import { Validations } from '../../../../../types/Validations'
import { ValidationTable } from '../ValidationTable/ValidationTable'
import styles from './ManuallyAuthMatch.module.scss'

interface ManuallyAuthMatchModalProps {
	open: boolean
	acceptAuthorization(): void
	previousValidation: Validations
	currentValidation: Validations
	passAllValidations: boolean
}
/**
 * @description Helps to display a modal when the user tries to manually authorize an Invoice when its status is Error
 * @param open <boolean>: If `true`, the modal will show
 * @param acceptAuthorization <function>: when trigger, the modal will authorize the Invoice
 * @param previousValidation <object>: get the previousValidation
 * @param currentValidation <object>: get the currentValidation
 * @param passAllValidations <boolean>: tells us if the validation passed or not
 * @returns component <ReactNode>
 */
export const ManuallyAuthMatchModal = ({
	open,
	acceptAuthorization,
	previousValidation,
	currentValidation,
	passAllValidations
}: ManuallyAuthMatchModalProps) => {
	const isNotValidated = Object.values(previousValidation).some((validation) =>
		typeof validation === 'object'
			? validation.value === InvoiceValidationEnum.NOT_VALIDATED
			: validation === InvoiceValidationEnum.NOT_VALIDATED
	)
	return (
		<NetcurioDialog
			titleText={t('authorizeManualInvoice.headerMatch')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="contained" onClick={() => acceptAuthorization()}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
			minWidth={'71rem'}
			maxWidth={'71rem'}
			contentFontSize={'0'}
		>
			<div className={styles.authorizeContainerColumn}>
				<div>
					<p className={styles.labels}>
						<Trans>{t('authorizeManualInvoice.descriptionMatch')}</Trans>
					</p>
				</div>
				<ValidationTable
					previousValidation={previousValidation}
					currentValidation={currentValidation}
					passAllValidations={passAllValidations}
				/>
				{isNotValidated && (
					<div className={styles.contentInSameLine}>
						<NetcurioIcons.Error className={classNames(styles.errorIcon)} />
						<p className={classNames(styles.labels, styles.labelsError)}>
							{t('previouslyValid')}
						</p>
					</div>
				)}
			</div>
		</NetcurioDialog>
	)
}
