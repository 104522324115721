import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioGrid } from '@netcurio-ui/components'
import { PURCHASE, Roles, Status } from '@netcurio-ui/common'
import { GoodsReceiptModule } from './GoodsReceiptModule/GoodsReceiptModule'
import { InvoicesModule } from './InvoicesModule/InvoicesModule'
import { GoodsReceiptDetail } from './GoodsReceiptDetail/GoodsReceiptDetail'
import { GoodReceipt, GoodReceiptItem, Invoice } from '../../../../types'
import Constants from '../../../../utilities/constants'
import UserInformation from '../../../../utilities/userInformation'
import boxGR from '@netcurio-ui/assets/src/icons/box-white.svg'
import invoices from '@netcurio-ui/assets/src/icons/invoice.svg'
import classNames from 'classnames'
import styles from '../purchaseOrderDetail.module.scss'

interface GRAndInvoicesProps {
	typePO?: string
	statusPO?: string
	dataGoodsReceipts?: Array<GoodReceipt>
	dataInvoices?: Array<Invoice>
	newGoodReceipt(): void
	openInvoiceDetail(uuid: string): void
	setShowNewInvoiceModal(open: boolean): void
	setShowDetailGoodReceive: Dispatch<SetStateAction<boolean>>
	showDetailGoodReceive: boolean
	showEntriesRecord(): void
	statusPurchaseOrder: Status
}
export const GoodsReceiptAndInvoicesModule: FC<GRAndInvoicesProps> = ({
	typePO,
	dataGoodsReceipts,
	dataInvoices,
	statusPO,
	newGoodReceipt,
	openInvoiceDetail,
	setShowNewInvoiceModal,
	setShowDetailGoodReceive,
	showDetailGoodReceive,
	showEntriesRecord,
	statusPurchaseOrder
}) => {
	const { t } = useTranslation()
	const [showGoodsReceiveTable, setShowGoodsReceiveTable] = useState<boolean>(false)
	const [showInvoicesTable, setShowInvoicesTable] = useState<boolean>(false)
	const [newGRModal, setNewGRModal] = useState<boolean>(false)
	const [dataGoodsReceiptsDetail, setDataGoodsReceiptsDetail] = useState<GoodReceiptItem[]>([])
	const [positionGRSelected, setPositionGRSelected] = useState<number>(-1)

	const userRole: Roles = useMemo(
		(): Roles => UserInformation.getCompanyRole() as Roles,
		[UserInformation.getCompanyRole]
	)
	const isDisabledButtonGR = !!(
		dataGoodsReceipts.length ||
		(userRole === Roles.CUSTOMER &&
			statusPO !== Constants.STATUS.NEW &&
			statusPO !== Constants.STATUS.REJECTED)
	)
	const isDisabledButtonInvoices = !!(
		dataInvoices.length ||
		(userRole === Roles.SUPPLIER && statusPO !== Constants.STATUS.REJECTED)
	)
	const isNeedChangeBorder: boolean =
		showGoodsReceiveTable && statusPO === PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT
	const isConsignmentPO: boolean = statusPO === PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT
	const showHeaderInvoice: boolean = typePO !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT

	const getInfoGoodReceipt = (idGR: string) => {
		const goodReceiptsDetail: GoodReceipt = dataGoodsReceipts.find(
			(goodReceipt: GoodReceipt): boolean => goodReceipt.id === idGR
		)
		if (goodReceiptsDetail.items) {
			setShowDetailGoodReceive(true)
			setDataGoodsReceiptsDetail(goodReceiptsDetail.items)
		} else {
			setShowDetailGoodReceive(true)
			setDataGoodsReceiptsDetail([])
		}
	}

	const showGrDetail = (position: number, idGR: string) => {
		if (position > -1 && idGR) {
			setPositionGRSelected(position)
			getInfoGoodReceipt(idGR)
		}
	}

	const closeGoodReceiveDetail = () => {
		setShowDetailGoodReceive(false)
		setPositionGRSelected(-1)
	}

	if (showDetailGoodReceive) {
		return (
			<GoodsReceiptDetail
				userRole={userRole}
				closeGoodReceiveDetail={closeGoodReceiveDetail}
				showEntriesRecord={showEntriesRecord}
				newGRModal={newGRModal}
				statusPurchaseOrder={statusPurchaseOrder}
				dataGoodsReceipts={dataGoodsReceipts}
				positionGRSelected={positionGRSelected}
				showGrDetail={showGrDetail}
				dataGoodsReceiptsDetail={dataGoodsReceiptsDetail}
			/>
		)
	}

	return (
		<NetcurioGrid item width="100%" height="100%" display="flex" flexDirection="column">
			<NetcurioGrid display="flex" justifyContent="space-between" textAlign="center">
				<NetcurioGrid
					className={classNames(
						showHeaderInvoice
							? styles.grInvoiceDeliverBillsOrderDetails
							: styles.grInvoiceDeliverBillsOrderDetailsFull,
						showHeaderInvoice
							? styles.grContainerOrderDetails
							: styles.grContainerOrderDetailsFull,
						{
							[styles.bgDarkBlueBarPressed]: isDisabledButtonGR,
							[styles.borderInTitle]: isNeedChangeBorder,
							[styles.grsConsignment]: isConsignmentPO,
							[styles.activeViewGRsBorderTable]:
								showHeaderInvoice && (showGoodsReceiveTable || showInvoicesTable),
							[styles.focusViewTable]: showGoodsReceiveTable,
							[styles.activeViewGRsBorderTableFull]:
								!showHeaderInvoice && (showGoodsReceiveTable || showInvoicesTable)
						}
					)}
					onClick={() => {
						if (isDisabledButtonGR) {
							setShowGoodsReceiveTable(!showGoodsReceiveTable)
							setShowInvoicesTable(false)
						}
					}}
				>
					<img className={styles.imgHeaderInvoicesDetailsClick} src={boxGR} alt="icon boxGR" />
					<p className={styles.textInvoicesOrderDetails}>{t('deliverText')}</p>
				</NetcurioGrid>
				{showHeaderInvoice && (
					<NetcurioGrid
						className={classNames(
							styles.grInvoiceDeliverBillsOrderDetails,
							styles.invoicesDeliverContainerOrderDetails,
							{
								[styles.bgDarkBlueBarPressed]: isDisabledButtonInvoices,
								[styles.activeViewInvoicesBorderTable]:
									showInvoicesTable || showGoodsReceiveTable,
								[styles.focusViewTable]: showInvoicesTable
							}
						)}
						onClick={() => {
							if (isDisabledButtonInvoices) {
								setShowInvoicesTable(!showInvoicesTable)
								setShowGoodsReceiveTable(false)
							}
						}}
					>
						<img
							className={styles.imgHeaderInvoicesDetailsClick}
							src={invoices}
							alt="icon invoices"
						/>
						<p className={styles.textInvoicesOrderDetails}>{t('invoicesText')}</p>
					</NetcurioGrid>
				)}
			</NetcurioGrid>
			{showGoodsReceiveTable && !showInvoicesTable && (
				<GoodsReceiptModule
					userRole={userRole}
					statusPO={statusPO}
					newGRModal={newGRModal}
					newGoodReceipt={newGoodReceipt}
					dataGoodsReceipts={dataGoodsReceipts}
					showGrDetail={showGrDetail}
				/>
			)}
			{showInvoicesTable && !showGoodsReceiveTable && (
				<InvoicesModule
					userRole={userRole}
					statusPO={statusPO}
					dataInvoices={dataInvoices}
					openInvoiceDetail={openInvoiceDetail}
					setShowNewInvoiceModal={setShowNewInvoiceModal}
				/>
			)}
		</NetcurioGrid>
	)
}
