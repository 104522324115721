import React from 'react'
import { NetcurioTooltip } from '@netcurio-ui/components'
import styles from './relatedCompanyDetail.module.scss'

interface Props {
	title: string
	text: string
	tooltip?: string
	id?: string
}

export const SquareInfo = ({ title, text, tooltip, id }: Props): JSX.Element => {
	return (
		<NetcurioTooltip title={tooltip ?? ''} placement={'top'}>
			<div className={styles.descriptionContainEnrollmentDetails}>
				<p className={styles.titleDescriptionInfoRelatedCompany}>{title}</p>
				<p className={styles.descriptionInfoRelatedCompany}>{text || '–'}</p>
			</div>
		</NetcurioTooltip>
	)
}
