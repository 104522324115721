import { t } from 'i18next'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import { PURCHASE, tableFillSelectionFilter, Roles } from '@netcurio-ui/common'
import Constants from '../../utilities/constants'
import styles from './purchaseOrderList.module.scss'

export function objectHeaderArray(userRole: Roles): Array<tableFillHeader> {
	const purchaseOrderColumn: tableFillHeader = new tableFillHeader(
		t('internalIdentifier'),
		styles.headerPurchaseContainerList,
		'',
		'id',
		'text'
	)
	const typeOrderColumn: tableFillHeader = new tableFillHeader(
		t('type_PO'),
		styles.headerTypeContainerList,
		'',
		'type',
		'type'
	)
	const statusOrderColumn: tableFillHeader = new tableFillHeader(
		t('actualState'),
		styles.headerStatusContainerList,

		'',
		'status',
		'status'
	)
	const branchOrderColumn: tableFillHeader = new tableFillHeader(
		t('placeOfDelivery'),
		styles.headerOfficeContainerList,
		'',
		'branch_office',
		'text'
	)
	const supplierOrderColumn: tableFillHeader = new tableFillHeader(
		t('seller'),
		styles.headerSupplierContainerList,
		'',
		'supplier',
		'text'
	)
	const customerOrderColumn: tableFillHeader = new tableFillHeader(
		t('buyer'),
		styles.headerCustomerContainerList,
		'',
		'customer',
		'text'
	)
	const dateOrderColumn: tableFillHeader = new tableFillHeader(
		t('creationDate'),
		styles.headerDateContainerList,
		'',
		'created_at',
		'date'
	)
	const totalOrderColumnCustomer: tableFillHeader = new tableFillHeader(
		t('subtotalToPay'),
		styles.headerTotalContainerListCustomer,
		'',
		'total',
		'numeric'
	)
	const totalOrderColumnProvider: tableFillHeader = new tableFillHeader(
		t('subtotalToPay'),
		styles.headerTotalContainerListProvider,
		'',
		'total',
		'numeric'
	)
	const customerReferenceOrderColumn: tableFillHeader = new tableFillHeader(
		t('externalIdentifier'),
		styles.headerCustomerReferenceContainerList,
		'',
		'customer_reference',
		'text'
	)
	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		purchaseOrderColumn,
		customerReferenceOrderColumn,
		typeOrderColumn,
		statusOrderColumn,
		branchOrderColumn,
		supplierOrderColumn,
		dateOrderColumn,
		totalOrderColumnCustomer
	]
	const objectHeaderArraySupplier: Array<tableFillHeader> = [
		purchaseOrderColumn,
		customerReferenceOrderColumn,
		typeOrderColumn,
		statusOrderColumn,
		branchOrderColumn,
		customerOrderColumn,
		dateOrderColumn,
		totalOrderColumnProvider
	]

	if (userRole === Roles.CUSTOMER) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const newStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.NEW,
		styles.newStatusButton
	)
	const confirmedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.CONFIRMED,
		styles.confirmedStatusButton
	)
	const halfConfirmedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		PURCHASE.HALF_CONFIRMED,
		styles.halfConfirmedStatusButton
	)
	const deliveredStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.DELIVERED,
		styles.deliveredStatusButton
	)
	const halfDeliveredStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		PURCHASE.HALF_DELIVERED,
		styles.halfDeliveredStatusButton
	)
	const rejectedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.REJECTED,
		styles.rejectStatusButton
	)

	return [
		newStatusOption,
		confirmedStatusOption,
		halfConfirmedStatusOption,
		deliveredStatusOption,
		halfDeliveredStatusOption,
		rejectedStatusOption
	]
}

export function objectTypeFilterArray(): Array<tableFillSelectionFilter> {
	const typeStandardOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.STANDARD,
		styles.standardTypeButton
	)
	const typeConsignmentOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT,
		styles.consignmentTypeButton
	)
	const typeExpressOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS,
		styles.expressTypeButton
	)

	return [typeStandardOption, typeConsignmentOption, typeExpressOption]
}
