import { NetcurioButton, NetcurioIcons } from '@netcurio-ui/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Consumption, ConsumptionItem, InvoiceItem, PurchaseOrder, PurchaseOrderItem } from '../../../types'
import { associateInvoiceItems } from '../../../utilities/associateInvoiceItems/associateInvoiceItems'

export enum AutoAssociationState {
	Idle,
	Executing,
	Executed
}

interface AutoAssociateInvoiceProps {
	invoiceItems: InvoiceItem[]
	suggestionsItemsToAssociate: Array<PurchaseOrderItem | ConsumptionItem>
	currentAssociation: PurchaseOrder | Consumption | null
	onFinishedAutoAssociation: (invoiceItems: InvoiceItem[], hasExceedItems: boolean) => void
	initialAutoAssociationState?: AutoAssociationState
	externalSetAutoAssociationState?: React.Dispatch<React.SetStateAction<AutoAssociationState>>
}

export const AutoAssociateInvoice = ({
	invoiceItems,
	suggestionsItemsToAssociate,
	currentAssociation,
	onFinishedAutoAssociation,
	initialAutoAssociationState,
	externalSetAutoAssociationState
}: AutoAssociateInvoiceProps) => {
	const { t } = useTranslation()

	const [localAutoAssociationState, localSetAutoAssociationState] = useState<AutoAssociationState>(
		AutoAssociationState.Idle
	)
	const autoAssociationState = initialAutoAssociationState ?? localAutoAssociationState
	const setAutoAssociationState = externalSetAutoAssociationState ?? localSetAutoAssociationState

	const handleAutoAssociateInvoice = () => {
		setAutoAssociationState(AutoAssociationState.Executing)
		const associationResult = associateInvoiceItems({
			invoiceItems,
			purchaseOrderItems: suggestionsItemsToAssociate
		})

		const hasExceedItems = associationResult.exceedItems.length > 0

		onFinishedAutoAssociation(associationResult.invoiceItems, hasExceedItems)

		setTimeout(() => {
			setAutoAssociationState(AutoAssociationState.Executed)
		}, 0)
	}

	const isAutoAssociationExecuted = autoAssociationState === AutoAssociationState.Executed

	return (
		<NetcurioButton
			variant="outlined"
			color="primary"
			endIcon={<NetcurioIcons.Check />}
			disabled={!Boolean(currentAssociation) || isAutoAssociationExecuted}
			onClick={handleAutoAssociateInvoice}
		>
			{t('invoices.associateAutomatically')}
		</NetcurioButton>
	)
}
