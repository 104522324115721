import { fillModal } from '../../components/dialogModal/fillModal'

export function objectCancelCreditMemoRequest() {
	const cancelRequestCreditMemo = new fillModal(
		'cancelModal',
		'titleCreditMemoDetailsExit',
		'cancelCreditMemoDetail',
		'comeback',
		'cancelText',
		'comeBackTextModal'
	)
	return [cancelRequestCreditMemo]
}

export function objectAcceptCreditMemoRequest() {
	const acceptRequestCreditMemo = new fillModal(
		'confirmModal',
		'titleCreditMemoDetailsAccept',
		'acceptCreditMemoDetail',
		'confirm',
		'acceptTextButton',
		'comeBackTextModal'
	)
	return [acceptRequestCreditMemo]
}

export function objectRejectCreditMemoRequest() {
	const rejectRequestCreditMemo = new fillModal(
		'rejectModalWithReason',
		'titleCreditMemoDetailsReject',
		'rejectCreditMemoDetail',
		'reject',
		'rejectActionText',
		'comeBackTextModal'
	)
	return [rejectRequestCreditMemo]
}

export function ModalSaveComments() {
	const saveComments = new fillModal(
		'goToList',
		'goToList',
		'questionCommentWithoutSend',
		'goToList',
		'acceptTextButton',
		'comeBackTextModal'
	)
	return [saveComments]
}
